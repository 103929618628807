import React from "react";

import "./task-list-header.scss";
import TaskListFilterDropDown from "./TaskListFilterDropDown";

const TaskListHeader = props => {
  const {
    pendingRequests,
    onChangeSearchHandler,
    headerOnChange,
    headerValues,
    isOwnTask
  } = props;
  return (
    <div className="task-list-header-wrapper">
      <div className="left-heading">
        <div className="heading">{`${isOwnTask ? 'My Task' : 'My Team Tasks'}`}</div>
        {/* <div className="pending-req">
          You have <span className="pending-count">{pendingRequests}</span>{" "}
          Pending Request
        </div> */}
      </div>
      <TaskListFilterDropDown
        onChangeSearchHandler={onChangeSearchHandler}
        headerOnChange={headerOnChange}
        headerValues={headerValues}
      />
    </div>
  );
};

export default TaskListHeader;
