import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {TEXT, WORKFLOW_TABLE_HEADING} from '../constant';
import InputBox from '../InputBox';
import TextArea from '../TextArea';
import SelectBox from '../SelectBox';
import Table from '../Table';
import CheckBox from '../CheckBox';
import FormValidator from '../../../services/FormValidator';
import {
    workFlowState,
    createWorkFlow,
    createActionTarget,
    requestMapperForWorkFlow,
    mapWorflowForEdit,
} from './helper';

import { fetchAdminActionsList, fetchAdminStateList, fetchPackageInstanceMapping, fetchAllWorkflow } from '../../../storeManager/common/repository';
import { AxiosService } from '../../../services/apiService';

import './style.scss';
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import Loader from "../../../components/Loader";
import {MODALS} from "../../../storeManager/modal/constants";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import {scrollToTop} from "../../../helper";

class ActionWorkflow extends Component {
    state = {
    name: '',
    description: '',
    isFirst: false,
    workflow: workFlowState(),
    actions: [],
    requestEdit: false,
    states: [],
    isRender: false,
    errorsMessage: '',
    packageMappingList: [],
    packageInstanceMapping: '',
    errorObj: {
        name: '',
        description: '',
        packageInstanceMapping: ''
    },
    formError: '',
    workflowList: []
    }

    componentDidMount() {
    this.loadHandler();
    }

    loadHandler = () => {
    const actionApi = new AxiosService(fetchAdminActionsList);
    const stateApi = new AxiosService(fetchAdminStateList);
    const packageMappingApi = new AxiosService(fetchPackageInstanceMapping);
    const WorkflowApi = new AxiosService(fetchAllWorkflow);
    Promise.all([
      actionApi.doAjax(),
      stateApi.doAjax(),
      packageMappingApi.doAjax(),
      WorkflowApi.doAjax()
    ])
    .then(res => {
      const [
        {data : {data : adminActionList, status: status1} },
        {data : {data : adminStateList, status: status2} },
        {data : {data : packageMappingList, status: status3} },
        {data : {data : workflowList, status: status4} }
      ] = res;
      if(status1 && status2 && status3 && status4) {
        this.setState({
            actions: adminActionList.map(action => {return {'id':action.id, 'value':action.name}}),
            states: adminStateList.map(state => {return {'id':state.id, 'value':state.name}}),
            packageMappingList: packageMappingList.map(packageMap => {return {'id':packageMap.id, 'value':packageMap.instanceServiceName}}),
            isRender: true,
            workflowList
        })
        }
      else{
        this.setState({isRender: true,})
      }
    })
    .catch( err => {
      this.setState({ isRender: true });
      console.log('Error in api call', err);
    })
    }

    handleWorkflowChange = (name, value, index, workFlowIndex) => {
    const { workflow, isFirst, formError } = this.state;
    let updatedIsFirst = isFirst;
    let updatedWorkflow = [...workflow];
    let selectedWorkflow = updatedWorkflow[workFlowIndex];
    let updatedFormError = formError;
    if(name === 'makeItFirst') {
      updatedFormError = value ? '' : updatedFormError;
      updatedIsFirst = value;
      selectedWorkflow.sourceState.isFirst = value;
    }
    if(name === 'sourceState') {
      selectedWorkflow[name].value = value;
    }
    if(name === 'action' || name === 'targetState' ) {
      selectedWorkflow.actionTarget[index][name].value = value;
    }
    this.setState({
        workflow: updatedWorkflow,
        isFirst: updatedIsFirst,
        formError: updatedFormError,
    })
    }

    handleChange = (name,value) => {
    this.setState({
      [name]: value,
    })
    }

    validateForm = (paylaod) => {
    const { errorObj, isFirst } = this.state;
    const formStatus = FormValidator.validateAdminWorkflowForm(paylaod);
    const { hasErrors, ...errorMessages } = formStatus;
    let updatedFormError = '';
    if(hasErrors){
    const updatecErrorObj = {...errorObj};
    const formKeys = Object.keys(paylaod);
    let updatecWorkflow;
    formKeys.forEach(key => {
        const inputKey = `${key}Message`;
        const errorMessage = errorMessages[inputKey];
        if(key === 'workflow') {
            updatecWorkflow = errorMessage;
        }
        if (errorMessage) {
            updatecErrorObj[key] = errorMessage;
        }
        if (!errorMessage) {
            updatecErrorObj[key] = '';
        }
    });
    if(!isFirst) {
        updatedFormError = "Please check the primary workflow";
    }
    this.setState({
        workflow: updatecWorkflow,
        errorObj:updatecErrorObj,
        formError: updatedFormError,
        isRender: true
    });
    return false;
    } else {
    const updatecErrorObj = {
        name: '',
        description: '',
        packageInstanceMapping: ''
    }
    this.setState({
        errorObj:updatecErrorObj
    })
    return true;
    }
    }

    handleSubmit = async (event) => {
    event.preventDefault();
    const { openModal } = this.props;
    const { ...paylaod } = this.state;
    const reqPaylod = requestMapperForWorkFlow(paylaod);
    if(this.validateForm(paylaod)){
    this.setState({ isRender: false });
    try {
        const response = await commonService.createWorkflow(reqPaylod);
        const responseData = response.data;
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
          this.setState({
            isRender: true
          });
          openModal(MODALS.CONFIRMATION_POPUP, {
              message: responseData.message
          });
          this.resetForm();
        } else {
            this.setState({
              error: (responseData && responseData.message) || GlobalConfig.serverError,
              isRender: true
            })
        }
      } catch {
        this.setState({
          error: GlobalConfig.serverError,
          isRender: true
        })
      }
    }
    }

    addRemoveObj = (btnType, index, actionType, workFlowIndex) => {
        const { workflow } = this.state;
        const updatedWorkFlow = [...workflow];
        if (actionType === 'actionTarget') {
            const selectedActionTarget = updatedWorkFlow[workFlowIndex].actionTarget;
            if (btnType === "More") {
                const obj = createActionTarget();
                selectedActionTarget.push(obj);
            } else {
                selectedActionTarget.splice(index, 1);
            }
        } else {
            // workFlow row
            if (btnType === "More") {
                const obj = createWorkFlow();
                updatedWorkFlow.push(obj);
            } else {
                if(updatedWorkFlow[index].sourceState.isFirst){
                    this.setState({
                        isFirst : false
                    });
                }
                updatedWorkFlow.splice(index, 1);
            }
        }
        this.setState({workflow: updatedWorkFlow});
    };

    getWorkFlow = (wflow, index) => {
        const createWorkFlow = [];
        const { workflow, isFirst } = this.state;
        Object.keys(wflow).map((wfKey, i) => {
            if (wfKey === "sourceState") {
                createWorkFlow.push(
                    <div key={`source_state_${i}`} className="col-lg-4 col-md-12">
                        <SelectBox
                            options={this.state.states}
                            labelName='Source State'
                            onChange={(name, value) => this.handleWorkflowChange(name, value, undefined, index)}
                            name='sourceState'
                            value={workflow[index].sourceState.value}
                            errorsMessage = {workflow[index][wfKey].errorMessage || '' }
                        />
                        <CheckBox
                            name="makeItFirst"
                            labelText="Make it First Step"
                            disabled={isFirst && !workflow[index].sourceState.isFirst}
                            isChecked={workflow[index].sourceState.isFirst}
                            onChange={(name, value, checked) => this.handleWorkflowChange(name, checked, undefined, index)}
                        />
                    </div>
                )
            } else if (wfKey === "actionTarget") {
                createWorkFlow.push(this.getActionTargetElem(wflow[wfKey], index))
            }
        });
        return createWorkFlow;
    }

    getActionTargetElem = (actionTarget, workFlowIndex) => {
        const { workflow } =  this.state;
        return (<div className="col-lg-8 col-md-12" key={`at_${workFlowIndex}`}>
            {actionTarget.map( (actionTargetEl, index) => (<div key={`actionTarget_${workFlowIndex}_${index}`} className="action-target-section">
                <div className="col-lg-6">
                    <SelectBox
                        labelName='Action'
                        options={this.state.actions}
                        onChange={(name, value) => this.handleWorkflowChange(name, value,index, workFlowIndex)}
                        name='action'
                        value={workflow[workFlowIndex].actionTarget[index].action.value}
                        errorsMessage = {workflow[workFlowIndex].actionTarget[index].action.errorMessage || '' }
                    />
                </div>
                <div className="col-lg-6">
                    <SelectBox
                        labelName='Target State'
                        options={this.state.states}
                        onChange={(name, value) => this.handleWorkflowChange(name, value,index, workFlowIndex)}
                        name='targetState'
                        value={workflow[workFlowIndex].actionTarget[index].targetState.value}
                        errorsMessage = {workflow[workFlowIndex].actionTarget[index].targetState.errorMessage || '' }
                    />
                </div>
                <div className="form-action">
                    {this.getAddRemoveButton(index, 'actionTarget', workFlowIndex)}
                </div>
            </div>))
            }
        </div>);
    }

    getAddRemoveButton = (index, actionType, workFlowIndex) => {
        const btnType = index === 0 ? 'More' : 'remove';
        const {errorsMessage} = this.state;
        return (<Fragment><button
            className={`button fill big add-btn ${btnType === 'remove' ? 'remove' : ''}`}
            onClick={() => this.addRemoveObj(btnType, index, actionType, workFlowIndex)}>
            { index === 0 ? <i className="add-icon">
                <svg width="11" height="18" viewBox="-2 -3 30 27">
                    <path fill="#FFF" fillRule="evenodd"
                          d="M14.414.011H8.681V9.31H.015v5.377h8.666v9.3h5.733v-9.3h8.601V9.31h-8.601V.011z"></path>
                </svg>
            </i> :
            <i className="remove-icon">
                <svg width="11" height="18" viewBox="0 0 22 27">
                    <path fill="#FFF" fillRule="evenodd" d="M20.036 9.343v14.572c0 1.701-1.394 3.085-3.108 3.085H5.072c-1.714 0-3.108-1.384-3.108-3.085V9.343A3.086 3.086 0 010 6.478V6.32c0-1.701 1.394-3.085 3.108-3.085h4.235A3.678 3.678 0 0111 0a3.677 3.677 0 013.657 3.235h4.235C20.606 3.235 22 4.619 22 6.32v.158c0 1.3-.815 2.412-1.964 2.865zM3.438 23.915a1.63 1.63 0 001.634 1.622h11.856a1.63 1.63 0 001.634-1.622V9.563H3.438v14.352zM11 1.463a2.209 2.209 0 00-2.168 1.772h4.336A2.208 2.208 0 0011 1.463zm9.526 4.857a1.63 1.63 0 00-1.634-1.622H3.108A1.63 1.63 0 001.474 6.32v.158A1.63 1.63 0 003.108 8.1h15.784a1.63 1.63 0 001.634-1.622V6.32zm-5.575 17.346a.734.734 0 01-.737-.731v-8.236c0-.404.33-.731.737-.731s.737.327.737.731v8.236c0 .404-.33.731-.737.731zm-3.951 0a.734.734 0 01-.737-.731v-8.236c0-.404.33-.731.737-.731s.737.327.737.731v8.236c0 .404-.33.731-.737.731zm-3.951 0a.734.734 0 01-.738-.731v-8.236c0-.404.331-.731.738-.731.406 0 .736.327.736.731v8.236c0 .404-.33.731-.736.731z"></path>
                </svg>
            </i>}
        </button>
        {errorsMessage && <label>&nbsp;</label>}
        </Fragment>)
    }

    getWorkFlowElement = () => {
        const { workflow } = this.state;
        return workflow.map((wflow, index, wfArr) => {
            return (<div id={index} key={`wf_${index}`} className="workflow mb10">
                <div className="workflow-action mr10">
                    {this.getAddRemoveButton(index, 'workFlow')}
                </div>
                <div className="workflow-form col-lg-11 pr0 pl0 pt15">
                    {this.getWorkFlow(wflow, index)}
                </div>
            </div>)
        });
    }

    editWorkFlow = (id) => {
        const { workflowList } = this.state;
        const updatedWorkflow = mapWorflowForEdit(workflowList, id);
        this.setState({
            ...updatedWorkflow
        }, () => scrollToTop())
    }

    resetForm = () => {
        this.setState({
            name: '',
            description: '',
            isFirst: false,
            workflow: workFlowState(),
            requestEdit: false,
            errorsMessage: '',
            packageInstanceMapping: '',
            errorObj: {
                name: '',
                description: '',
                packageInstanceMapping: ''
            },
            formError: '',
        }, () => this.loadHandler())
    };

    render() {
        const {
            name, description, isRender, requestEdit,
            packageInstanceMapping, packageMappingList, formError, errorObj, workflowList
        } = this.state,
            workFlowElement = this.getWorkFlowElement();
        return (
            isRender ?
            <div className="admin-workflow">
            <h2 className="heading mb-15">{TEXT.WORKFLOW_TITLE}</h2>
            <div className="sub-heading">{TEXT.ACTIONS_SUBTITLE}</div>
            <div className="content-form no-gutters">
                <div className="col-lg-8 no-gutters">
                    <h3 className="content-title">{`${requestEdit ? 'UPDATE' : 'CREATE'} WORKFLOW`}</h3>
                    <div className="action-name-section col-lg-6">
                        <InputBox
                            labelName='Workflow Name'
                            name='name'
                            className='form-control'
                            type="text"
                            value={name}
                            onChange={this.handleChange}
                            errorsMessage={errorObj.name || ''}
                        />
                    </div>
                    <div className="action-desc-section">
                        <TextArea
                            labelName='Description'
                            name='description'
                            className='form-control'
                            pholder="by this you can change assignment of any request."
                            onChange={this.handleChange}
                            value={description}
                            errorsMessage={errorObj.description || ''}
                        />
                    </div>
                    <div className="package-instance-mapping col-lg-6">
                        <SelectBox
                            labelName='Package Instance Mapping'
                            name='packageInstanceMapping'
                            onChange={this.handleChange}
                            options={packageMappingList}
                            value={packageInstanceMapping}
                            errorsMessage={errorObj.packageInstanceMapping || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="content-title">LET’S DESIGN THE WORKFLOW</h3>
                        <div className="workflow-section">
                            {workFlowElement}
                        </div>
                    </div>
                </div>
                <div className="ml40">
        {formError && <label className="error-message">{formError}</label>}
                    <button className="button fill big w-210 mt10 mb30" onClick={this.handleSubmit}>
                        {`${requestEdit ? 'UPDATE' : 'CREATE'} WORKFLOW`}
                    </button>
                </div>
            </div>
            <div className="table-container mt45">
              <h3>WORKFLOW {workflowList && `(${workflowList.length})`}</h3>
              <div className="table-data-list">
                  <Table showHead headings={WORKFLOW_TABLE_HEADING} rows={workflowList} handleClick = {(id)=>this.editWorkFlow(id)}/>
              </div>
            </div>
            {/*<Modal />*/}
          </div> : <Loader />
        );
    }
}

const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModalHandler, dispatch)
});

export default connect(null, mapDispatchToProps)(ActionWorkflow);
