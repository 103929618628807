import React, { Component, Fragment } from "react";
import Input from "../../Common/Input";
import Error from "../../Common/Error";
import {MODALS} from "../../../storeManager/modal/constants";
import {POLICY_URL} from "../../../constants";

class IntentForm extends Component {
  renderForm = intentFormArray => {
    const formType = "intentForm";
    const {
      intentForm,
      inputChangedHandler,
      radioChangeHandler,
      addRemoveObj,
      selectHandler,
      selectInputConfigHandler,
      termsConditionChangeHandler,
      formConfig,
      formSubmit,
      formErrorMessage,
      openModal,
      isSecondApplication
    } = this.props;
    let formArr = [];
    for (let i = 0; i < intentFormArray.length; i++) {
      const formElement = intentFormArray[i];
      if (formElement.config.isHeader) {
        formArr.push(
          <div className="pb20" key={`${formElement.id}${i}`}>
            <div className={`title-2 pb10 ${formElement.id}${i}`}>{formElement.config.isHeader}</div>
            <hr className="no-space" />
          </div>
        );
      } else if (formElement.config.isSubHeader) {
        formArr.push(
        <div className="mb5" key={`${formElement.id}${i}`}>{formElement.config.isSubHeader}{formElement.config.isRequired ? <span className='required'>*</span>:null}</div>
        );
      } 
      else if (formElement.config.isTermsAndCondition) {
        if( formConfig && formConfig.buttonArray && !!formConfig.buttonArray.length){
        formArr.push(<div key={`${formElement.id}${i}`} className="notes align-left mtb20">
          <div className={`checkbox-element ${!formElement.config.valid ? 'error' : ''}`}>
            <input type="checkbox" checked={formElement.config.value} onChange={(e) => { termsConditionChangeHandler(e.target.checked, formType) }} />
            <span className="checkmark" />
          </div>
          <span className="pl30 pr5">By submitting, I agree to all</span>
          <span
              className="title"
              onClick={()=>{
                openModal(MODALS.FILE_VIEWER_POPUP, {
                  customModalContentClass: "file-viewer-modal",
                  url: POLICY_URL.SUBMISSION_OF_INTENT,
                  urlType: "docx",
                });
              }}
          >
            Submission of Intent
          </span>
          <span className='required'>*</span>
          {!formElement.config.valid ? <span className="invalid-text">{formElement.config.errorMessage}</span> : null}
        </div>)
        }
      }
       else if (Array.isArray(formElement.config)) {
        let disabledClass = '';
        if(formElement.id === 'selectInputConfig') {
          const isExistingRelationship = intentForm.existingRelationship.radioConfig[0].elementConfig.checked;
          if(!isExistingRelationship) {
            disabledClass = 'disabled';
          }
        }
        formElement.config.map((configObj, index) => {
          formArr.push(
            <div className={`form-control-group ${disabledClass}`} key={`${formElement.id}${index}`}>
              <Input
                labelConfig={configObj.labelConfig}
                elementType={configObj.elementType}
                elementConfig={configObj.elementConfig}
                value={configObj.value}
                valid={configObj.valid}
                errorMessage={configObj.errorMessage}
                touched={configObj.touched}
                wrapperClasses={configObj.wrapperClasses}
                inputGroupClasses={configObj.inputGroupClasses}
                selectConfig={configObj.selectConfig}
                dataListConfig = {configObj.dataListConfig}
                selectHandler={event => {
                  selectHandler(event, formElement.id, index, formType);
                }}
                changed={event =>
                  selectInputConfigHandler(
                    formType,
                    event,
                    formElement.id,
                    index,
                    configObj.id
                  )
                }
                isGstDisabled={!!disabledClass}
              />

              <button
                type="button"
                className={`button fill big ml10 ${configObj.buttonConfig.buttonClass}`}
                onClick={e => {
                  e.preventDefault();
                  addRemoveObj(
                    formType,
                    formElement.id,
                    configObj.buttonConfig.type,
                    index
                  );
                }}
              >
                {configObj.buttonConfig.type === "More" ? (
                  <>
                    <i>
                      <svg width="12" height="13" viewBox="0 0 23 24">
                        <path
                          fill="#FFF"
                          fillRule="evenodd"
                          d="M14.414.011H8.681V9.31H.015v5.377h8.666v9.3h5.733v-9.3h8.601V9.31h-8.601V.011z"
                        />
                      </svg>
                    </i>
                    <div className="text">More</div>
                  </>
                ) : (
                    <i className="delete-icon">
                      <svg width="14" height="18" viewBox="0 0 22 27">
                        <path
                          fill="#FFF"
                          fillRule="evenodd"
                          d="M20.036 9.343v14.572c0 1.701-1.394 3.085-3.108 3.085H5.072c-1.714 0-3.108-1.384-3.108-3.085V9.343A3.086 3.086 0 010 6.478V6.32c0-1.701 1.394-3.085 3.108-3.085h4.235A3.678 3.678 0 0111 0a3.677 3.677 0 013.657 3.235h4.235C20.606 3.235 22 4.619 22 6.32v.158c0 1.3-.815 2.412-1.964 2.865zM3.438 23.915a1.63 1.63 0 001.634 1.622h11.856a1.63 1.63 0 001.634-1.622V9.563H3.438v14.352zM11 1.463a2.209 2.209 0 00-2.168 1.772h4.336A2.208 2.208 0 0011 1.463zm9.526 4.857a1.63 1.63 0 00-1.634-1.622H3.108A1.63 1.63 0 001.474 6.32v.158A1.63 1.63 0 003.108 8.1h15.784a1.63 1.63 0 001.634-1.622V6.32zm-5.575 17.346a.734.734 0 01-.737-.731v-8.236c0-.404.33-.731.737-.731s.737.327.737.731v8.236c0 .404-.33.731-.737.731zm-3.951 0a.734.734 0 01-.737-.731v-8.236c0-.404.33-.731.737-.731s.737.327.737.731v8.236c0 .404-.33.731-.737.731zm-3.951 0a.734.734 0 01-.738-.731v-8.236c0-.404.331-.731.738-.731.406 0 .736.327.736.731v8.236c0 .404-.33.731-.736.731z"
                        />
                      </svg>
                    </i>
                  )}
              </button>
            </div>
          );
        });
      } else if (
        Array.isArray(formElement.config.radioConfig) &&
        formElement.config.withoutInput
      ) {
        formArr.push(
          <Input
            key={`${formElement.id}${i}`}
            labelConfig={formElement.config.labelConfig}
            wrapperClasses={formElement.config.wrapperClasses}
            valid={formElement.config.valid}
            inputGroupClasses={formElement.config.inputGroupClasses}
            radioConfig={formElement.config.radioConfig}
            // isDisabled={isSecondApplication}
            radioChangeHandler={(event, index) =>
              radioChangeHandler(event, formElement.id, index, formType)
            }
          />
        );
      } else if (Array.isArray(formElement.config.radioConfig)) {
          let disabledClass = '';
        if(formElement.id === 'gst') {
          const isGst = intentForm.gst.radioConfig[0].elementConfig.checked;
          if(!isGst) {
              disabledClass = 'disabled';
          }
        }
        formArr.push(
          <div className={`form-control-group ${disabledClass}`} key={`${formElement.id}${i}`}>
            <Input
              labelConfig={formElement.config.labelConfig}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              valid={formElement.config.valid}
              errorMessage={formElement.config.errorMessage}
              touched={formElement.config.touched}
              wrapperClasses={formElement.config.wrapperClasses}
              inputGroupClasses={formElement.config.inputGroupClasses}
              radioConfig={formElement.config.radioConfig}
              isDisabled={isSecondApplication}
              radioChangeHandler={(event, index) =>
                radioChangeHandler(event, formElement.id, index, formType)
              }
              changed={(event, index) =>
                inputChangedHandler(event, formElement.id, formType)
              }
              isGstDisabled={!!disabledClass}
            />
          </div>
        );
      } else {
        formArr.push(
          <Input
            key={`${formElement.id}${i}`}
            labelConfig={formElement.config.labelConfig}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            valid={formElement.config.valid}
            errorMessage={formElement.config.errorMessage}
            touched={formElement.config.touched}
            wrapperClasses={formElement.config.wrapperClasses}
            inputGroupClasses={formElement.config.inputGroupClasses}
            changed={event =>
              inputChangedHandler(event, formElement.id, formType)
            }
          />
        );
      }
    }

    formErrorMessage && formArr.push(<Error key={'IntentFormErr'} error={formErrorMessage} />)    

    if (formConfig && formConfig.buttonArray) {
      formConfig.buttonArray.forEach((item, index) => {
        formArr.push(
          <Fragment key={index}>
            {
              (item.priority === 'PRIMARY') ?
                <button
                  className="button fill big w-210 mt10 mb30 mr20"
                  type="submit"
                  value="delete"
                  onClick={(e) => formSubmit(e, 'intentForm', item)}
                >
                  {item.label}
                </button>
                :
                (item.priority === 'SECONDARY') ?
                <button
                  className="button action big w-210 mt10 mb30"
                  type="button"
                  value="Update"
                  onClick={(e) => formSubmit(e, 'intentForm', item)}
                >
                  {item.label}
                </button>
                :null
            }
          </Fragment>)
      })
    }
    return formArr;
  };



  render() {
    const {
      intentForm,
      // formSubmit,
      // formErrorMessage,
      formConfig,
    } = this.props;
    const intentFormArray = [];
    const intentFormKeys = Object.keys(intentForm);
    intentFormKeys.forEach(key => {
      intentFormArray.push({
        id: key,
        config: intentForm[key]
      });
    });

    let renderedForm = this.renderForm(intentFormArray);

    return (
      <div className="intent-form">
        <div className="pb25">
          <div className="title-1 pr10">Intent Form</div>

          <div className="description">To be filled by applicant</div>
        </div>

        <form>
            <fieldset disabled={!formConfig.editable} className={`${!formConfig.editable ? "application-form-disabled":''}`}>
          {renderedForm}
            </fieldset>
        </form>
      </div>
    );
  }
}

export default IntentForm;
