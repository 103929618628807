import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { PATHS, MESSAGES } from "../../constants";
import './index.scss'
// import logo from "../../assets/images/logo.png";
import logo from "../../assets/images/tata-play-logo-black.png";

const SomethingWentWrong = (props) => {
    const role = JSON.parse(localStorage.getItem('role')) || {};
    const { serverError } = props;
    const serverErrorMessage = (
        <div className="heading pb30">
            {MESSAGES.SERVER_ERROR}
        </div>
    );

    const somethingWentWrongMessage = (
        <div className="heading pb30">
            {MESSAGES.SOMETHING_WENT_WRONG}
        </div>
    );

    return (
      <Fragment>
        <div className="p25">
          <Link
            to={
              role.description === "Partner"
                ? PATHS.APPLICATION_FORM
                : PATHS.HOME
            }
          >
            <img src={logo} alt="Tata Play" className="home-logo" />
          </Link>
        </div>

        <div className="server-error mb30">
          {serverError ? serverErrorMessage : somethingWentWrongMessage}

          <i className="server-error-icon mb30">
            <svg
              id="Layer_1"
              x="0"
              y="0"
              viewBox="0 0 90.4 88.4"
              width="100"
              height="100"
              xmlSpace="preserve"
            >
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="15.526"
                y1="18.169"
                x2="17.419"
                y2="18.169"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_1_)"
                strokeMiterlimit="10"
                d="M16 17.7h.9v.9H16z"
              />
              <linearGradient
                id="SVGID_2_"
                gradientUnits="userSpaceOnUse"
                x1="21.312"
                y1="18.169"
                x2="23.205"
                y2="18.169"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_2_)"
                strokeMiterlimit="10"
                d="M21.8 17.7h.9v.9h-.9z"
              />
              <linearGradient
                id="SVGID_3_"
                gradientUnits="userSpaceOnUse"
                x1="27.099"
                y1="18.169"
                x2="28.992"
                y2="18.169"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_3_)"
                strokeMiterlimit="10"
                d="M27.6 17.7h.9v.9h-.9z"
              />
              <linearGradient
                id="SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1="15.526"
                y1="41.314"
                x2="17.419"
                y2="41.314"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_4_)"
                strokeMiterlimit="10"
                d="M16 40.9h.9v.9H16z"
              />
              <linearGradient
                id="SVGID_5_"
                gradientUnits="userSpaceOnUse"
                x1="21.312"
                y1="41.314"
                x2="23.205"
                y2="41.314"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_5_)"
                strokeMiterlimit="10"
                d="M21.8 40.9h.9v.9h-.9z"
              />
              <linearGradient
                id="SVGID_6_"
                gradientUnits="userSpaceOnUse"
                x1="27.099"
                y1="41.314"
                x2="28.992"
                y2="41.314"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_6_)"
                strokeMiterlimit="10"
                d="M27.6 40.9h.9v.9h-.9z"
              />
              <linearGradient
                id="SVGID_7_"
                gradientUnits="userSpaceOnUse"
                x1="6.846"
                y1="44.207"
                x2="83.572"
                y2="44.207"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                d="M12.1 76.5h28.4L37 82.3h45.6l-7.9-13v-12c0-1.4-.6-2.7-1.7-3.6l-.9-.8.9-.8a4.6 4.6 0 001.7-3.6V34.1c0-1.4-.6-2.7-1.7-3.6l-.9-.8.9-.8a4.6 4.6 0 001.7-3.6V10.9c0-2.6-2.1-4.8-4.8-4.8H12.1c-2.6 0-4.8 2.1-4.8 4.8v14.5c0 1.4.6 2.7 1.7 3.6l.9.8-.9.7a4.6 4.6 0 00-1.7 3.6v14.5c0 1.4.6 2.7 1.7 3.6l.9.8-.9.6a4.6 4.6 0 00-1.7 3.6v14.5c0 2.6 2.2 4.8 4.8 4.8zm69 4.9H38.6l21.2-35 21.3 35zm-7.2-24.2v10.6l-8.8-14.4H70c2.1-.1 3.9 1.7 3.9 3.8zM8.2 25.4V10.9C8.2 8.8 10 7 12.1 7H70c2.1 0 3.9 1.7 3.9 3.9v14.5c0 2.1-1.7 3.9-3.9 3.9H12.1c-2.1 0-3.9-1.8-3.9-3.9zm0 23.1V34.1c0-2.1 1.7-3.9 3.9-3.9H70c2.1 0 3.9 1.7 3.9 3.9v14.5c0 2.1-1.7 3.9-3.9 3.9h-5.4l-4.7-7.8-4.7 7.8h-43c-2.2-.1-4-1.8-4-4zm0 8.7c0-2.1 1.7-3.9 3.9-3.9h42.5L41.1 75.6h-29c-2.1 0-3.9-1.7-3.9-3.9V57.2z"
                stroke="url(#SVGID_7_)"
                strokeMiterlimit="10"
              />
              <linearGradient
                id="SVGID_8_"
                gradientUnits="userSpaceOnUse"
                x1="15.526"
                y1="64.459"
                x2="17.419"
                y2="64.459"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_8_)"
                strokeMiterlimit="10"
                d="M16 64h.9v.9H16z"
              />
              <linearGradient
                id="SVGID_9_"
                gradientUnits="userSpaceOnUse"
                x1="21.312"
                y1="64.459"
                x2="23.205"
                y2="64.459"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_9_)"
                strokeMiterlimit="10"
                d="M21.8 64h.9v.9h-.9z"
              />
              <linearGradient
                id="SVGID_10_"
                gradientUnits="userSpaceOnUse"
                x1="27.099"
                y1="64.459"
                x2="28.992"
                y2="64.459"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_10_)"
                strokeMiterlimit="10"
                d="M27.6 64h.9v.9h-.9z"
              />
              <linearGradient
                id="SVGID_11_"
                gradientUnits="userSpaceOnUse"
                x1="58.923"
                y1="64.459"
                x2="60.816"
                y2="64.459"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_11_)"
                strokeMiterlimit="10"
                d="M59.4 58.2h.9v12.5h-.9z"
              />
              <linearGradient
                id="SVGID_12_"
                gradientUnits="userSpaceOnUse"
                x1="58.923"
                y1="76.032"
                x2="60.816"
                y2="76.032"
              >
                <stop offset="0" stopColor="#e8308f" />
                <stop offset=".489" stopColor="#e8308f" />
                <stop offset="1" stopColor="#e8308f" />
              </linearGradient>
              <path
                stroke="url(#SVGID_12_)"
                strokeMiterlimit="10"
                d="M59.4 75.6h.9v.9h-.9z"
              />
            </svg>
          </i>

          <p className="go-to-text">
            Go back to
            <Link to={PATHS.HOME} className="previous-page prl5">
              Dashboard
            </Link>
          </p>
        </div>
      </Fragment>
    );
};

export default SomethingWentWrong;
