export const createWorkFlow = () => {
  return {
    sourceState: {
      value: "",
      errorMessage: "",
      isFirst: false,
    },
    id: null,
    actionTarget: [createActionTarget()]
  }
}

export const createActionTarget = () => {
  return {
    id: null,
    action: {
      value: "",
      errorMessage: "",
    },
    targetState: {
      value: "",
      errorMessage: "",
    }
  }
}

export const workFlowState=() =>  ([
  createWorkFlow()
]);

export const mapWorflowForEdit = (workflowList, id) => {
  const selectedWorkFlow = workflowList.find( wf => wf.id === id );
  let workflowData = [];
  let updatedState = {};
  selectedWorkFlow.binders.forEach(singleBinder =>{
    const { sourceState, action, targetState, id, startingPoint } = singleBinder;
    const isExistWorkFlowIndex = workflowData.findIndex((wf) => wf.sourceState.value == sourceState.id);
    if(isExistWorkFlowIndex !== -1) {
      workflowData[isExistWorkFlowIndex].actionTarget.push(
        {
          id: id.toString(),
          action: {value: action.id.toString(), errorMessage: ""},
          targetState: {value: targetState.id.toString(), errorMessage: ""}
        }
      )
    } else {
      workflowData.push({
        id: id,
        sourceState: {
          value: sourceState.id.toString(),
          errorMessage: "",
          isFirst: startingPoint,
        },
        actionTarget: [{
          id: id.toString(),
          action: {value: action.id.toString(), errorMessage: ""},
          targetState: {value: targetState.id.toString(), errorMessage: ""}
        }]
      })
    }
  });

  if(selectedWorkFlow) {
    const { name, description, id, packageInstanceMapping } = selectedWorkFlow;
     updatedState = {
      name,
      description,
      isFirst: true,
      workflow: workflowData,
      packageInstanceMapping: packageInstanceMapping.id.toString(),
      requestEdit: true,
      updateWorkflowId: id
    }
  }
  return updatedState;
}

export const requestMapperForWorkFlow = (payload) => {
  const { description, name, packageInstanceMapping, workflow, updateWorkflowId, requestEdit } = payload;
  const requestObj = {
    description: description,
    name: name,
    packageInstanceMapping: {
      id: packageInstanceMapping
    }
  }

  if(requestEdit){
    requestObj.id = updateWorkflowId;
  }

  const binders = [];
  // create binders
  workflow.forEach( (singleWF, index) => {
      const curSourceState = { id: singleWF.sourceState.value };
      const isStartingPoint = singleWF.sourceState.isFirst;
      singleWF.actionTarget.forEach( item => {
        binders.push({
          id: item.id,
          sourceState: curSourceState,
          action: { id: item.action.value },
          startingPoint: isStartingPoint,
          targetState: { id: item.targetState.value }
        })
      });
  })
  requestObj.binders = binders;
  return (requestObj);
}
