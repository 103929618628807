import React, { Fragment } from "react";

import TaskListHeader from "./TaskListHeader";
import TaskList from "./TaskList";
import TaskListPagination from "./TaskListPagination";
import NoDataFound from "../../Common/NoDataFound";

const RightPanel = props => {
  const {
    taskList,
    redirectOnClick,
    selectedRoleObj,
    showResultsArray,
    itemsPerPage,
    itemsPerPageChanged,
    handlePageChange,
    selectedPage,
    onChangeSearchHandler,
    headerValues,
    headerOnChange,
    totalCount,
    lobList,
    tabChangeHandler,
    activeTab
  } = props;

  const startingIndex = (itemsPerPage && selectedPage && (itemsPerPage * (selectedPage-1))) || 0;
  let totalPageCount = 1;
  if(totalCount){
    totalPageCount = Math.floor(totalCount / itemsPerPage) +
    (totalCount % itemsPerPage > 0 ? 1 : 0);
  totalPageCount = totalPageCount > 0 ? totalPageCount : 1;
  }
  const isOwnTask = () => {
    const currentUserRoleId = JSON.parse(localStorage.getItem('role')) && JSON.parse(localStorage.getItem('role')).id;
    return selectedRoleObj.id === currentUserRoleId;
  }


  return (
    <Fragment>
      <TaskListHeader
        isOwnTask={isOwnTask()}
        pendingRequests={totalCount || 0}
        onChangeSearchHandler={onChangeSearchHandler}
        headerValues={headerValues}
        headerOnChange={headerOnChange}
      />
      <div className="tab-header-wrapper pb10 pl30">
        {lobList.map((lob,i)=><span
            key={i}
            className={activeTab === i ? 'tab-header activeTab' : 'tab-header'}
            onClick={() => tabChangeHandler(i)}
            style={{fontSize: "18px"}}
        >
          {lob.name} ({lob.total})
        </span>)}
    </div>
      {taskList && taskList.length ? (
        <Fragment>
          <TaskList taskList={taskList} redirectOnClick={redirectOnClick} startingIndex={startingIndex} lobList={lobList} tabChangeHandler={tabChangeHandler} activeTab={activeTab}/>
          <TaskListPagination
            totalCount={totalCount || 0}
            selectedRole={selectedRoleObj.label || ""}
            showResultsArray={showResultsArray}
            itemsPerPage={itemsPerPage}
            itemsPerPageChanged={itemsPerPageChanged}
            handlePageChange={handlePageChange}
            selectedPage={selectedPage}
            totalPageCount={totalPageCount}
          />
        </Fragment>
      ) : (
        <NoDataFound />
      )}
    </Fragment>
  );
};

export default RightPanel;
