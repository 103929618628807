export const TEXT = {
  ACTIONS_TITLE: "Actions",
  ACTIONS_SUBTITLE: "Action Button lets you instantly #TakeAction on news that matters. Don’t just read the headlines, change them.",
  STATE_TITLE: "States",
  WORKFLOW_TITLE: "Workflow",
  FORM_VISIBILITY: "Form Visibility",
  FORM_VISIBILITY_SUBTITLE: "",
  BUSINESS_CONTROLLER: "Business Controller",
  BUSINESS_CONTROLLER_SUBTITLE: "",
  BUSINESS_WORKFLOW: "Business Workflow",
  BUSINESS_WORKFLOW_SUBTITLE: "",
  NOTIFICATION_TEMPLATE_HEADING: "Notification Template",
  NOTIFICATION_TEMPLATE_SUBTITLE: "",
  EMAIL_TEMPLATE_HEADING: "Email Template",
  EMAIL_TEMPLATE_SUBTITLE: ""
}

export const ACTION_TABLE_HEADING = [
  {key:'name', value:'Name'},
  {key:'label' , value:'Label'},
  {key:'description' , value:'Description'},
  {key:'type' , value:'Type'},
  {key:'priority' , value:'Priority'},
  {key:'actionCategory', value:'Action Category'},
  {key:'status', value:'Status'},
  {key:'message', value:'Message'},
  {key:'actions', value:'Actions'},
]

export const FORM_VISIBILITY_HEADING = [
  {key:'formName', value:'Form Name'},
  {key:'formType' , value:'Form Type'},
  {key:'editable' , value:'Editable'},
  {key:'enable' , value:'Enable'},
  {key:'actions', value:'Actions'},
]

export const BUSINESS_CONTROLLER_HEADING = [
  {key:'name', value:'Name'},
  {key:'actions', value:'Actions'},
]

export const NOTIFICATION_TEMPLATE_HEADING = [
  {key:'name', value:'Name'},
  {key:'message', value:'Message'},
  {key:'actions', value:'Actions'},
]

export const STATE_TABLE_HEADING = [
  {key:'name', value:'Name'},
  {key:'description', value:'Description'},
  {key:'owners', value:'List of Owners'},
  {key:'sla', value:'SLA Days'},
  {key:'escalationOwner', value:'Escalation Owner'},
  {key:'defaultForm', value:'Form Type'},
  {key:'actions', value:'Actions'},
]

export const EMAIL_TEMPLATE_TABLE_HEADING = [
  {key:'friendlyTemplateName', value:'Name'},
  {key:'emailTemplateName', value:'templateName'},
  {key:'sendTosOR', value:'Mail To'},
  {key:'sendCcsOR', value:'Mail CC'},
  {key:'actions', value:'Actions'},
]

export const BUSINESS_WORKFLOW_HEADING = [
  {key:'name', value:'Name'},
  {key:'actions', value:'Actions'},
]

export const WORKFLOW_TABLE_HEADING = [
  {key:'name', value:'Name'},
  {key:'description', value:'Description'},
  {key:'created', value:'Created Date'},
  {key:'status', value:'Status'},
  {key:'actions', value:'Actions'}
]
