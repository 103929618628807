import React, { Component } from "react";

class MobileViewLeftSidebar extends Component {
    applicationFormDropdown = React.createRef();
    state = {
        listOpen: false,
        dropdownList: [
            {
                serial: "01",
                title: "Intent Form",
                subTitle: "To be filled by applicant",
                formKey: "intentForm"
            },
            {
                serial: "02",
                title: "Evaluation Form",
                subTitle: "To be filled by ASI / ASM",
                formKey: "evaluationForm"
            },
            {
                serial: "03",
                title: "Documents Softcopy",
                subTitle: "To be uploaded by Applicant after ASI/ASM Visit",
                formKey: "documentForm"
            },
            {
                serial: "04",
                title: "Documents Hardcopy",
                subTitle: "To be sent by Applicant when asked by MIS",
                formKey: "originalDocument"
            }
        ],
    };

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.applicationFormDropdown.current && !this.applicationFormDropdown.current.contains(event.target)) {
            this.setState({
                listOpen: false,
            });
        }
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    handleClick = (index, item) => {
        const {handleSelect} = this.props;
        handleSelect(index);
        this.toggleList();
        this.setState({
            headerTitle: item
        })
    };

    toggleList = () => {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }))
    };

    render() {
        const {listOpen, dropdownList} = this.state;
        const {activeTab, formConfig} = this.props;
        return (
            <div ref={this.applicationFormDropdown}>
                <div onClick={() => this.toggleList()}
                     className={listOpen ? "left-sidebar-tab default mb5 open-dropdown " : "left-sidebar-tab default mb5"}>
                    <div className="list">
                        <div className="number">{dropdownList[activeTab].serial}</div>
                        <div className="value">
                            <div className="title">
                                {dropdownList[activeTab].title}
                            </div>
                            <div className="sub-title">
                                {dropdownList[activeTab].subTitle}
                            </div>
                        </div>

                        <i className="down-icon">
                            <svg width="12" height="20" viewBox="0 0 490.656 490.656">
                                <path fill="#686868"
                                      d="M484.132 124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86-7.208 0-13.964 2.792-19.036 7.86l-183.84 183.848L62.056 108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968 2.788-19.036 7.856l-16.12 16.128c-10.496 10.488-10.496 27.572 0 38.06l219.136 219.924c5.064 5.064 11.812 8.632 19.084 8.632h.084c7.212 0 13.96-3.572 19.024-8.632l218.932-219.328c5.072-5.064 7.856-12.016 7.864-19.224 0-7.212-2.792-14.068-7.864-19.128z"/>
                            </svg>
                        </i>
                    </div>
                </div>
                {
                    listOpen && (
                        <ul className={listOpen ? "left-sidebar-tab responsive-view" : "left-sidebar-tab"}>
                            {
                                dropdownList.map((item, index) => {
                                    const isNotAllowed = formConfig && formConfig[item.formKey] && formConfig[item.formKey].enable ? 'list' : 'list not-allowed';
                                    return (
                                        <li className={activeTab === index ? 'list active' : isNotAllowed}
                                            key={item.title}
                                            onClick={() => {
                                                this.handleClick(index, item)
                                            }}
                                        >
                                            <div className="number">{item.serial}</div>
                                            <div className="value">
                                                <div className="title">
                                                    {item.title}
                                                </div>
                                                <div className="sub-title">
                                                    {item.subTitle}
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
            </div>
        )
    }
}

export default MobileViewLeftSidebar;