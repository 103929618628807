import {ACTION} from "./constants";

export const resetReduxState = () => (dispatch) => {
  dispatch({
    type: ACTION.RESET_STATE,
  })
};

export const changeNotificationReadStatus = (index) => (
  { type: ACTION.CHANGE_NOTIFICATION_READ_STATUS, index }
);

export const setNotificationsList = (data) => (
  { type: ACTION.SET_NOTIFICATION_LIST, data }
);

/*
export const logout = () => (dispatch) => {
  dispatch({
    type: ACTION.LOGOUT,
  })
};*/
