import React, { Component } from 'react';
import '../InputBox/style.scss';
import './style.scss';

class TextArea extends Component {

  onChange = (event) => {
    let { name, onChange } = this.props;
    let { value } = event.target;
    onChange && onChange(name,value);
  }

  render() {
    let {labelName, name, value = '', pholder, errorsMessage, ...other } = this.props;
    return (
      <div className={`form-group ${errorsMessage && 'error'}`}>
        {labelName && <label>{labelName}</label>}
        <textarea 
          {...other}  
          value={value}
          name={name}
          placeholder={pholder}
          onChange={this.onChange}
        />
        {errorsMessage && <label className={`${errorsMessage && 'error-message'}`}>{errorsMessage}</label>}
      </div>
    );
  }
}

export default TextArea;