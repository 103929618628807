import React, { useEffect, useState } from 'react';
import { dateFormatter } from '../../../helper';

const StoreView = props => {
    const [gradeKeyValues, setGradeKeyValues] = useState({});
    const {store} = props.store;

    useEffect(() => {
        var acc = document.getElementsByClassName("accordion");
        var i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }

        const gradeComponentsArray = (store) => {
            let gradeArray = [];
            let gradeKeyObj = {};
            store.storeGradeValues.map(item => {
                if(item.storeTemplate.customTemplateIdentifier) {
                    // gradeArray.push({
                        gradeKeyObj[item.storeTemplate.customTemplateIdentifier] = item.storeTemplateTypeValues[0].optionKey ? item.storeTemplateTypeValues[0].optionKey.keyName : ""
                    // });
                    // return item.storeTemplateTypeValues;
                }
            });
            setGradeKeyValues(gradeKeyObj);
        }
        gradeComponentsArray(store);
    }, [store]);

    return (
      <div className="accordion-container">
        <button className="accordion">basic details of partner</button>
        <div className="panel">
          <table>
            <tr>
              <td>Name of Partner</td>
              <td>{store.partnerName}</td>
            </tr>
            <tr>
              <td>Family Details</td>
              <td>{store.familyDetails}</td>
            </tr>
            <tr>
              <td>Previous business and experience in retail </td>
              <td>{gradeKeyValues.prevExperience}</td>
            </tr>
            <tr>
              <td>Is any familiy member into runing DTH business. </td>
              <td>{store.isFamilyInDTH ? "Yes" : "No"}</td>
            </tr>
          </table>
        </div>
        <button className="accordion">basic details of proposed stores</button>
        <div className="panel">
          <table>
            <tr>
              <td>Whether town name is certified for store opening</td>
              <td>{store.storeDetails.isCertifiedToOpen ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td>Store Location</td>
              <td>{store.storeDetails.storeLocation}</td>
            </tr>
            <tr>
              <td>Projected acquisition from proposed store - Yearly </td>
              <td>{store.storeDetails.projectedAcquisition}</td>
            </tr>
            <tr>
              <td>Projected recharge base of proposed store - Yearly </td>
              <td>{store.storeDetails.projectedRecharge}</td>
            </tr>
            <tr>
              <td>Number of months for self-sustaining as per ROI </td>
              <td>{store.storeDetails.numberOfMonths}</td>
            </tr>
            <tr>
              <td>
                Partner has to enter minimum 3 year agreement with store owner
              </td>
              <td>
                {store.storeDetails.agreementWithStoreOwner ? "Yes" : "No"}
              </td>
            </tr>
          </table>
        </div>
        <button className="accordion">A) basic information of location</button>
        <div className="panel">
          <table>
            <tr>
              <td>Population of the Locality</td>
              <td>{store.storeLocationDetails.populationOfLocality}</td>
            </tr>
            <tr>
              <td>No of households in the Locality</td>
              <td>{store.storeLocationDetails.numberOfHouseholds}</td>
            </tr>
            <tr>
              <td>
                List of Pin Codes used to calculate Active subscriber base - TS
              </td>
              <td>
                {store.storeLocationDetails.activeBasePincodes
                  .split(",")
                  .join(", ")}
              </td>
            </tr>
            <tr>
              <td>Active subscriber base - TS</td>
              <td>{store.storeLocationDetails.activeSubscriber}</td>
            </tr>
            <tr>
              <td>Percentage share of TS</td>
              <td>{gradeKeyValues.percentageShare}</td>
            </tr>
            <tr>
              <td>Active subscriber base - Other DTH</td>
              <td>{store.storeLocationDetails.activeOtherSubscriber}</td>
            </tr>
            <tr>
              <td>Active subscriber base - Cable</td>
              <td>{gradeKeyValues.activeCableSubscriber}</td>
            </tr>
            <tr>
              <td>Monthly cable subscription amount</td>
              <td>{store.storeLocationDetails.monthlyCableSubscription}</td>
            </tr>
            <tr>
              <td>Recharge base @ average ARPU - TS</td>
              <td>{store.storeLocationDetails.rechargeBase}</td>
            </tr>
          </table>
        </div>
        <button className="accordion">
          B) additional information of location
        </button>
        <div className="panel">
          <table>
            <tr>
              <td>Whether TSBB is having presence</td>
              <td>{gradeKeyValues.isTSBBPresence}</td>
            </tr>
            <tr>
              <td>All Cluster Name which can be serviced by Store</td>
              <td>{store.storeLocationDetails.allClusterName}</td>
            </tr>
            <tr>
              <td>No of homepass</td>
              <td>{store.storeLocationDetails.numberOfHomepass}</td>
            </tr>
          </table>
        </div>
        <button className="accordion">C) store dimension and layout</button>
        <div className="panel">
          <table>
            <tr>
              <td>Is the Store on main road</td>
              <td>{gradeKeyValues.isStoreOnRoad}</td>
            </tr>
            <tr>
              <td>Brief Description of Location</td>
              <td>{store.storeDimensionLayout.briefDescription}</td>
            </tr>
            <tr>
              <td>Size of store ( LxB - In Sq Ft )</td>
              <td>{store.storeDimensionLayout.sizeOfStore}</td>
            </tr>
            <tr>
              <td>Minimum frontage (Shutter Size In Ft)</td>
              <td>{store.storeDimensionLayout.minimumFrontage}</td>
            </tr>
            <tr>
              <td>
                Whether the inside of Store is having hindrance of pillar or
                beam
              </td>
              <td>{store.storeDimensionLayout.isInsideStore ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td>
                Whether the store surface is plain for carrying out branding
                work
              </td>
              <td>
                {store.storeDimensionLayout.isStoreSurface ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td>
                Specify number of Airtel/Vodafone store in nearby vicinity
              </td>
              <td>{store.storeDimensionLayout.nearbyNumberOfStore}</td>
            </tr>
            <tr>
              <td>Specify Important landmark in nearby vicinity</td>
              <td>{store.storeDimensionLayout.nearbyImportantLandmark}</td>
            </tr>
            <tr>
              <td>
                Specify number of Tata Play Preferred shore in nearby vicinity
              </td>
              <td>{store.storeDimensionLayout.nearbyPreferredStore}</td>
            </tr>
            <tr>
              <td>Distance from current location of Tata Play Store - In KM</td>
              <td>{store.storeDimensionLayout.distanceFromCurrentLocation}</td>
            </tr>
            <tr>
              <td>Require Local Language for Branding &amp; POSM</td>
              <td>
                {store.storeDimensionLayout.isRequiredLocalLanguage
                  ? "Yes"
                  : "No"}
              </td>
            </tr>
            <tr>
              <td>Proposed date of store handing over for branding</td>
              <td>
                {dateFormatter(
                  store.storeDimensionLayout.proposedDateOfHandover,
                  "dd/MMM/yyyy"
                )}
              </td>
            </tr>
            <tr>
              <td>Proposed date of Launch of Store</td>
              <td>
                {dateFormatter(
                  store.storeDimensionLayout.proposedDateOfLaunch,
                  "dd/MMM/yyyy"
                )}
              </td>
            </tr>
          </table>
        </div>
        <button className="accordion">D) property details</button>
        <div className="panel">
          <table>
            <tr>
              <td>Property background</td>
              <td>{gradeKeyValues.propertyBackground}</td>
            </tr>
            <tr>
              <td>Monthly Rent (Rs)</td>
              <td>{store.storePropertyDetails.monthlyRented}</td>
            </tr>
            <tr>
              <td>Security Deposit Amount (Rs)</td>
              <td>{store.storePropertyDetails.securityDeposit}</td>
            </tr>
            <tr>
              <td>Yearly rental increase % in market</td>
              <td>{store.storePropertyDetails.yearlyRental}</td>
            </tr>
            <tr>
              <td>Businesses Done in Last 3 Yrs In this Store</td>
              <td>{store.storePropertyDetails.prevThreeYearsDetail}</td>
            </tr>
            <tr>
              <td>
                Has the store received any notice from Local authority for road
                widening
              </td>
              <td>{store.storePropertyDetails.noticeFromLocalAuthority}</td>
            </tr>
            <tr>
              <td>To obtain NOC that store is not under any Legal dispute</td>
              <td>{store.storePropertyDetails.legalDisputeNOC}</td>
            </tr>
            <tr>
              <td>
                Whether electricity connection capacity is sufficient for Brand
                Store - 5KVA load
              </td>
              <td>
                {store.storePropertyDetails.electricityConnectionCapacity}
              </td>
            </tr>
            <tr>
              <td>Whether water accumulation issue in rainy season</td>
              <td>{store.storePropertyDetails.waterAccumulationIssue}</td>
            </tr>
            <tr>
              <td>Whether any leakage issue inside the store area</td>
              <td>{store.storePropertyDetails.leakageIssue}</td>
            </tr>
            <tr>
              <td>Whether the store location is in LCO area</td>
              <td>{store.storePropertyDetails.storeLocationInLCO}</td>
            </tr>
            <tr>
              <td>What is the age of the building or Store</td>
              <td>{gradeKeyValues.ageOfBuilding}</td>
            </tr>
            <tr>
              <td>
                The permission for repair work should be obtained from Owner
              </td>
              <td>{store.storePropertyDetails.permissionToRepairWork}</td>
            </tr>
            <tr>
              <td>Adjacent Stores should not be under Negative list</td>
              <td>{store.storePropertyDetails.adjacentStores}</td>
            </tr>
          </table>
        </div>
        <button className="accordion">
          recommendation comment - swot format
        </button>
        <div className="panel">
          <table>
            <tr>
              <td>Strength of the Store Location</td>
              <td>{store.strength}</td>
            </tr>
            <tr>
              <td>Weakness of the Store Location</td>
              <td>{store.weakness}</td>
            </tr>
            <tr>
              <td>Opportunity of the Store Location</td>
              <td>{store.opportunity}</td>
            </tr>
            <tr>
              <td>Threat of the Store Location</td>
              <td>{store.threat}</td>
            </tr>
          </table>
        </div>
      </div>
    );
}

export default StoreView;