import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

export default class CustomInput extends Component {
  render() {
    const {
      elementConfig, /*elementType,*/ value, valid, changed, errorMessage, /*...rest*/
    } = this.props;
    const inputClasses = ['input-element'];
    let errorElement = null;
    if (!valid) {
      // inputParent.push('error');
      errorElement = <span className="validate invalid-text">{errorMessage}</span>;
    }
    return (
      <Fragment>
          <input
              className={inputClasses.join(' ')}
              {...elementConfig}
              placeholder={elementConfig.placeholder}
              value={value}
              onChange={e => changed(e.target.value)}
          />
          <div className="radio-group">
              <div>
                  <input type="radio" id="yes" name="drone" value="yes"
                         checked />
                      <label htmlFor="yes">Yes</label>
              </div>

              <div>
                  <input type="radio" id="no" name="drone" value="no" />
                      <label htmlFor="no">no</label>
              </div>
          </div>
          {errorElement}
      </Fragment>
    );
  }
}

CustomInput.propTypes = {
  // elementConfig : PropTypes.object,
  errorMessage: PropTypes.string,
  changed: PropTypes.func,
};
