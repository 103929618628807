import React from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
    const {
        elementConfig, value, valid, changed,
    } = props;
    const handleChange = (selectedOption) => {
        changed(selectedOption.value);
    }
    const inputClasses = ['custom-select'];
    let foundObj = null;
    if (value !== '') {
        foundObj = elementConfig.options.find(element => element.value === value);
    }
    if (!valid) {
        inputClasses.push('is-invalid');
    }
    if(elementConfig.disabled) {
        inputClasses.push('disabled');
    }

    const customStyles = (width = 100, height = 40) => {
        return {
            container: (base) => ({
                ...base,
                display:'inline-block',
                width: width,
            }),
            valueContainer: (base) => ({
                ...base,
                minHeight: height,
            })
        }
    }

    return (
        <React.Fragment>
            <Select
                value={foundObj}
                placeholder={elementConfig.placeholder}
                onChange={handleChange}
                options={elementConfig.options}
                className={inputClasses.join(' ')}
                isSearchable={true}
                styles={customStyles}
                // isDisabled={elementConfig.disabled}
            />
        </React.Fragment>
    );
}

export default CustomSelect;
