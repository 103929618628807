import React, { Component } from 'react';
import { TEXT, BUSINESS_CONTROLLER_HEADING } from '../constant';
import InputBox from '../InputBox';
import { commonService } from "../../../storeManager/common/services";
import { BussinessControllerData } from '../../../storeManager/common/repository';
import GlobalConfig from "../../../globalConfig/globalConfig";
import { openModal as openModalHandler } from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import { connect } from 'react-redux';
import { MODALS } from "../../../storeManager/modal/constants";
import { bindActionCreators } from 'redux';
import { AxiosService } from '../../../services/apiService';
import Table from '../Table';
import { scrollToTop } from '../../.././helper';
import Loader from '../../../components/Loader';
import InputsValidator from '../../../services/FormValidator/InputsValidator';

class AdminBusiness extends Component {
    state = {
        name: "",
        id: null,
        isEdit: false,
        isLoading: true,
        getLineOfBusinessData: [],
        errorObj: {
            name: ''
        }
    }

    componentDidMount = () => {
        this.loadHandler()
    }

    loadHandler = async () => {
        const getLineOfBusinessApi = new AxiosService(BussinessControllerData);
        Promise.all([
            getLineOfBusinessApi.doAjax()
        ])
            .then(res => {
                const [
                    { data: { data: getLineOfBusinessData, status } },
                ] = res;
                if(status){
                    this.setState({
                        getLineOfBusinessData,
                        isLoading: false
                    })
                }
                else{
                    this.setState({isLoading: false});
                }

            })
            .catch(err => {
                this.setState({isLoading: false});
                console.log('Error in api call', err);
            })
    };

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    };

    handleClick = async (id) => {
        const response = await commonService.getLineOfBussinessDetail(id);
        if(response){
            const {data: {data}} = response;
            this.setState({
                name: data.name,
                id: data.id,
                isEdit: true
            }, () => scrollToTop())
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { name,id } = this.state;
        const payload = {
            id: null||id,
            name: name
        }
        if (this.validateForm(payload)) {
            this.setState({
                isLoading: true
            });
            try {
                const response = await commonService.createLineOfBusiness(payload);
                const responseData = response.data;
                const { openModal } = this.props;
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    this.setState({
                        isLoading: false
                    });
                    openModal(MODALS.CONFIRMATION_POPUP, {
                        message: responseData.message
                    });
                    this.resetForm();
                } else {
                    this.setState({
                        error: responseData.message,
                        isLoading: false
                    })
                }
            }
            catch {
                this.setState({
                    error: GlobalConfig.serverError,
                    isLoading: false
                })
            }
        }
    }

    validateForm = (payload) => {
        const { errorObj } = this.state;
        const formStatus = InputsValidator.validateName(payload.name);
        if (formStatus.error) {
            errorObj.name = formStatus.message;
            this.setState({
                errorObj
            })
            return false;
        }
        else {
            errorObj.name = '';
            this.setState({
                errorObj
            })
            return true;
        }
    }

    resetForm = () => {
        this.setState({
            name: "",
            isEdit: false
        }, () => this.loadHandler())
    }

    render() {
        const { name,isEdit, getLineOfBusinessData, isLoading, errorObj } = this.state;
        return (
            <div className="business-workflow">
                {isLoading && <Loader />}
                <h2 className="heading mb-15">{TEXT.BUSINESS_CONTROLLER}</h2>
                <div className="sub-heading">{TEXT.BUSINESS_CONTROLLER_SUBTITLE}</div>
                <div className="content-form no-gutters">
                    <div className="col-lg-8 no-gutters">
                    <h3 className="content-title">{`${isEdit ? 'UPDATE' : 'CREATE'} Line of business`}</h3>
                        <div className="row">
                            <div className="form-name col-lg-6">
                                <InputBox
                                    labelName='Line Of Bussiness'
                                    name='name'
                                    className='form-control'
                                    type="text"
                                    value={name}
                                    onChange={this.handleChange}
                                    errorsMessage={errorObj.name || ''}
                                />
                            </div>
                        </div>
                        <div>
                            <button className="button fill big w-210 mt10 mb30 mr20" onClick={this.handleSubmit}>{`${isEdit ? 'UPDATE' : 'CREATE'}`}</button>
                        </div>
                    </div>
                </div>
                <div className="table-container mt45">
                    <h3>Form Visibility {getLineOfBusinessData && `(${getLineOfBusinessData.length})`}</h3>
                    <div className="table-data-list">
                        <Table showHead headings={BUSINESS_CONTROLLER_HEADING} rows={getLineOfBusinessData} handleClick={this.handleClick} />
                    </div>
                </div>
                {/*<Modal />*/}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
});

export default (connect(null, mapDispatchToProps)(AdminBusiness))
