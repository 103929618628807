export const API_PATH = {
  production: {
    API_ROOT: `${window.location.origin}/partnerOnBoard`,
    // S3_API_ROOT: `${window.location.origin}`,
  },
  development: {
    // API_ROOT: 'https://dzsduj1qdf7t7.cloudfront.net/partnerOnBoard', //Dev URL
    API_ROOT: "https://qa-associates.tataplay.com/partnerOnBoard", //QA URL
    // S3_API_ROOT: '',
  },
};

// export const logoLink = '';
