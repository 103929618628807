import React, { Component } from 'react';
import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";

// import "slick-carousel/slick/slick-theme.css";

import './style.scss';
// import './../../../../node_modules/react-slick/lib/'

class ReactSlick extends Component {
  render(){
    const {settings} = this.props;
    // var settings = {
    //   dots: false,
    //   slidesToShow: 2,
    //   slidesToScroll: 2
    // };
    // console.log("settings", settings)
    return (
      <Slider {...settings}>
        {this.props.children}
      </Slider>
    )
  }
}

export default ReactSlick;