import React, {Component} from 'react';
import Tooltip from '../Tooltip';
import CustomInput from '../CustomInput';
import CustomSelect from "../CustomSelect";
import { INPUT_MAX_LENGTH } from '../../../constants'

export default class Input extends Component {
  render() {
    const {
      labelConfig,
      elementConfig,
      elementType,
      value,
      valid,
      // touched,
      resendOtp,
      changed,
      wrapperClasses,
      errorMessage,
      inputGroupClasses,
      radioConfig,
      radioChangeHandler,
      reGenerateOtp,
      selectConfig,
      dataListConfig,
      selectHandler,
      isDisabled,
      isGstDisabled
      // ...rest
    } = this.props;
    const wrapperClass = [...wrapperClasses];
    const inputGroupClass = inputGroupClasses ? ['input-group-element', ...inputGroupClasses] : ['input-group-element'];

    let inputElement = null;

    let labelElement = null;

    let errorElement = null;

    let radioTextElement = null;
    let selectConfigElement = null;
    let dataListConfigElement = null;

    let inputClasses = ['input-element'];
    let radioClasses = ['radio-element'];
    let resendOtpField = (
        <span className="resend-otp" onClick={()=>reGenerateOtp()}>
          Resend
        </span>
    );

    if (labelConfig) {
      labelElement = (
        <label className={labelConfig.classes ? labelConfig.classes.join(' ') : ''}>
          {labelConfig.label}
          {
            labelConfig.optional ? <span className="optional-text">{labelConfig.optional}</span> : null
          }
        </label>
      );
    }

    if(selectConfig){
            selectConfigElement = (
          <select
              className={inputClasses.join(' ')}
              value={selectConfig.elementConfig.value}
              placeholder={selectConfig.elementConfig.placeholder}
              onChange={e => selectHandler(e.target.value)}
          >
              {selectConfig.elementConfig.options.map((option, index) => (
                  <option key={index} value={option.value}>
                      {option.label}
                  </option>
              ))}
          </select>
      )
    }

    if(radioConfig) {
        radioTextElement = (
            <div className="radio-group">
                {
                    radioConfig.map((obj, index)=>{
                        return (
                            <div className="radio-field" key={index}>
                                <input
                                    className="radio-element"
                                    id={`${obj.elementConfig.name}-${obj.labelConfig.label}`}
                                    {...obj.elementConfig}
                                    value={obj.elementConfig.checked}
                                    disabled={isDisabled}
                                    onChange={e => {
                                        radioChangeHandler(e.target.value, index)
                                    }}
                                />
                                <label
                                    htmlFor={`${obj.elementConfig.name}-${obj.labelConfig.label}`}
                                    className={obj.labelConfig.classes ? obj.labelConfig.classes.join(' ') : ' '}
                                >
                                    {obj.labelConfig.label}
                                </label>
                                <span className="select-radio" />
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    if (!valid) {
      wrapperClass.push('error');
      errorElement = <span className="validate invalid-text">{errorMessage}</span>;
    }

    switch (elementType) {
      case ('input'):
        inputElement = (
          <input
            className={inputClasses.join(' ')}
            {...elementConfig}
            placeholder={elementConfig.placeholder}
            value={value}
            onChange={e => changed(e.target.value)}
          />
          );
        if(elementConfig.type=='number'&&elementConfig.maxLength){
          inputElement = (
            <input
              className={inputClasses.join(' ')}
              {...elementConfig}
              placeholder={elementConfig.placeholder}
              value={value}
              onChange={e => {
                if(e.target.value.toString().split('').length<=elementConfig.maxLength)
                  changed(e.target.value)
              }}
            />
          )
        }
        break;
      case ('textarea'):
        inputElement = (
          <textarea
            className={inputClasses.join(' ')}
            {...elementConfig}
            placeholder={elementConfig.placeholder}
            value={value}
            onChange={e => changed(e.target.value)}
          />
        );
        break;
      case ('select'):
        inputElement = (
          <select
            className={inputClasses.join(' ')}
            value={value}
            placeholder={elementConfig.placeholder}
            onChange={e => changed(e.target.value)}
            disabled={elementConfig.disabled}
          >
            {elementConfig.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
        break;
        case ('dataList'):
        inputElement = (
          <React.Fragment>
          <input
            className={inputClasses.join(' ')}
            value={value}
            list="pinCode"
            onChange = {e => changed(e.target.value)}
            // maxLength = {INPUT_MAX_LENGTH.PARTNER_CODE_MAX_LENGTH}
            disabled = {elementConfig.disabled}
          />
          <datalist
            id="pinCode"
            className={inputClasses.join(' ')}
            placeholder={elementConfig.placeholder}
          >
          {elementConfig.options.map((option, index) => (
              <option key={index} value={option.label}>
              </option>
          ))}
        </datalist>
        </React.Fragment>
        );
        break;
      case ('customSelect'):
        inputElement = (
          <CustomSelect {...this.props} />
        );
        break;
      /*case ('customPhone'):
        inputElement = (
          <CustomPhone {...this.props} />
        );
        break;*/
      case ('customInput'):
          inputElement = (
              <CustomInput {...this.props} />
          );
          break;
      case ('file'):
        inputElement = (
            <input
                className={inputClasses.join(' ')}
                {...elementConfig}
                value={value}
                onChange={e => changed(e)}
            />
        );
        break;
      case ('radio'):
        inputElement = (
            <input
                className={radioClasses.join(' ')}
                {...elementConfig}
                value={value}
                onChange={e => changed(e.target.value)}
            />
        );
        break;
      default:
          inputElement = (
          <input
            className={inputClasses.join(' ')}
            {...elementConfig}
            value={value}
            onChange={e => changed(e.target.value)}
            disabled={isGstDisabled}
          />
        );
    }

    return (
      <div className={wrapperClass.join(' ')}>
        <div className={inputGroupClass.join(' ')}>
            {elementConfig && inputElement}
            {labelElement}
            {labelConfig && labelConfig.tooltip &&
              <div className="show-tooltip">
                <i className="info-icon">
                  <svg width="14" height="14" viewBox="0 0 28 28">
                    <path fill="#735b67" fillRule="evenodd" d="M13.999 28C6.28 28 0 21.72 0 14S6.28 0 13.999 0C21.719 0 28 6.28 28 14s-6.281 14-14.001 14zm0-26.164C7.293 1.836 1.836 7.293 1.836 14s5.457 12.164 12.163 12.164c6.708 0 12.165-5.457 12.165-12.164S20.707 1.836 13.999 1.836zm1.687 19.571h-3.372a.918.918 0 110-1.836h.768v-7.833h-.768a.919.919 0 010-1.837h1.687c.506 0 .917.411.917.918v8.752h.768a.917.917 0 110 1.836zM13.741 8.818a1.224 1.224 0 11-.002-2.448 1.224 1.224 0 01.002 2.448z" />
                  </svg>
                </i>
                <Tooltip message={labelConfig.tooltip} />
              </div>
            }
            {
                resendOtp && resendOtpField
            }
            {radioTextElement}
            {selectConfigElement}
            {dataListConfigElement}
        </div>
        {errorElement}
      </div>
    );
  }
}
