import {AxiosService} from '../../services/apiService';
import GlobalConfig from "../../globalConfig/globalConfig";

import {
    sendOtpApi,
    registerUserApi,
    dashBoardListApi,
    dashBoardFilterListApi,
    validateLoginAPI,
    getIntentForm,
    getPartnerDetails,
    getStateList,
    getCityList,
    getExistingRelationshipList,
    getBusinessAssociationList,
    downloadDocuments,
    partnerFormSubmitApi,
    fetchAdminActionsList,
    fetchAdminStateList,
    fetchPackageInstanceMapping,
    createWorkflow,
    fetchAllWorkflow,
    createAction,
    getActionDetails,
    createState,
    getStateDetails,
    actionTypeList,
    actionPriorityList,
    ownersList,
    getFormType,
    createFormVisibility,
    fetchFormVisibilityData,
    verifyEvdDetails,
    validateEvdOtp,
    assigneeList,
    partnerChangeStateApi,
    createLineOfBusiness,
    fetchBusinessControllerData,
    createBusinessWorkflow,
    getLineOfBussinessDetail,
    notificationListApi,
    notificationStatusChangeApi,
    getPincodeList,
    getTownsListApi,
    createNotificationTemplate,
    getNotificationTemplate,
    getEmailtemplateDetails,
    createEmailTemplate,
    getUploadDocumentTemplateApi,
    getStoreTemplates,
    activeSubscriber,
    logoutApi,
    downloadFilesApi,
    createPartnerDropdownListApi,
    createPartnerFormApi,
    getPartnerFormInfoApi,
    DraftPartnerFormApi
} from "./repository";

// import GlobalConfig from "../../globalConfig/globalConfig";

class CommonService {
    generateOtp(param) {
        sendOtpApi.params = param;
        const api = new AxiosService(sendOtpApi);
        return api.doAjax();
    }

    registerUserApi(param) {
        registerUserApi.params = param;
        const api = new AxiosService(registerUserApi);
        return api.doAjax();
    }

    dashBoardList(param) {
        dashBoardListApi.params = param;
        const api = new AxiosService(dashBoardListApi);
        return api.doAjax();
    }

    dashBoardFilterList(param) {
        dashBoardFilterListApi.params = param;
        const api = new AxiosService(dashBoardFilterListApi);
        return api.doAjax();
    }

    validateLoginOTP(param) {
        validateLoginAPI.params = param;
        const api = new AxiosService(validateLoginAPI);
        return api.doAjax();
    }

    getIntentForm(param) {
        getIntentForm.params = param;
        const api = new AxiosService(getIntentForm);
        return api.doAjax();
    }

    getStateList(param) {
        getStateList.params = param;
        const api = new AxiosService(getStateList);
        return api.doAjax();
    }

    getCityList(param) {
        getCityList.params = {};
        const api = new AxiosService({...getCityList, url: `${getCityList.url}${param.id}`});
        return api.doAjax();
    }

    getPincodeList(param) {
        this.getPincodeList.param = {};
        const api = new AxiosService({...getPincodeList, url: `${getPincodeList.url}/${param.cityId}`});
        return api.doAjax();
    }

    getTownsList(param) {
        this.getPincodeList.param = {};
        const api = new AxiosService({...getTownsListApi, url: `${getTownsListApi.url}/${param.pinCode}`});
        return api.doAjax();
    }

    getExistingRelationshipList() {
        const api = new AxiosService(getExistingRelationshipList);
        return api.doAjax();
    }

    getBusinessAssociationList(param) {
        getBusinessAssociationList.params = param;
        const api = new AxiosService(getBusinessAssociationList);
        return api.doAjax();
    }

    getPartnerDetails(id) {
        getPartnerDetails.url = `${GlobalConfig.API_ROOT}/partner/${id}`;
        const api = new AxiosService(getPartnerDetails);
        return api.doAjax();
    }

    partnerFormSubmit(param) {
        partnerFormSubmitApi.params = param;
        const api = new AxiosService(partnerFormSubmitApi);
        return api.doAjax();
    }

    downloadZIPDocuments(docId, partnerId) {
        downloadDocuments.url = `${GlobalConfig.API_ROOT}/document/downloadZipFile/${docId}/${partnerId}`;
        const api = new AxiosService(downloadDocuments);
        return api.doAjax();
    }

    fetchAdminActionsList(param) {
        fetchAdminActionsList.params = param;
        const api = new AxiosService(fetchAdminActionsList);
        return api.doAjax();
    }

    fetchAdminStateList(param) {
        fetchAdminStateList.params = param;
        const api = new AxiosService(fetchAdminStateList);
        return api.doAjax();
    }

    fetchFormVisibilityData(param) {
        fetchFormVisibilityData.params = param;
        const api = new AxiosService(fetchFormVisibilityData);
        return api.doAjax();
    }

    fetchBusinessControllerData(param) {
        fetchBusinessControllerData.params = param;
        const api = new AxiosService(fetchBusinessControllerData);
        return api.doAjax();
    }

    fetchPackageInstanceMapping(param) {
        fetchPackageInstanceMapping.params = param;
        const api = new AxiosService(fetchPackageInstanceMapping);
        return api.doAjax();
    }

    createWorkflow(param) {
        createWorkflow.params = param;
        const api = new AxiosService(createWorkflow);
        return api.doAjax();
    }

    fetchAllWorkflow(param) {
        fetchAllWorkflow.params = param;
        const api = new AxiosService(fetchAllWorkflow);
        return api.doAjax();
    }

    createAction(param) {
        createAction.params = param;
        const api = new AxiosService(createAction);
        return api.doAjax();
    }

    getActionDetails(id) {
        getActionDetails.url = `${GlobalConfig.API_ROOT}/action/${id}`;
        const api = new AxiosService(getActionDetails);
        return api.doAjax();
    }

    createState(param) {
        createState.params = param;
        const api = new AxiosService(createState);
        return api.doAjax();
    }

    createEmailTemplate(param) {
        createEmailTemplate.params = param;
        const api = new AxiosService(createEmailTemplate);
        return api.doAjax();
    }

    createFormVisibility(param) {
        createFormVisibility.params = param;
        const api = new AxiosService(createFormVisibility);
        return api.doAjax();
    }

    createLineOfBusiness(param) {
        createLineOfBusiness.params = param;
        const api = new AxiosService(createLineOfBusiness);
        return api.doAjax();
    }

    createNotificationTemplate(param) {
        createNotificationTemplate.params = param;
        const api = new AxiosService(createNotificationTemplate);
        return api.doAjax();
    }

    createBusinessWorkflow(param) {
        createBusinessWorkflow.params = param;
        const api = new AxiosService(createBusinessWorkflow);
        return api.doAjax();
    }

    getStateDetails(id) {
        getStateDetails.url = `${GlobalConfig.API_ROOT}/workflowState/${id}`;
        const api = new AxiosService(getStateDetails);
        return api.doAjax();
    }

    getEmailDetails(id) {
        getEmailtemplateDetails.url = `${GlobalConfig.API_ROOT}/emailTemplate/${id}`;
        const api = new AxiosService(getEmailtemplateDetails);
        return api.doAjax();
    }

    getFormDetail(id) {
        getFormType.url = `${GlobalConfig.API_ROOT}/formVisibility/${id}`;
        const api = new AxiosService(getFormType);
        return api.doAjax();
    }

    getBusinessWorkflowController(id) {
        fetchBusinessControllerData.url = `${GlobalConfig.API_ROOT}/lineofbusinessworkflow/${id}`;
        const api = new AxiosService(fetchBusinessControllerData);
        return api.doAjax();
    }

    // getLineOfBussinessToOnBoardData() {
    //     getLineOfBussinessToOnBoard.url = `${GlobalConfig.API_ROOT}/lineofbusiness/`;
    //     const api = new AxiosService(getLineOfBussinessToOnBoard);
    //     return api.doAjax();
    // }

    getLineOfBussinessDetail(id) {
        getLineOfBussinessDetail.url = `${GlobalConfig.API_ROOT}/lineofbusiness/${id}`;
        const api = new AxiosService(getLineOfBussinessDetail);
        return api.doAjax();
    }

    getNotificationTemplate(id) {
        getNotificationTemplate.url = `${GlobalConfig.API_ROOT}/notificationTemplate/${id}`;
        const api = new AxiosService(getNotificationTemplate);
        return api.doAjax();
    }

    actionTypeList(param) {
        actionTypeList.params = param;
        const api = new AxiosService(actionTypeList);
        return api.doAjax();
    }

    actionPriorityList(param) {
        actionPriorityList.params = param;
        const api = new AxiosService(actionPriorityList);
        return api.doAjax();
    }

    ownersList(param) {
        ownersList.params = param;
        const api = new AxiosService(ownersList);
        return api.doAjax();
    }

    verifyEvdDetails(param){
        verifyEvdDetails.params =param;
        const api = new AxiosService(verifyEvdDetails);
        return api.doAjax();
    }

    validateEvdOtp(param){
        validateEvdOtp.params =param;
        const api = new AxiosService(validateEvdOtp);
        return api.doAjax();
    }

    getAssigneeList() {
        const api = new AxiosService(assigneeList);
        return api.doAjax();
    }

    partnerChangeState(param) {
        partnerChangeStateApi.params = param;
        const api = new AxiosService(partnerChangeStateApi);
        return api.doAjax();
    }

    notificationList(param) {
        notificationListApi.params = param;
        const api = new AxiosService(notificationListApi);
        return api.doAjax();
    }

    changeNotificationStatus(param) {
        notificationStatusChangeApi.params = param;
        const api = new AxiosService(notificationStatusChangeApi);
        return api.doAjax();
    }

    getUploadDocumentTemplateByLOB(lob) {
        getUploadDocumentTemplateApi.url = `${GlobalConfig.API_ROOT}/documentTemplate/lob/${lob}`;
        const api = new AxiosService(getUploadDocumentTemplateApi);
        return api.doAjax();
    }

    getAllStoreTemplates() {
        const api = new AxiosService(getStoreTemplates);
        return api.doAjax();
    }

    activeSubscriberCalculation(param) {
        activeSubscriber.params = param;
        const api = new AxiosService(activeSubscriber);
        return api.doAjax();
    }
    userLogout() {
        const api = new AxiosService(logoutApi);
        return api.doAjax();
    }
    downloadFile(params) {
        downloadFilesApi.params = params;
        const api = new AxiosService(downloadFilesApi);
        return api.doAjax();
    }
    createPartnerDropdownList() {
        const api = new AxiosService(createPartnerDropdownListApi);
        return api.doAjax();
    }
    getPartnerFormInfo(applicationId) {
        getPartnerFormInfoApi.url = `${GlobalConfig.API_ROOT}/partner/getCodeCreationForm/${applicationId}`
        const api = new AxiosService(getPartnerFormInfoApi);
        return api.doAjax();
    }
    createPartnerForm(param) {
        createPartnerFormApi.params = param;
        const api = new AxiosService(createPartnerFormApi);
        return api.doAjax();
    }
    draftPartnerForm(param) {
        DraftPartnerFormApi.params = param;
        const api = new AxiosService(DraftPartnerFormApi);
        return api.doAjax();
    }

}

export const commonService = new CommonService();
