import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./landing-dashboard.scss";
import GlobalConfig from "../../globalConfig/globalConfig";
import { commonService } from "../../storeManager/common/services";
import { PATHS, DASHBOARD_CONFIG } from "../../constants";
import { MODALS } from "../../storeManager/modal/constants";
import { openModal as openModalHandler } from "../../storeManager/modal/actions";
import ActionButtons from './ActionButtons';

class LandingDashboard extends PureComponent {
    state = {
        firmName: "",
        mobileNumber: "",
        emailId: "",
        lineOfBusiness: "",
        isSalesVisible: true,
        salesLOBStatus: "",
        isStoreVisible: true,
        storeLOBStatus: ""
    }

    componentDidMount() {
        if(this.props.history.action == 'POP'){
            this.props.history.push('/application-form')
        }
        const firmName = localStorage.getItem("name");
        const mobileNumber = localStorage.getItem("mobileNumber");
        const emailId = localStorage.getItem("emailId");
        this.setState({
            firmName: firmName,
            mobileNumber: mobileNumber,
            emailId: emailId
        });

        this.getLineOfBussiness();
    }

    lobDetails = () => {
        const partnerJsonData = localStorage.getItem("partners");
        const partnerData = JSON.parse(partnerJsonData);

        return partnerData;
    }

    getLineOfBussiness = async () => {
        try {
            const response = await commonService.fetchBusinessControllerData();
            const responseData = response.data;
            this.setState({
                lineOfBusiness: responseData.data
            });
            const partnerData = this.lobDetails();
            partnerData.map(partner => {
                if (partner.lineOfBusiness === DASHBOARD_CONFIG.SALES) {
                    this.setState({
                        salesLOBStatus: partner.status
                    });
                    if (partner.status === DASHBOARD_CONFIG.IN_PROGRESS || partner.status === DASHBOARD_CONFIG.COURIER_TRACKING || partner.status === DASHBOARD_CONFIG.REJECTED) {
                        this.setState({
                            isSalesVisible: true,
                        });
                    } else if (partner.status === DASHBOARD_CONFIG.ON_HOLD || partner.status === DASHBOARD_CONFIG.APPROVED) {
                        this.setState({
                            isSalesVisible: false
                        });
                    }
                }
                if (partner.lineOfBusiness === DASHBOARD_CONFIG.STORE) {
                    this.setState({
                        storeLOBStatus: partner.status
                    });
                    if (partner.status === DASHBOARD_CONFIG.IN_PROGRESS || partner.status === DASHBOARD_CONFIG.COURIER_TRACKING || partner.status === DASHBOARD_CONFIG.REJECTED) {
                        this.setState({
                            isStoreVisible: true,
                        });
                    } else if (partner.status === DASHBOARD_CONFIG.ON_HOLD || partner.status === DASHBOARD_CONFIG.APPROVED) {
                        this.setState({
                            isStoreVisible: false
                        });
                    }
                }
            });
        } catch {
            this.setState({ error: GlobalConfig.serverError });
        }
    };

    landingToIntentForm = (lobType, lobStatus) => {
        const { openModal } = this.props;
        const partnerData = this.lobDetails();

        if (lobStatus === DASHBOARD_CONFIG.REJECTED) {
            openModal(MODALS.CONFIRMATION_POPUP, {
                message: DASHBOARD_CONFIG.MODAL_MESSAGE
            });
            return;
        }

        const dataFromDashboard = {
            lob: lobType
        }
        localStorage.setItem("dataFromDashboard", JSON.stringify(dataFromDashboard));

        const { history } = this.props;
        history.push(PATHS.APPLICATION_FORM);
    }

    render() {
        const { firmName, mobileNumber, emailId, isSalesVisible, isStoreVisible, lineOfBusiness, salesLOBStatus, storeLOBStatus } = this.state;
        const [sales, store] = lineOfBusiness;
        let dataForActionButtons = null;

        if (lineOfBusiness.length) {
            dataForActionButtons = (
                <div>
                    <ActionButtons
                        clickHandler={this.landingToIntentForm}
                        isVisible={isSalesVisible}
                        lobStatus={salesLOBStatus}
                        lobType={sales.name}
                    />
                    <ActionButtons
                        clickHandler={this.landingToIntentForm}
                        isVisible={isStoreVisible}
                        lobStatus={storeLOBStatus}
                        lobType={store.name}
                    />
                </div>
            )
        }

        return (
            <section className="landing-wrapper row">
                <aside className="left-section col-sm-6 pl45">
                    <picture className="shop-icon d-block"></picture>
                    <p className="welcome mt40 mb0">Welcome</p>
                    <h2 className="firm-name heading previous-partner-logo">{firmName}</h2>
                    <p className="mt15 mb0">Phone</p>
                    <strong className="d-block">+91 {mobileNumber}</strong>
                    <p className="mt15 mb0">Email</p>
                    <strong className="d-block">{emailId}</strong>
                </aside>

                <aside className="right-section col-sm-6">
                    {dataForActionButtons}
                </aside>
            </section>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
});

export default connect(null, mapDispatchToProps)(withRouter(LandingDashboard));
