import React, {Fragment} from "react";

import "./left-panel.scss";

const LeftPanel = (props) => {
  const {
        tasksByRole,
        selectedRoleObj,
        filterRoleOnChange,
        filterStatusOnChange,
        filterListByStatus,
        selectedStatusObj,
      } = props;

      return (
        <Fragment>
          <ul className="task-by-role-list-wrapper">
              {tasksByRole &&
                tasksByRole.length &&
                tasksByRole.map((item, index) => (
                  <li
                    key={index}
                    className={`task-by-role-item ${
                      item.id === selectedRoleObj.id ? "activeRole" : ""
                    }`}
                    onClick={() => filterRoleOnChange(item)}
                  >
                    <span className="task-by-role">{item.label}</span>
                    <span className="task-count">({item.count})</span>
                  </li>
                ))}
            </ul>
            {
              filterListByStatus && filterListByStatus.length ? (
                <ul className="task-by-role-list-wrapper status-list-wrapper">
                  { filterListByStatus.map((item, index) => (
                      <li
                        key={index}
                        className={`task-by-role-item ${
                          item.status === selectedStatusObj.status ? "activeRole" : ""
                        } 
                        `}
                        onClick={() => filterStatusOnChange(item)}
                      >
                        <span className="task-by-role">
                          {item.status.replace(/_+/g, " ").toLowerCase()}
                        </span>
                        <span className="task-count">({item.count})</span>
                      </li>
                    ))}
                </ul>
              ) : null
            }

        </Fragment>
      );
};

export default LeftPanel;
