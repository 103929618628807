import React, { useState } from 'react';
import Input from "../../../../Common/Input";

const PageFrom = (props) => {
    const { formElement, inputChangedHandler } = props;
    return (
        <div className='form-page-wrapper'>
            <div className='row'>
            {
                    formElement && formElement.map((element) => {
                        const [key, config] = element;
                        return(
                            <div key={key} className={config.widthClass}>
                                <Input
                                    key={key}
                                    labelConfig={config.labelConfig}
                                    elementType={config.elementType}
                                    elementConfig={config.elementConfig}
                                    value={config.value || ''}
                                    valid={config.valid}
                                    errorMessage={config.errorMessage}
                                    touched={config.touched}
                                    wrapperClasses={config.wrapperClasses}
                                    inputGroupClasses={config.inputGroupClasses}
                                    changed={event => inputChangedHandler(event, key)}
                                />
                            </div>
                        )
                    })
            }
            </div>
        </div>
    )
}

export default PageFrom
