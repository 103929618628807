import React, { Component } from 'react';
import { TEXT, BUSINESS_WORKFLOW_HEADING } from '../constant';
import InputBox from '../InputBox';
import {MODALS} from "../../../storeManager/modal/constants";
import Table from '../Table';
import SelectBox from '../SelectBox';
import { BussinessControllerData, fetchAllWorkflow,fetchAllBusinessWorkflow } from '../../../storeManager/common/repository';
import { AxiosService } from '../../../services/apiService';
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {scrollToTop} from '../../.././helper';
import Loader from '../../../components/Loader';
import CheckBox from '../CheckBox';
import FormValidator from '../../../services/FormValidator';


class BusinessWorkflow extends Component {

    defaultForm = () => ({
        name: '',
        LineOfBusiness: '',
        selectedWorkflow: [],
        id:null
    });

    state = {
        getLineOfBusinessData: [],
        workflowList: [],
        isEdit: false,
        businessWorkflowData: [],
        formData: this.defaultForm(),
        isLoading: true,
        errorObj: {
            nameError: '',
            LineOfBusinessError: '',
            selectedWorkflowError: ''
        },
    }

    componentDidMount(){
        this.loadHandler()
    };

    loadHandler = async () => {
        const getLineOfBusinessApi = new AxiosService(BussinessControllerData);
        const WorkflowApi = new AxiosService(fetchAllWorkflow);
        const businessWorkflowApi = new AxiosService(fetchAllBusinessWorkflow);
        Promise.all([
            getLineOfBusinessApi.doAjax(),
            WorkflowApi.doAjax(),
            businessWorkflowApi.doAjax()
        ])
        .then(res => {
            const [
            {data : {data : getLineOfBusinessData, status: status1} },
            {data : {data : workflowList, status: status2} },
            {data : {data : businessWorkflowData, status: status3} }
            ] = res;
        if(status1 && status2 && status3) {
            this.setState({
            getLineOfBusinessData: getLineOfBusinessData.map(item => {return {'id':item.id, 'value':item.name}}),
            workflowList,
            businessWorkflowData,
            isLoading: false
            })
        }
        else{
            this.setState({isLoading: false})
        }
        })
        .catch( err => {
            this.setState({isLoading: false})
            console.log('Error in api call', err);
        })
    };

    handleChange = (name,value) => {
        const updatedFormData = { ...this.state.formData }
        updatedFormData[name] = value;
        this.setState({
          formData: updatedFormData,
        })
      }

    handleCheckbox = (name, value, checked, id, type) => {
        const updatedFormData = { ...this.state.formData }
        if(checked){
            updatedFormData['selectedWorkflow'].push({id})
        } else {
            updatedFormData['selectedWorkflow'] = updatedFormData['selectedWorkflow'].filter(e => e.id !== id)
        }
        this.setState({
          formData: updatedFormData
        })
      };


    handleSubmit = async (event) => {
        event.preventDefault();
        const { formData: {name, LineOfBusiness, selectedWorkflow, id} } = this.state;
        const { ...payload } = this.state;
        const reqPayload = {
            id: null || id,
            name: name,
            lob: {id: +(LineOfBusiness)},
            workflows: selectedWorkflow
        }
        if(this.validateForm(payload)){
        this.setState({ isLoading: true });
            try {
            const response = await commonService.createBusinessWorkflow(reqPayload);
            const responseData = response.data;
            const { openModal } = this.props;
            if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE){
                this.setState({
                    isLoading: false
                });
                openModal(MODALS.CONFIRMATION_POPUP, {
                    message: responseData.message
                });
                this.resetForm();
            } else {
                this.setState({
                    error: responseData.message,
                    isLoading: false
                })
            }
        } catch{
            this.setState({
                error: GlobalConfig.serverError,
                isLoading: false
            })
        }
      }
    };

    validateForm = (payload) => {
        const formStatus = FormValidator.validateBusinessWorkFlowForm(payload);
        const { hasErrors, ...errorMessages } = formStatus;
        if(hasErrors){
            this.setState({
                errorObj: errorMessages
            })
            return false;
        }
        else{
            this.setState({
                errorObj: errorMessages
            })
            return true;
        }
    }

    resetForm = () => {
        this.setState({
        formData: this.defaultForm(),
        isEdit: false
        }, () => this.loadHandler())
    };

    handleClick = async (id) => {
        const response = await commonService.getBusinessWorkflowController(id);
        if(response){
            const { name, lob, workflows } = response.data.data;
            let updatedformData = {
                id,
                name,
                LineOfBusiness: lob && lob.id,
                selectedWorkflow: workflows
            }
            this.setState({
                formData: updatedformData,
                isEdit: true
            })
            scrollToTop();
        }
    }

    render() {
        const { formData: { name, LineOfBusiness, selectedWorkflow} , isEdit,
        getLineOfBusinessData, workflowList, businessWorkflowData,
        isLoading, errorObj: { nameError,LineOfBusinessError,selectedWorkflowError }
        } = this.state,

        workflowListData = workflowList && workflowList.map((singleWf, index) => {
            const findInList = selectedWorkflow.find(singleForm => singleForm.id === singleWf.id);
            const isChecked = findInList ? true : false;
            return <CheckBox
              id={ singleWf.id }
              key={ index }
              labelText= { singleWf.name }
              name={ singleWf.name }
              value={ singleWf.name }
              onChange={ (name,value, checked, id) => this.handleCheckbox(name,value, checked, id) }
              isChecked={ isChecked }
            />
        });
        return(
          <div className="Businessworkflow">
            {isLoading && <Loader />}
            <h2 className="heading mb-15">{TEXT.BUSINESS_WORKFLOW}</h2>
            <div className="sub-heading">{TEXT.BUSINESS_WORKFLOW_SUBTITLE}</div>
            <div className="content-form no-gutters">
            <div className="col-lg-8 no-gutters">
            <h3 className="content-title">{`${isEdit ? 'UPDATE' : 'CREATE'} Workflow controller`}</h3>
            <div className="row">
                <div className="form-name col-lg-6">
                    <InputBox
                        labelName='Line Of Bussiness'
                        name = 'name'
                        className = 'form-control'
                        type = "text"
                        value = {name}
                        onChange = {this.handleChange}
                        errorsMessage = { nameError || '' }
                    />
                </div>
                <div className="lob-section col-lg-6">
                    <SelectBox
                        labelName = "Line of business type"
                        options = { getLineOfBusinessData }
                        className = 'form-control'
                        onChange = { this.handleChange }
                        name = 'LineOfBusiness'
                        value = { LineOfBusiness }
                        errorsMessage = { LineOfBusinessError || '' }
                    />
                </div>
            </div>
            <div className="workflow-outer-container">
            <label>List of workflow</label>
            <div className="row workflow-container mb10">
            <div className="workflow-section col-lg-12">
            {workflowListData}
            </div>
            </div>
            <div className="error-message">{selectedWorkflowError}</div>
            </div>
                <button className="button fill big w-210 mt10 mb30 mr20" onClick={this.handleSubmit}>{`${isEdit ? 'UPDATE' : 'CREATE'}`}</button>
            </div>
            </div>
            <div className="table-container mt45">
                <h3>Form Visibility {businessWorkflowData && `(${businessWorkflowData.length})`}</h3>
                <div className="table-data-list">
                <Table showHead headings={BUSINESS_WORKFLOW_HEADING} rows={businessWorkflowData} handleClick={this.handleClick} />
                </div>
            </div>
            {/*<Modal/>*/}
          </div>
        )
    }
  }

  const mapDispatchToProps = dispatch => ({
      openModal: bindActionCreators(openModalHandler, dispatch)
    });

export default (connect(null, mapDispatchToProps)(BusinessWorkflow))




