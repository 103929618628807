import React, {Component, Fragment} from "react";
import TableComponent from "../../Common/TableComponent";
import Slider from "../../Common/ReactSlickSlider";
import "./left-section.scss";
import {Link} from 'react-router-dom';
import _get from 'lodash/get';
import {MESSAGES, PATHS} from './../../../constants';
import PartnerIcon from './../../../assets/images/partner-icon.png';
import DownloadIcon from './../../../assets/images/download.png';
import {isValidArray} from './helper';
import {dateFormatter, numberFormatter} from '../../../helper';
import Tooltip from "../../Common/Tooltip";
import StoreView from "./StoreView";
import {commonService} from "../../../storeManager/common/services";


class DetailLeftSection extends Component {
    state = {
        bankDetails: [],
        partnerDetails: [],
        existingPartnerShipsDetails: [],
        existingPartnerCodes: [],
        isLoading: false,
        storeObject: {}
    };

    constructor(props) {
        super(props);
        const {bankDetails, partnerDetails, existingPartnerShipsDetails} = props || {};
        this.bankDetailsHeaderArray(bankDetails);
        this.partnerDetailsHeaderArray(partnerDetails);
        this.existingPartnerShipsDetailsHeaderArray(existingPartnerShipsDetails);
    }

    static getDerivedStateFromProps = (props) => {
        return {
            bankDetails: props.bankDetails,
            partnerDetails: props.partnerDetails,
            existingPartnerShipsDetails: props.existingPartnerShipsDetails,
            existingPartnerCodes: props.existingPartnerCodes,
            storeObject: props.store
        };
    };

    bankDetailsHeaderArray = (bankDetails) => {
        let bankDetailArray = [];
        if (bankDetails && bankDetails.length) {
            bankDetailArray.push({
                key: "bankDetails"
            });
        }
        return (bankDetailArray);
    };

    partnerDetailsHeaderArray = (partnerDetails) => {
        let partnerDetailArray = [];
        if (partnerDetails && partnerDetails.length) {
            partnerDetailArray.push({
                key: "partnerDetails"
            });
        }
        return (partnerDetailArray);
    };

    existingPartnerShipsDetailsHeaderArray = (existingPartnerShipsDetails) => {
        let existingPartnerShipsDetailsArray = [];
        if (existingPartnerShipsDetails && existingPartnerShipsDetails.length) {
            existingPartnerShipsDetailsArray.push({
                key: "existingPartnerShipsDetails"
            });
        }
        return (existingPartnerShipsDetailsArray);
    };

    downloadDocument = async (id) => {
        let documentUrls = [];
        const {uploadedDocuments} = this.props;
        const findDocument = uploadedDocuments.find(item => item.id === id);
        if (findDocument) {
            documentUrls = findDocument.documents;
        }
        documentUrls && documentUrls.forEach((item) => {
            commonService.downloadFile({fileUrl: item.url})
                .then(res => {
                    const binaryImg = atob(res.data);
                    const length = binaryImg.length;
                    const arrayBuffer = new ArrayBuffer(length);
                    const uintArray = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < length; i++) {
                        uintArray[i] = binaryImg.charCodeAt(i);
                    }
                    const fileBlob = new Blob([uintArray], { type: 'application/pdf' });
                    const link=document.createElement('a');
                    link.href=window.URL.createObjectURL(fileBlob);
                    link.download=item.name;
                     link.click();
                    })
                });
    };

    showWareHouseDetails = (warehouseDetails, updatedWarehouseAddress) => {
        return (warehouseDetails &&
        (warehouseDetails.totalArea ||
          (updatedWarehouseAddress && !!updatedWarehouseAddress.trim()) ||
          warehouseDetails.managerName ||
          warehouseDetails.managerMobileNo))

    }

    render() {
        const {
            requestId,
            firmName,
            firmAddress,
            email,
            mobileNumber,
            activeSliderTab,
            tabChanged,
            firmDetailList,
            businessDetailList,
            businessAssociations,
            totalBanks,
            partnerCounts,
            uploadedDocuments,
            warehouseDetails,
            sliderSettings,
            pendingDays,
            createdDate,
            ticketAssignedTo,
            ticketAssignedToRole,
            responseObject,
            store,
            activeTab,
            tabToggled
        } = this.props;

        const dateFormat = 'dd/MMM/yyyy';

        const { bankDetails, partnerDetails, existingPartnerShipsDetails, storeObject } = this.state;
        const { existingPartnerCodes, existingPartnerShips } = responseObject;
        // console.log(existingPartnerCodes);
        const bankDetailsHeaderArray = this.bankDetailsHeaderArray(
            bankDetails
        );

        const evdEmail = _get(responseObject, 'firm.evdEmailId', '');
        const evdMobile = _get(responseObject, 'firm.evdPhoneNumber', '');
        const circleCode = _get(responseObject, 'firm.address.town.circleCode.name', '');

        const activeBankSliderKey =
            bankDetailsHeaderArray &&
            bankDetailsHeaderArray.length &&
            bankDetailsHeaderArray[0].key;

        const activeBankSliderArray = this.state[activeBankSliderKey];

        const bankSliderSettings = {
            arrows: true,
        };

        const partnerDetailsHeaderArray = this.partnerDetailsHeaderArray(
            partnerDetails,
        );

        const activePartnerSliderKey =
            partnerDetailsHeaderArray &&
            partnerDetailsHeaderArray.length &&
            partnerDetailsHeaderArray[0].key;

        const activePartnerSliderArray = this.state[activePartnerSliderKey];


        const partnerSliderSettings = {
            arrows: true,
        };

        const existingPartnerShipsDetailsHeaderArray = this.existingPartnerShipsDetailsHeaderArray(
            existingPartnerShipsDetails,
        );

        const activeExistingPartnershipSliderKey =
            existingPartnerShipsDetailsHeaderArray &&
            existingPartnerShipsDetailsHeaderArray.length &&
            existingPartnerShipsDetailsHeaderArray[0].key;

        const activeExistingPartnerSliderArray = this.state[activeExistingPartnershipSliderKey];

        const existingPartnerSettings = {
            arrows: true,
        };

        let updatedWarehouseAddress =
            warehouseDetails.address && (
                `   ${warehouseDetails.address.line || ''}
                    ${(warehouseDetails.address.town || {}).name || ''}
                    ${(warehouseDetails.address.state || {}).name || ''}
                    ${(warehouseDetails.address.pinCode || {}).code || ''} `
            );

        const showWareHouse = this.showWareHouseDetails(warehouseDetails, updatedWarehouseAddress);
        const {downloadDocument} = this;

        let totalScore;
        if(store && store.store) {
            const totalStoreScore = store.store.totalStoreScore;
            totalScore = (<div className="d-inline-block total-score"><h3 className="heading">Total Score :</h3> <div className="d-inline request-id">{totalStoreScore ? totalStoreScore : "--"}</div></div>);
        }else {
            totalScore = null
        }

        return (
            <Fragment>
                <div className="top-wrap">
                    <div className="top-left">
                        <div className="d-inline-block">
                            <Link className="back-btn mr10" to={PATHS.HOME}>
                                <i className="icon">
                                    <svg width="16" height="7" viewBox="0 0 32 13">
                                        <path fill="#E50079" fillRule="evenodd"
                                            d="M31.799 6.98l-5.83 5.82a.688.688 0 0 1-.972 0V.19a.688.688 0 0 1 .972 0l5.83 5.82a.684.684 0 0 1 0 .97zM18 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 18 9zm-8 0a2 2 0 1 1-.001-3.999A2 2 0 0 1 10 9zM2 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 2 9z"/>
                                    </svg>
                                </i>
                                <span>Back</span>
                            </Link>
                        </div>
                        <div className="d-inline-block">
                            <div className="id-wrap">
                                <div className="request-id-wrap d-block">
                                    <div className="d-inline-block">Request Id: <span className="request-id">{requestId || ''}</span></div>
                                    {totalScore}
                                </div>
                                <div className="created-date">Created on: {createdDate && dateFormatter(createdDate, dateFormat)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="top-right">
                        <span className="assigned-on">
                            Assigned to <span className="role">{ticketAssignedToRole}</span> From
                        <span className="assigned-date ml5">{pendingDays}</span>
                        </span>
                        <span className="name">{ticketAssignedTo}</span>
                    </div>
                </div>
                <div className="firm-basic-info">
                    <h2 className="firm-name heading previous-partner-logo">
                        {firmName}
                    </h2>
                    {
                        firmAddress ? (
                            <div className="firm-address">
                                <Fragment>
                                    {firmAddress.line} {(firmAddress.town || {}).name} {(firmAddress.state || {}).name} {(firmAddress.pinCode || {}).code} - {circleCode}
                                </Fragment>
                            </div>
                        ) : <Fragment/>
                    }
                    <div className="firm-contact">
                        <span className="common-span contact">
                            <a href={`tel:+91${mobileNumber}`} className="number">
                                +91 {mobileNumber || ''}
                            </a>
                        </span>
                        <span className="common-span email">
                            <a href={`mailto:${email}`} target="_top">
                                {email || ''}
                            </a>
                        </span>
                    </div>
                    <div className="evd-details">
                        <h3 className="evd-detail-label">EVD Details:</h3>
                        <span className="common-span contact">
                            <a href={`tel:+91${evdMobile}`} className="number">
                                +91 {evdMobile || ''}
                            </a>
                        </span>
                        <span className="common-span email">
                            <a href={`mailto:${evdEmail}`} target="_top">
                                {evdEmail || ''}
                            </a>
                        </span>
                    </div>
                </div>
                <div className="firm-detail">
                    <h3 className="title">Firm Details</h3>
                    <div className="firm-detail-list">
                        {firmDetailList &&
                        firmDetailList.map((item, index) => {
                            return (
                                <span className={`firm-detail-wrapper `} key={index}>
                                <span className={`default-icon ${item.imgClass}`}/>
                    <div className="firm-detail-right-block">
                      <span className="firm-detail-label ">{item.label}</span>
                        {
                            item.label === 'Shop Area' ? (
                                <div className="firm-detail-value">
                                    {item.value || '0'} {item.measure}
                                </div>
                            ) : (
                                <div className="firm-detail-value">
                                    {item.value || 'NA'} {item.measure}
                                </div>
                            )
                        }
                    </div>
                  </span>
                            );
                        })}
                    </div>
                </div>
                <div className="business-detail">
                    <h3 className="title">Business Details</h3>
                    <div className="business-detail-wrapper">
                        <div className="business-detail-basic-info">
                            {businessDetailList &&
                            businessDetailList.map((item, index) => {
                                return (
                                    <span className="basic-info-wrapper" key={index}>
                      <span className="label d-block">{item.id}</span>
                                        {
                                            item.value && (
                                                <span className="value">
                                                    {(item.mappingKey=== 'turnover' ? numberFormatter(item.value) : item.value) || ''} {item.measure}
                                                </span>
                                            )
                                        }
                    </span>
                                );
                            })}
                        </div>
                        {businessAssociations
                            && !!businessAssociations.length
                            && <div className="business-detail-table">
                            <h4 className="current-business-association">
                                Current Business Association
                            </h4>
                            <div className="table-wrapper-scrollable">
                                <TableComponent tableData={businessAssociations} hideColumns={['id', 'createdDate']}/>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="bank-prev-partner-slider-wrapper">
                            <div className="bank-details-wrapper">
                                <div className="tab-header-wrapper pb10">
                                    <span
                                        className={activeSliderTab === 0 ? 'tab-header activeTab' : 'tab-header'}
                                        onClick={() => tabChanged(0)}
                                    >
                                        Bank Details ({totalBanks})
                                    </span>

                                    <span
                                        className={activeSliderTab === 1 ? 'tab-header activeTab' : 'tab-header'}
                                        onClick={() => tabChanged(1)}
                                    >
                                            Applicant's Partner's Details ({partnerCounts})
                                        </span>
                                </div>
                                <div className="slider-wrapper">
                                    {
                                        activeSliderTab === 0 ? (
                                            <Fragment>
                                                {activeBankSliderArray && activeBankSliderArray.length && (
                                                    <Slider
                                                        settings={{
                                                            ...sliderSettings,
                                                            ...bankSliderSettings
                                                        }}
                                                    >
                                                        {isValidArray(activeBankSliderArray) && activeBankSliderArray.map((item, index) => (
                                                            <div className="slider-container" key={index}>
                                                                <div className="slider-item bank-card">
                                                                    <div className="img-circle">
                                                                        <img src={(item.bank && item.bank.logo) || ''}
                                                                             alt="bank"/>
                                                                    </div>
                                                                    <div className="medium-strong">
                                                                        <div className="common-slide d-inline-block">
                                                                            {(item.bank && item.bank.name) || ''} {item.bank && item.bank.others ? ' - '+item.name : ''}
                                                                        </div>
                                                                        <div className="common-slide mb25">
                                                                            {item.accountNo || ''}
                                                                        </div>
                                                                    </div>
                                                                    {item.ccLimit && <span className="common-slide mb20">
                                                                            Credit Limit ~ {(item.ccLimit) || ''}
                                                                        </span>}
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </Slider>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                {activePartnerSliderArray && activePartnerSliderArray.length && (
                                                    <Slider
                                                        settings={{
                                                            ...sliderSettings,
                                                            ...partnerSliderSettings
                                                        }}
                                                    >
                                                        {activePartnerSliderArray.map((item, index) => (
                                                            <div className="slider-container" key={index}>
                                                                <div className="slider-item partner-card">
                                                                    <div className="img-circle">
                                                                        <img src={PartnerIcon} alt="Partner"/>
                                                                    </div>
                                                                    <div className="medium-strong">
                                                                        <span
                                                                            className="common-slide name text-camel pb25">
                                                                                {item.name || ''}
                                                                            </span>
                                                                        <div className="label">
                                                                            PAN Number
                                                                        </div>
                                                                        <div className="common-slide text-upper">
                                                                            {item.pan || ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </Slider>
                                                )}
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="bank-prev-partner-slider-wrapper">
                            <div className="bank-details-wrapper">
                                <div className="tab-header-wrapper pb10">
                                    <span
                                        className={activeTab === 0 ? 'tab-header activeTab' : 'tab-header'}
                                        onClick={() => tabToggled(0)}
                                    >EXISTING RELATIONSHIP ({existingPartnerShips ? existingPartnerShips.length : 0})
                                    </span>
                                    <span
                                        className={activeTab === 1 ? 'tab-header activeTab' : 'tab-header'}
                                        onClick={() => tabToggled(1)}
                                    >Partner Codes ({existingPartnerCodes ? existingPartnerCodes.length : 0})
                                    </span>
                                </div>
                                <div className="slider-wrapper">
                                    {
                                        activeTab === 0 ? (
                                            <Fragment>
                                                {activeExistingPartnerSliderArray && activeExistingPartnerSliderArray.length && (
                                                    <Slider
                                                        settings={{
                                                            ...sliderSettings,
                                                            ...existingPartnerSettings
                                                        }}
                                                    >
                                                        {activeExistingPartnerSliderArray.map((item, index) => (
                                                            <div className="slider-container" key={index}>
                                                                <div className="slider-item relationship-card">
                                                                    <div className="medium-strong">
                                                                        <span className="common-slide name pb25">
                                                                            {item.channel.name || ''}
                                                                        </span>
                                                                        <div className="label">
                                                                            Applicant code
                                                                        </div>
                                                                        <div className="common-slide">
                                                                            {item.partnerCode.code || ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </Slider>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                {existingPartnerCodes && existingPartnerCodes.length>0 && (
                                                    <Slider
                                                        settings={{
                                                            ...sliderSettings,
                                                            ...partnerSliderSettings
                                                        }}
                                                    >
                                                        {existingPartnerCodes.map((item, index) => (
                                                            <div className="slider-container" key={index}>
                                                                <div className="slider-item relationship-card">
                                                                    <div className="img-circle">
                                                                        <img src={PartnerIcon} alt="Partner"/>
                                                                    </div>
                                                                    <div className="medium-strong">
                                                                        <span
                                                                            className="common-slide name text-camel pb25">
                                                                                {item.channelName || ''}
                                                                            </span>
                                                                        <div className="label">
                                                                            Partner Code
                                                                        </div>
                                                                        <div className="common-slide text-upper">
                                                                            {item.partnerCode || ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </Slider>
                                                )}
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="document-warehouse-wrapper">
                    <div className="documents-wrapper">
                        {
                            uploadedDocuments &&
                            uploadedDocuments.length ? (
                                <Fragment>
                                    <h4 className="title">Documents</h4>
                                    <div className="documents-list-wrapper">
                                        {
                                            uploadedDocuments.map((item, index) => (
                                                <div className="document-item-wrapper" key={index}>
                                                    <span className="doc-type-image"/>
                                                    <span
                                                        className="doc-name">{item.documentTemplate.label || ''}</span>
                                                    {
                                                        item.documents && item.documents.length !== 0 ? (
                                                            <span
                                                                className="download"
                                                                onClick={() => downloadDocument(item.id)}>
                                                            <img src={DownloadIcon} alt="download" className="icon"/>
                                                            </span>
                                                        ): <Tooltip message={MESSAGES.NO_FILE_AVAILABLE} />
                                                    }

                                                </div>
                                            ))}
                                    </div>
                                </Fragment>
                            ) : ''
                        }
                    </div>
                    {
                        showWareHouse ?
                            (
                            <div className="warehouse-wrapper">
                                <h4 className="title">Warehouse Details</h4>
                                <div className="warehouse-details-wrapper">
                                {
                                    warehouseDetails.totalArea && (
                                        <>
                                            <div className="label">Total Area</div>
                                            <div className="value totalArea">
                                                <Fragment>{warehouseDetails.totalArea || ''} sq ft</Fragment>
                                            </div>
                                        </>
                                        )
                                }
                                {
                                    updatedWarehouseAddress && !!updatedWarehouseAddress.trim() && (
                                        <>
                                            <div className="label">Warehouse Address</div>
                                            <div className="value">
                                                <Fragment>
                                                    {updatedWarehouseAddress}
                                                </Fragment>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    warehouseDetails.managerName && (
                                        <>
                                            <div className="label">Warehouse Manager Name</div>
                                            <div className="value">{warehouseDetails.managerName || ''}</div>
                                        </>
                                    )
                                }
                                {
                                    warehouseDetails.managerMobileNo && (
                                        <>
                                            <div className="label">Manager's Contact Number</div>
                                            <div className="value">{warehouseDetails.managerMobileNo || ''}</div>
                                        </>
                                    )
                                }
                                </div>
                            </div>
                    ):<Fragment/>}
                </div>
                {storeObject.store ? <StoreView store={storeObject} /> : null}
            </Fragment>
        );
    }
}
export default DetailLeftSection;
