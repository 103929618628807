import React from 'react';
import PropTypes from 'prop-types'
import './tooltip.scss';

const Tooltip = (props) => (
    <div className="tooltip-element">
        {props.message}
    </div>
);

Tooltip.propTypes = {
  message: PropTypes.string
};

export default Tooltip;