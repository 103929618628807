import React from 'react';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import {PATHS, ROLES_CATEGORY} from './constants/index';
import {isAuthenticated} from './helper';
import CommonLayout from '../src/components/Common/CommonLayout';
import Auth from "./components/Auth";
import Dashboard from "./components/Dashboard";
import LandingDashboard from "./components/LandingDashboard";
// import NoPageFound from "./components/NoPageFound";
//Route handling
import {Provider} from 'react-redux';
import store from './store/store';
import {createBrowserHistory} from 'history';
import httpService from './axios-interceptor';
import SomethingWentWrong from "./components/SomethingWentWrong";
// import TransactionStatus from "./components/TransactionStatus";
// import MyProfile from "./components/MyProfile";
import Header from "./components/Common/Header";
// import Footer from "./components/Common/Footer";
import ListingDetail from "./components/ListingDetail";
import VerifyOTPComponent from "./components/Auth/Registeration/VerifyOTPComponent";
import ApplicationForm from "./components/ApplicationForm";
import Admin from "./components/Admin";
import AboutUs from "./components/AboutUs";
import Modal from "./components/Common/Modal";

const history = createBrowserHistory();
httpService.setupInterceptors(store, history);

const Routes = () => {
    const PrivateRoute = ({component, ...rest}) => (
        <div className="bg-custom">
            <div className="user-view-container">
                <div className="wrapper">
                    <Router history={history}>
                        {
                            (history.location.pathname === PATHS.SOMETHING_WENT_WRONG) ||
                            (history.location.pathname === PATHS.SERVER_ERROR) ? null : <Header/>
                        }
                        <Route {...rest} render={props => {
                            const isRoleCategory = localStorage.getItem("roleCategory");
                            const roleCategory = isRoleCategory && JSON.parse(isRoleCategory);
                            const isDashboard = localStorage.getItem("showDashboard");
                            const showDashboard = isDashboard && JSON.parse(isDashboard);
                            if(isAuthenticated() && (roleCategory === ROLES_CATEGORY.PARTNER)){
                                if(showDashboard) {
                                    return (
                                        <Switch>
                                            <CommonLayout
                                                path={PATHS.LANDING_DASHBOARD}
                                                component={LandingDashboard}
                                                exact
                                            />
                                            <CommonLayout
                                                path={PATHS.APPLICATION_FORM}
                                                component={ApplicationForm}
                                                exact
                                            />
                                            <CommonLayout
                                               path={PATHS.SERVER_ERROR}
                                               component={SomethingWentWrong}
                                               exact
                                               serverError
                                           />
                                           <CommonLayout
                                               path={PATHS.SOMETHING_WENT_WRONG}
                                               component={SomethingWentWrong}
                                               exact
                                           />
                                           <Route path="/" render={() => <Redirect to={PATHS.LANDING_DASHBOARD} />} />
                                        </Switch>
                                   )
                                }else {
                                    return (
                                        <Switch>
                                            <CommonLayout
                                                path={PATHS.APPLICATION_FORM}
                                                component={ApplicationForm}
                                                exact
                                            />
                                            <CommonLayout
                                               path={PATHS.SERVER_ERROR}
                                               component={SomethingWentWrong}
                                               exact
                                               serverError
                                           />
                                           <CommonLayout
                                               path={PATHS.SOMETHING_WENT_WRONG}
                                               component={SomethingWentWrong}
                                               exact
                                           />
                                           <Route path="/" render={() => <Redirect to={PATHS.APPLICATION_FORM} />} />
                                        </Switch>
                                   )
                                }
                                 
                            } else if(isAuthenticated() && (roleCategory === ROLES_CATEGORY.WORKFLOW_MANAGER)){
                               return (
                                   <Switch>
                                       <CommonLayout
                                           path={`${PATHS.ADMIN}/:tabName`}
                                           component={Admin}
                                           exact
                                       />
                                       <CommonLayout
                                           path={PATHS.ADMIN}
                                           component={Admin}
                                           exact
                                       />
                                       <CommonLayout
                                            path={PATHS.SERVER_ERROR}
                                            component={SomethingWentWrong}
                                            exact
                                            serverError
                                        />
                                        <CommonLayout
                                            path={PATHS.SOMETHING_WENT_WRONG}
                                            component={SomethingWentWrong}
                                            exact
                                        />
                                      <Route path="/" render={() => <Redirect to={PATHS.ADMIN} />} />
                                   </Switch>
                              )
                          }
                            else if(isAuthenticated()){
                                return (<Switch>
                                    <CommonLayout
                                        path={PATHS.HOME}
                                        component={Dashboard}
                                        exact
                                    />
                                    <CommonLayout
                                        path={PATHS.DASHBOARD}
                                        component={Dashboard}
                                        exact
                                    />
                                    <CommonLayout
                                        path={PATHS.DETAIL}
                                        component={ListingDetail}
                                        exact
                                    />
                                    <CommonLayout
                                        path={`${PATHS.APPLICATION_FORM}/:id`}
                                        component={ApplicationForm}
                                        exact
                                    />
                                    <CommonLayout
                                        path={PATHS.SERVER_ERROR}
                                        component={SomethingWentWrong}
                                        exact
                                        serverError
                                    />
                                    <CommonLayout
                                        path={PATHS.SOMETHING_WENT_WRONG}
                                        component={SomethingWentWrong}
                                        exact
                                    />
                                    <Route path="/" render={() => <Redirect to={PATHS.HOME} />} />
                                </Switch>
                            )
                            }
                            else{
                                return (
                                    <Redirect to={{
                                        pathname: PATHS.LOGIN,
                                        state: {from: props.location},
                                    }}
                                    />
                                )
                            }
                            }
                        }/>
                    </Router>
                </div>
                <div className="inner-bg-layer" />
            </div>
        </div>
    );
    return (
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <CommonLayout
                        path={PATHS.LOGIN}
                        component={Auth}
                        exact
                    />
                    <CommonLayout
                        path={PATHS.VERIFY_USER}
                        component={VerifyOTPComponent}
                        exact
                    />
                    <CommonLayout
                        path={PATHS.ABOUT_US}
                        component={AboutUs}
                        exact
                    />
                    <PrivateRoute/>
                </Switch>
                <Modal />
            </Router>
        </Provider>
    )
};

export default Routes;
