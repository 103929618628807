import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { TEXT, EMAIL_TEMPLATE_TABLE_HEADING } from '../constant';
import InputBox from '../InputBox';
import Table from '../Table';
import CheckBox from '../CheckBox';
import Loader from '../../Loader';
import {MODALS} from "../../../storeManager/modal/constants";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import GlobalConfig from "../../../globalConfig/globalConfig";
import { ownersList, fetchEmailTemplateList } from '../../../storeManager/common/repository';
import { AxiosService } from '../../../services/apiService';
import {commonService} from "../../../storeManager/common/services";
import {scrollToTop} from "../../../helper";
import FormValidator from '../../../services/FormValidator';

class EmailTemplate extends Component {

  defaultForm = () =>({
    name: '',
    templateName: '',
    // emailTo: [],
    // emailCC: [],
    sendTosOR : [],
    sendTosAND: [],
    sendCcsOR : [],
    sendCcsAND: []
  });

  state = {
    isLoading: true,
    isEdit: false,
    error: '',
    formData: this.defaultForm() ,
    ownersList: [],
    errorObj : {
      nameError: "",
      templateNameError: "",
      emailToError:"",
      emailCCError: ""
    },
    ownersList: [],
    emailTemplateList:[]
  };

  componentDidMount(){
    this.loadHandler();
  }

  loadHandler = async () => {
    const ownerApi = new AxiosService(ownersList);
    const stateApi = new AxiosService(fetchEmailTemplateList);
    Promise.all([
      ownerApi.doAjax(),
      stateApi.doAjax(),
    ])
    .then(res => {
      const [
        {data : {data : ownersList} },
        {data : {data : emailTemplateList} },
      ] = res;

      if(emailTemplateList.length){
        emailTemplateList.map(obj => {
          obj.sendTosOR = obj.sendTosOR.concat(obj.sendTosAND);
          obj.sendCcsOR = obj.sendCcsOR.concat(obj.sendCcsAND);
          return obj;
        })
      }

      this.setState({
        ownersList,
        emailTemplateList,
        isLoading: false,
      })
    })
    .catch( err => {
      this.setState({isLoading: false});
      console.log('Error in api call', err);
    })
  };

  handleChange = (name, value) => {
    const updatedFormData = { ...this.state.formData }
    updatedFormData[name] = value;
    if(name == 'formType' && value == 'Select'){
      updatedFormData[name] = null;
      this.setState({
        formData: updatedFormData
      })
    }else{
    this.setState({
      formData: updatedFormData,
    })
  }
  };

  handleCheckbox = (name,value, checked, id, type) => {
    const { formData } = this.state;
    const updatedFormData = { ...formData };
    if(checked){
      updatedFormData[type].push({id})
    } else {
      updatedFormData[type] = updatedFormData[type].filter(e => e.id !== id)
    }
    this.setState({
      formData: updatedFormData,
    })
  };

  handleRadioButton = (name, value, id) => {
    this.handleChange(name, {id});
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { formData } = this.state;
    const { ...validatePayload } = formData;
    if(this.validateForm(validatePayload)){
    this.setState({ isLoading: true });
      try {
        let {
              formData: {
                name,
                templateName,
                sendTosOR,
                sendTosAND,
                sendCcsOR ,
                sendCcsAND
              },
              id
            } = this.state;
            const payload = {
              id: id || null,
              friendlyTemplateName: name,
              emailTemplateName: templateName,
              sendTosOR,
              sendTosAND,
              sendCcsOR ,
              sendCcsAND
            }
        const response = await  commonService.createEmailTemplate(payload);
        const responseData = response.data;
        const { openModal } = this.props;
        if(responseData){
          if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE){
            this.setState({
              isLoading: false
            });
            openModal(MODALS.CONFIRMATION_POPUP, {
              message: responseData.message
            });
            this.resetForm();
          } else {
            this.setState({
              error: (responseData && responseData.message) || GlobalConfig.serverError,
              isLoading: false,
            })
          }
        }
      } catch (e) {
        console.log('error occured on admin state =', e);
        this.setState({
          error: GlobalConfig.serverError,
          isLoading: false,
        })
      }
    }
  }

  resetForm = () => {
    this.setState({
      formData: this.defaultForm(),
      error: '',
      id: '',
      isEdit: false,
      errorObj : {
        nameError: "",
        templateNameError: "",
        emailToError:"",
        emailCCError: ""
      }
    }, () => this.loadHandler());
  };

  handleClick = async (id) => {
    const response = await commonService.getEmailDetails(id);
    if(response){
      const updateFormdata = { ...this.state.formData };
      let {friendlyTemplateName,emailTemplateName, id, sendTosOR, sendTosAND, sendCcsOR, sendCcsAND /* sendTos, sendCcs*/ } = response.data.data;
      const sendTosList = sendTosOR.map(el => {return {'id': el.id}});
      const sendCcsList = sendCcsOR.map(el => {return {'id': el.id}});
      const sendCcsAndList = sendTosAND.map(el => {return {'id': el.id}});
      const sendTosAndList = sendCcsAND.map(el => {return {'id': el.id}});
      updateFormdata.name = friendlyTemplateName;
      updateFormdata.templateName = emailTemplateName;
      updateFormdata.sendTosOR = sendTosList;
      updateFormdata.sendCcsOR = sendCcsList;
      updateFormdata.sendTosAND = sendCcsAndList;
      updateFormdata.sendCcsAND = sendTosAndList;
      this.setState({
        id,
        formData: updateFormdata,
        isEdit: true,
      }, () => scrollToTop());
    }
  };

  validateForm = (payload) =>{
    /*let { errorObj } = this.state;
    const formStatus = FormValidator.validateAdminState(payload);
    if(formStatus.hasErrors){
      errorObj = formStatus;
      this.setState({
        errorObj
      });
    }
    else{
      return true;
    }*/
    return true;
  }

  render() {
    const {
      formData: { name, templateName, sendTosOR, sendTosAND, sendCcsOR, sendCcsAND },
      isLoading, error, ownersList, emailTemplateList, isEdit, errorObj,
    } = this.state;

   const emailToElement = ownersList && ownersList.map((owner, index) => {
      const findInList = sendTosOR.find(singleOwner => singleOwner.id === owner.id);
      const isChecked = findInList ? true : false;
      return <CheckBox
          id={owner.id}
          key={index}
          labelText={owner.name}
          name={owner.name}
          value={owner.name}
          onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'sendTosOR')}
          isChecked={isChecked}
      />
    });

    const emailCCElement = ownersList && ownersList.map((owner, index) => {
      const findInList = sendCcsOR.find(singleOwner => singleOwner.id === owner.id);
      const isChecked = findInList ? true : false;
      return <CheckBox
          id={owner.id}
          key={index}
          labelText={owner.name}
          name={owner.name}
          value={owner.name}
          onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'sendCcsOR')}
          isChecked={isChecked}
      />
    });

    const emailToAndElement = ownersList && ownersList.map((owner, index) => {
      const findInList = sendTosAND.find(singleOwner => singleOwner.id === owner.id);
      const isChecked = findInList ? true : false;
      return <CheckBox
          id={owner.id}
          key={index}
          labelText={owner.name}
          name={owner.name}
          value={owner.name}
          onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'sendTosAND')}
          isChecked={isChecked}
      />
    });

    const emailCCAndElement = ownersList && ownersList.map((owner, index) => {
      const findInList = sendCcsAND.find(singleOwner => singleOwner.id === owner.id);
      const isChecked = findInList ? true : false;
      return <CheckBox
          id={owner.id}
          key={index}
          labelText={owner.name}
          name={owner.name}
          value={owner.name}
          onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'sendCcsAND')}
          isChecked={isChecked}
      />
    });

    return (
      <div className="admin-state">
        {isLoading && <Loader />}
         <h2 className="heading mb-15">{TEXT.EMAIL_TEMPLATE_HEADING}</h2>
         <div className="sub-heading">{TEXT.EMAIL_TEMPLATE_SUBTITLE}</div>
        {isEdit && <div className="content-form no-gutters">
          <form className="col-lg-8 no-gutters">
            <h3 className="content-title">{`${isEdit ? 'Update' : 'Create'} Email Template`}</h3>
            <div className="row">
              <div className="state-name-section col-lg-6">
                <InputBox
                    labelName='Name'
                    name='name'
                    className='form-control'
                    InputType="text"
                    value={name}
                    onChange={this.handleChange}
                    disabled={true}
                    errorsMessage={errorObj.nameError || ""}
                />
              </div>
              <div className="state-name-section col-lg-6">
                <InputBox
                    labelName='Email template Name'
                    disabled={true}
                    name='templateName'
                    className='form-control'
                    InputType="text"
                    value={templateName}
                    onChange={this.handleChange}
                    errorsMessage={errorObj.templateNameError || ""}
                />
              </div>
            </div>
            <div className="row">
              <div className="owner-list-sectionescownerList col-lg-6 form-group">
                <label>Mail (To) {sendTosOR.length > 0 && `(0${sendTosOR.length} Selected)`}</label>
                <div className="owner-bg">
                  {emailToElement}
                </div>
                <div className="error-message">{errorObj.emailToError}</div>
              </div>
              <div className="owner-list-sectionescownerList col-lg-6 form-group">
                <label>Mail (CC) {sendCcsOR.length > 0 && `(0${sendCcsOR.length} Selected)`}</label>
                <div className="owner-bg">
                  {emailCCElement}
                </div>
                <div className="error-message">{errorObj.emailCCError}</div>
              </div>
            </div>

            <div className="row">
              <div className="owner-list-sectionescownerList col-lg-6 form-group">
                <label>Mail (To) (And) {sendTosAND.length > 0 && `(0${sendTosAND.length} Selected)`}</label>
                <div className="owner-bg">
                  {emailToAndElement}
                </div>
                <div className="error-message">{errorObj.emailToError}</div>
              </div>
              <div className="owner-list-sectionescownerList col-lg-6 form-group">
                <label>Mail (CC) (And) {sendCcsAND.length > 0 && `(0${sendCcsAND.length} Selected)`}</label>
                <div className="owner-bg">
                  {emailCCAndElement}
                </div>
                <div className="error-message">{errorObj.emailCCError}</div>
              </div>
            </div>

            <div className="">
              {error && <label className="error-message">{error}</label>}
              <button className="button fill big w-210 mt10 mb30 mr20" onClick={this.handleSubmit}>
                {`${isEdit ? 'UPDATE' : 'CREATE'}`}
              </button>
            </div>
          </form>
        </div>
        }
         <div className="table-container mt45">
            <h3>Actions {emailTemplateList && `(${emailTemplateList.length})`}</h3>
            <div className="table-data-list">
              <Table showHead headings={EMAIL_TEMPLATE_TABLE_HEADING} rows={emailTemplateList} handleClick={this.handleClick} />
            </div>
          </div>
          {/*<Modal />*/}
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModalHandler, dispatch)
});

export default (connect(null, mapDispatchToProps)(EmailTemplate))
