import React, {Fragment} from 'react';
import Proptypes from 'prop-types';
import {TABLE_HEADER} from '../../../constants';

const TableComponent = (props) => {
  const { tableData, checkBox, checkboxHandler, tableClass, isVisibleCheck, sorting, sortHandler, hideColumns } = props;
  const iconName = tableClass ? tableClass : "";
  let columns = [];
  let tableHeaders = null;
  let tableBody = null;

  if (tableData.length) {
    columns = Object.keys(tableData[ 0 ]);
    if (hideColumns && hideColumns.length) {
      columns = columns.filter(x => !(hideColumns.indexOf(x) > -1));
    }
    if (checkBox) {
      columns.splice(0, 0, '');
    }
    /*if(columns.indexOf('isChecked')) {
      const indexOfChecked = columns.indexOf('isChecked');
      columns.splice(indexOfChecked, 1)
    }*/
    tableHeaders = (
      <thead>
      <tr>
        {
          columns.map(header => {
            if (header !== 'isChecked' && header !== 'isEdit' && header !== 'isDisabled' && header !== 'isVisible') {
              return (
                <th key={header}>
                  {
                    sorting && TABLE_HEADER[ header ] ? (
                      <span className="table-sort-icon" onClick={()=>sortHandler(header)}>{TABLE_HEADER[ header ]}</span>
                    ) : TABLE_HEADER[ header ]
                  }
                </th>
              );
            }
            return null
          })
        }
      </tr>
      </thead>
    );
    tableBody = (
      <tbody>
      {
        tableData.length ? (
          tableData.map((rowData, index) => {
            return isVisibleCheck ? (
              rowData.isVisible ? (
                <tr key={`table-body-${index}`}>
                  {
                    columns.map((column, indx) => {
                      return (
                        <Fragment key={`column-${indx}`}>
                          {
                            ((column !== 'isChecked' && column !== 'isEdit' && column !== 'isDisabled' && column !== 'isVisible') && !rowData[ column ]) ? (
                              <td>
                                <label className="checkbox-element">
                                  <input
                                    type="checkbox"
                                    id={rowData.subscriberId}
                                    value={rowData.subscriberId || ''}
                                    onChange={checkboxHandler}
                                    checked={rowData.isChecked}
                                    disabled={rowData.isDisabled}
                                  />
                                  <span className="checkmark"/>
                                </label>
                              </td>
                            ) : (column !== 'isChecked' && column !== 'isEdit' && column !== 'isDisabled' && column !== 'isVisible') ? <td>{rowData[ column ]}</td> : null
                          }
                        </Fragment>
                      )
                    })
                  }
                </tr>
              ) : null
            ) : (
              <tr key={`table-body-${index}`}>
                {
                  columns.map((column, indx) => {
                    return (
                      <Fragment key={`column-${indx}`}>
                        <td>{rowData[ column ]}</td>
                        {/*{*/}
                        {/*  ((column !== 'isChecked' && column !== 'isEdit' && column !== 'isDisabled' && column !== 'isVisible') && !rowData[ column ]) ? (*/}
                        {/*    <td>*/}
                        {/*      <label className="checkbox-element">*/}
                        {/*        <input*/}
                        {/*          type="checkbox"*/}
                        {/*          id={rowData.subscriberId}*/}
                        {/*          value={rowData.subscriberId || ''}*/}
                        {/*          onChange={checkboxHandler}*/}
                        {/*          checked={rowData.isChecked}*/}
                        {/*          disabled={rowData.isDisabled}*/}
                        {/*        />*/}
                        {/*        <span className="checkmark"/>*/}
                        {/*      </label>*/}
                        {/*    </td>*/}
                        {/*  ) : (column !== 'isChecked' && column !== 'isEdit' && column !== 'isDisabled' && column !== 'isVisible') ? <td>{rowData[ column ]}</td> : null*/}
                        {/*}*/}
                      </Fragment>
                    )
                  })
                }
              </tr>
            )
          })
        ) : (
          <tr>
            <td align="center" colSpan={columns.length - 1}>No Data Found</td>
          </tr>
        )
      }
      </tbody>
    );
    return (
      <table className={`table-wrapper ${iconName}`}>
        {
          tableHeaders
        }
        {
          tableBody
        }
      </table>
    )
  }
  return (
    <div className="no-data-found">No Data Found</div>
  )
};

TableComponent.propTypes = {
  tableData: Proptypes.array.isRequired,
  checkBox: Proptypes.bool,
  checkboxHandler: Proptypes.func,
  tableClass: Proptypes.string,
  isVisibleCheck: Proptypes.bool,
  sorting: Proptypes.bool,
  sortHandler: Proptypes.func
};

TableComponent.defaultProps = {
  tableData: [],
  checkBox: false,
  checkboxHandler: () => {},
  tableClass: null,
  isVisibleCheck: false,
  sorting: false,
  sortHandler: () => {}
};

export default TableComponent;