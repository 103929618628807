import React, { Component } from "react";
import Input from "../../../../Common/Input";
import Search from "../../../../Common/Search";

class TaskListFilterDropDown extends Component {
  state = {
    loginForm: {
      sortBy: {
        wrapperClasses: ["form-element", "task-header-dropdown"],
        inputGroupClasses: [],
        elementType: "select",
        elementConfig: {
          options: [
            { label: "Sort By", value: "" },
            { label: "Recently Created", value: "CREATED_DATE" },
            { label: "Last Modified", value: "MODIFIED_DATE" }
          ]
        },
        value: "sortBy",
        errorMessage: "",
        valid: true,
        touched: false
      }
    }
  };

  inputChangedHandler = (inputValue, inputIdentifier) => {
    const { loginForm } = this.state;
    const updatedForm = { ...loginForm };
    const updatedFormElement = { ...updatedForm[inputIdentifier] };
    updatedFormElement.touched = true;
    updatedFormElement.valid = true;
    updatedFormElement.errorMessage = "";
    updatedForm[inputIdentifier] = updatedFormElement;
    this.setState({ loginForm: updatedForm });
    this.props.headerOnChange(inputIdentifier, inputValue)
  };

  render() {
    const { loginForm } = this.state;
    const { onChangeSearchHandler, headerValues } = this.props;
    let formElementsArray = [];
    const formKeys = Object.keys(loginForm);
    formKeys.forEach(key => {
      formElementsArray.push({
        id: key,
        config: loginForm[key]
      });
    });
    return (
        <>
            {/*<Search onChangeSearchHandler={onChangeSearchHandler}/>*/}
            <form
            >
                {formElementsArray &&
                formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        labelConfig={formElement.config.labelConfig}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={(headerValues && headerValues[formElement.config.value]) || ''}
                        valid={formElement.config.valid}
                        errorMessage={formElement.config.errorMessage}
                        touched={formElement.config.touched}
                        wrapperClasses={formElement.config.wrapperClasses}
                        inputGroupClasses={formElement.config.inputGroupClasses}
                        changed={event => this.inputChangedHandler(event, formElement.id)}
                    />
                ))}
            </form>
        </>
    );
  }
}

export default TaskListFilterDropDown;
