import { ACTION } from "./constants";

const initialState = {
  notificationList:[]
};

const changeNotificationReadStatus = (state, { index }) => ({
    ...state,
    notificationList: [
      ...state.notificationList.slice(0,index),
      Object.assign({}, state.notificationList[index], {isNew: false}),
      ...state.notificationList.slice(index+1)
   ]
  });

const setNotificationsList = (state, { data }) => ({
    ...state,
    notificationList: [...data]
  });

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.RESET_STATE: return { ...initialState };
    case ACTION.CHANGE_NOTIFICATION_READ_STATUS: return changeNotificationReadStatus(state, action);
    case ACTION.SET_NOTIFICATION_LIST: return setNotificationsList(state, action);
    default:
      return state;
  }
}
