import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { TEXT, STATE_TABLE_HEADING } from '../constant';
import InputBox from '../InputBox';
import TextArea from '../TextArea';
import SelectBox from '../SelectBox';
import Table from '../Table';
import RadioButton from '../RadioButton';
import CheckBox from '../CheckBox';
import Loader from '../../../components/Loader';
import {MODALS} from "../../../storeManager/modal/constants";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import GlobalConfig from "../../../globalConfig/globalConfig";
import {
  ownersList,
  fetchAdminStateList,
  fetchFormVisibilityData,
  fetchAllWorkflow,
  fetchAllNotification,
  getForm,
  // notificationListApi,
  fetchEmailTemplateList
} from '../../../storeManager/common/repository';
import { AxiosService } from '../../../services/apiService';
import {commonService} from "../../../storeManager/common/services";
import {scrollToTop} from "../../../helper";
import FormValidator from '../../../services/FormValidator';

class ActionState extends Component {

  defaultForm = () =>({
    name: '',
    description: '',
    sla: '',
    owners: [],
    sendNotificationTo: [],
    escalationOwner: {},
    assignedOwner: {},
    formVisibility: [],
    formType: "",
    workflow: "",
    emailTemplate: "",
    notificationTemplates: []
  });

  state = {
    isLoading: true,
    isEdit: false,
    error: '',
    formData: this.defaultForm() ,
    ownerSelected: 0,
    ownersList: [],
    stateList: [],
    formVisibilityData: [],
    workflowList: [],
    notificationList: [],
    emailTemplateList: [],
    selectedFormVisibilityData: [],
    FormTypeList: [],
    errorObj : {
      nameError: "",
      descriptionError: "",
      // ownersError: "",
      slaError: "",
      escalationOwnerError:"",
      assignedOwnerError:"",
      formVisibilityError:"",
      sendNotificationToError: ""
    },
  };

  componentDidMount(){
    this.loadHandler();
  }

  loadHandler = async () => {
    const ownerApi = new AxiosService(ownersList);
    const stateApi = new AxiosService(fetchAdminStateList);
    const formVisibilityApi = new AxiosService(fetchFormVisibilityData);
    const formTyepeApi = new AxiosService(getForm);
    const WorkflowApi = new AxiosService(fetchAllWorkflow);
    const notificationAPi = new AxiosService(fetchAllNotification);
    const emailtemplateApi = new AxiosService(fetchEmailTemplateList);
    Promise.all([
      ownerApi.doAjax(),
      stateApi.doAjax(),
      formVisibilityApi.doAjax(),
      formTyepeApi.doAjax(),
      WorkflowApi.doAjax(),
      notificationAPi.doAjax(),
      emailtemplateApi.doAjax()
    ])
    .then(res => {
      const [
        {data : {data : ownersList} },
        {data : {data : stateList} },
        {data : {data : formVisibilityData} },
        {data : {data : FormTypeList} },
        {data : {data : workflowList}},
        {data : {data : notificationList}},
        {data : {data : emailTemplateList}}
      ] = res;
      this.setState({
        ownersList,
        stateList,
        formVisibilityData,
        notificationList,
        emailTemplateList: emailTemplateList.map(item => {return {'id':item.id, 'value':item.friendlyTemplateName}}),
        workflowList :  workflowList.map(item => {return {'id':item.id, 'value':item.name}}),
        FormTypeList : FormTypeList.map(item => {return {'id':item, 'value':item}}),
        isLoading: false,
      })
    })
    .catch( err => {
      this.setState({isLoading: false});
      console.log('Error in api call', err);
    })
  };

  handleChange = (name, value) => {
    const updatedFormData = { ...this.state.formData }
    updatedFormData[name] = value;
    if(name === 'formType' && value === 'Select'){
      updatedFormData[name] = null;
      this.setState({
        formData: updatedFormData
      })
    }else{
    this.setState({
      formData: updatedFormData,
    })
  }
  };

  handleCheckbox = (name,value, checked, id, type) => {
    const { formData } = this.state;
    const updatedFormData = { ...formData };
    if(checked){
      updatedFormData[type].push({id})
    } else {
      updatedFormData[type] = updatedFormData[type].filter(e => e.id !== id)
    }
    this.setState({
      formData: updatedFormData,
    })
  };

  handleRadioButton = (name, value, id) => {
    this.handleChange(name, {id});
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { formData } = this.state;
    const { ...validatePayload } = formData;
    if(this.validateForm(validatePayload)){
    this.setState({ isLoading: true });
      try {
        let {
              formData: { emailTemplate, workflow, notificationTemplates, description, formType, escalationOwner, name, owners, sla, assignedOwner, formVisibility, sendNotificationTo},
              id
            } = this.state;
            if(formType === ""){
              formType = null
            };
            if(workflow === ""){
              workflow = null
            }else{
              workflow = {
                id: workflow
              }
            }
            const payload = {
              id: id || null,
              description,
              escalationOwner,
              name,
              owners,
              sla,
              assignedOwner,
              formVisiblities: formVisibility,
              defaultForm: formType,
              workflow: workflow,
              emailTemplate : emailTemplate ? { id: emailTemplate } : null,
              notificationTemplates,
              sendNotificationTo
            }
        const response = await  commonService.createState(payload);
        const responseData = response.data;
        const { openModal } = this.props;
        if(responseData){
          if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE){
            this.setState({
              isLoading: false
            });
            openModal(MODALS.CONFIRMATION_POPUP, {
              message: responseData.message
            });
            this.resetForm();
          } else {
            this.setState({
              error: (responseData && responseData.message) || GlobalConfig.serverError,
              isLoading: false,
            })
          }
        }
      } catch (e) {
        console.log('error occured on admin state =', e);
        this.setState({
          error: GlobalConfig.serverError,
          isLoading: false,
        })
      }
    }
  }

  resetForm = () => {
    this.setState({
      formData: this.defaultForm(),
      error: '',
      id: '',
      isEdit: false,
      errorObj : {
        nameError: "",
        descriptionError: "",
        ownersError: "",
        slaError:"",
        escalationOwnerError:"",
        assignedOwnerError:"",
        formVisibility:""
      }
    }, () => this.loadHandler());
  };

  handleClick = async (id) => {
    const response = await commonService.getStateDetails(id);
    if(response){
      const updateFormdata = { ...this.state.formData };
      let {partnerWorflowId, notificationTemplates, id, name, description, sla, owners, escalationOwner, assignedOwner, formVisiblities, defaultForm, /*workflow, sendNotificationTo,*/ emailTemplate } = response.data.data;
      if(defaultForm === null){
        defaultForm = "";
      };
      if(partnerWorflowId === null){
        partnerWorflowId = "";
      };
    
      const ownersIdList = owners.map(el => {return {'id': el.id}});
      const formVisibilityList = formVisiblities.map(el => {return {'id': el.id}});
      const notificationTemplatesList = notificationTemplates ? notificationTemplates.map(el=>{return {'id': el.id}}) : [];
      updateFormdata.name = name;
      updateFormdata.emailTemplate = (emailTemplate && emailTemplate.id) || '';
      updateFormdata.description = description;
      updateFormdata.formType = defaultForm;
      updateFormdata.sla = sla && sla.toLocaleString().length < 2 ? `0${sla}` : sla;
      updateFormdata.owners = ownersIdList;
      updateFormdata.notificationTemplates = notificationTemplatesList;
      updateFormdata.formVisibility = formVisibilityList;
      updateFormdata.escalationOwner = escalationOwner || {};
      updateFormdata.assignedOwner = assignedOwner || {};
      updateFormdata.workflow = partnerWorflowId;
      this.setState({
        id,
        formData: updateFormdata,
        isEdit: true,
      }, () => scrollToTop());
    }
  };

  validateForm = (payload) =>{
    let { errorObj } = this.state;
    const formStatus = FormValidator.validateAdminState(payload);
    if(formStatus.hasErrors){
      errorObj = formStatus;
      this.setState({
        errorObj
      });
    }
    else{
      return true;
    }
  }

  render() {
    const {
      formData: { emailTemplate, name, notificationTemplates, workflow, sla, description, owners, escalationOwner, assignedOwner,formType, formVisibility, /* sendNotificationTo */ },
      isLoading, error, ownersList, stateList, notificationList, emailTemplateList, formVisibilityData, isEdit, FormTypeList, errorObj, workflowList,
    } = this.state;
    let slaDays = [];
    for(let i = 1; i <= 50; i++){
      slaDays.push(i.toLocaleString().length < 2 ? `0${i}` : i)
    }
    const slaDaysList = slaDays.map(day => {return {'id':day, 'value':day}})

    const escownerList = ownersList && ownersList.map((owner, index) => {
      return <RadioButton
          key={index}
          name="escalationOwner"
          id={owner.id}
          value={owner.name}
          onChange={this.handleRadioButton}
          isChecked={escalationOwner.id === owner.id}
      />
    }),
    assignedOwnerList = ownersList && ownersList.map((owner, index) => {
      return <RadioButton
          key={index}
          name="assignedOwner"
          id={owner.id}
          value={owner.name}
          onChange={this.handleRadioButton}
          isChecked={assignedOwner.id === owner.id}
      />
    }),
    ownersElement = ownersList && ownersList.map((owner, index) => {
      const findInList = owners.find(singleOwner => singleOwner.id === owner.id);
      const isChecked = findInList ? true : false;
      return <CheckBox
        id={owner.id}
        key={index}
        labelText={owner.name}
        name={owner.name}
        value={owner.name}
        onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'owners')}
        isChecked={isChecked}
      />
    }),
    notificationElement = notificationList && notificationList.map((notification, index) => {
      const findInList = notificationTemplates.find(singleNotification => singleNotification.id === notification.id);
      const isChecked = findInList ? true : false;
      return <CheckBox
        id={notification.id}
        key={index}
        labelText={notification.name}
        name={notification.name}
        value={notification.name}
        onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'notificationTemplates')}
        isChecked={isChecked}
      />
    }),
    formVisibilityElement = formVisibilityData && formVisibilityData.map((form, index) => {
      const findInList = formVisibility.find(singleForm => singleForm.id === form.id);
      const isChecked = findInList ? true : false;
      return <CheckBox
          id={form.id}
          key={index}
          labelText={form.formName}
          name={form.formName}
          value={form.formName}
          onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'formVisibility')}
          isChecked={isChecked}
      />
    });
    return (
      <div className="admin-state">
        {isLoading && <Loader />}
         <h2 className="heading mb-15">{TEXT.STATE_TITLE}</h2>
         <div className="sub-heading">{TEXT.ACTIONS_SUBTITLE}</div>
         <div className="content-form no-gutters">
          <form className="col-lg-8 no-gutters">
            <h3 className="content-title">{`${isEdit ? 'Update' : 'Create'} States`}</h3>
            <div className="row">
              <div className="state-name-section col-lg-6">
                <InputBox
                  labelName='State Name'
                  name='name'
                  className='form-control'
                  InputType="text"
                  value={name}
                  onChange={this.handleChange}
                  errorsMessage = { errorObj.nameError || "" }
                />
              </div>
              <div className="state-name-section col-lg-6">
                <SelectBox
                  labelName="SLA Days"
                  options={slaDaysList}
                  name="sla"
                  value={sla}
                  onChange={this.handleChange}
                  errorsMessage = { errorObj.slaError || "" }
                />
              </div>
            </div>
            <div className="row">
              <div className="owner-list-sectionescownerList col-lg-6 form-group">
              <label>List of Owners { owners.length > 0 && `(0${owners.length} Selected)`}</label>
              <div className="owner-bg">
                {ownersElement}
              </div>
              {/* <div className="error-message">{errorObj.ownersError}</div> */}
              </div>
              <div className="escalation-owner-section col-lg-6 form-group">
                  <label>Escalation Owner</label>
                  <div className="owner-bg">
                    {escownerList}
                  </div>
                  <div className="error-message">{errorObj.escalationOwnerError}</div>
              </div>
            </div>
            <div className="row">
              <div className="owner-list-section col-lg-6 form-group">
                <label>Form visibility</label>
                <div className="owner-bg">
                  {formVisibilityElement}
                </div>
                <div className="error-message">{errorObj.formVisibilityError}</div>
              </div>
              <div className="escalation-owner-section col-lg-6 form-group">
                <label>assigned Owner</label>
                <div className="owner-bg">
                  {assignedOwnerList}
                </div>
                <div className="error-message">{errorObj.assignedOwnerError}</div>
              </div>
            </div>
            <div className="row">
              <div className="notification-owner col-lg-6 form-group">
                <label>Notification Template List</label>
                <div className="owner-bg">
                  { notificationElement }
                </div>
                <div className="error-message">{errorObj.sendNotificationToError}</div>
              </div>
              <div className='workflow-list col-lg-6 form-group'>
                <SelectBox
                    labelName='Email template'
                    name='emailTemplate'
                    onChange={this.handleChange}
                    options={emailTemplateList}
                    value={emailTemplate}
                />
              </div>
            </div>
            <div className="state-desc-section">
              <TextArea
                labelName='Description'
                name='description'
                className='form-control'
                pholder="by this you can change assignment of any request."
                onChange={this.handleChange}
                value={description}
                errorsMessage = {errorObj.descriptionError || ""}
              />
            </div>
            <div className='form-type-list'>
              <SelectBox
                labelName='Form Type'
                name='formType'
                onChange={this.handleChange}
                options={FormTypeList}
                value={formType}
              />
            </div>
            <div className='workflow-list'>
              <SelectBox
                labelName='Workflow'
                name='workflow'
                onChange={this.handleChange}
                options={workflowList}
                value={workflow}
              />
            </div>
            <div className="">
            {error && <label className="error-message">{error}</label>}
            <button className="button fill big w-210 mt10 mb30 mr20" onClick={this.handleSubmit}>
              {`${isEdit ? 'UPDATE' : 'CREATE'}`}
            </button>
            </div>
          </form>
         </div>
         <div className="table-container mt45">
            <h3>Actions {stateList && `(${stateList.length})`}</h3>
            <div className="table-data-list">
              <Table showHead headings={STATE_TABLE_HEADING} rows={stateList} handleClick={this.handleClick} />
            </div>
          </div>
          {/*<Modal />*/}
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModalHandler, dispatch)
});

export default (connect(null, mapDispatchToProps)(ActionState))
