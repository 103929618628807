import React, {Component, Fragment} from 'react';
import FormValidator from "../../../services/FormValidator";
import Input from "../../Common/Input";
//import {Link} from 'react-router-dom';
//import {PATHS} from '../../../constants'
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
// import {withRouter} from 'react-router-dom';
import Loader from "../../Loader";

class VerifyEvdDetailsModal extends Component {
    state = {
        verifyEvdDetailsForm: {
            mobileInfo: {
                type: 'heading',
                title: "EVD Mobile Number OTP",
                description: "A 6 digit OTP code has been sent  to your mobile number",
                value: '',
                visible: false
            },
            mobileOTP: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'otp'],
                labelConfig: {
                    label: 'Enter OTP',
                    classes: ['floating-label'],
                },
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Enter OTP',
                    maxLength: 6,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
                resendOtp: true,
                visible: false,
            },
            emailInfo: {
                type: 'heading',
                title: "EVD Email ID OTP",
                description: "A 6 digit OTP code has been sent  to your email address",
                value: '',
                visible: false
            },
            emailOTP: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'otp'],
                labelConfig: {
                    label: 'Enter OTP',
                    classes: ['floating-label'],
                },
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Enter OTP',
                    maxLength: 6,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
                resendOtp: true,
                visible: false
            },
        },
        firmName: '',
        error: '',
        isLoading: false,
    };

    componentDidMount() {
        const {modalParameters: {modalProps}} = this.props;
        let {mobileNumber, email, isEmailToVerify, isMobileNoToVerify} = modalProps;
        const {verifyEvdDetailsForm} = this.state;
        const updateForm = {...verifyEvdDetailsForm};
        if(isEmailToVerify && isMobileNoToVerify){
            updateForm.mobileInfo.visible = true;
            updateForm.mobileOTP.visible = true;
            updateForm.mobileInfo.value = mobileNumber;
            updateForm.emailInfo.visible = true;
            updateForm.emailOTP.visible = true;
            updateForm.emailInfo.value = email;
        }
        else if (isEmailToVerify) {
            updateForm.emailInfo.visible = true;
            updateForm.emailOTP.visible = true;
            updateForm.emailInfo.value = email;
        } else if (isMobileNoToVerify) {
            updateForm.mobileInfo.visible = true;
            updateForm.mobileOTP.visible = true;
            updateForm.mobileInfo.value = mobileNumber;
        }
        this.setState({
            verifyEvdDetailsForm: updateForm,
        })
    }

    inputOTPHandler = (inputValue, inputIdentifier) => {
        const {verifyEvdDetailsForm} = this.state;
        const updatedForm = {...verifyEvdDetailsForm};
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let value = inputValue;
        if (inputIdentifier === 'mobileOTP' || inputIdentifier === 'emailOTP') {
            value = value.replace(/[^0-9]/g, '');
        }
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.errorMessage = '';
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({verifyEvdDetailsForm: updatedForm})
    };

    authenticateOTP = (e) => {
        e.preventDefault();
        const {verifyEvdDetailsForm} = this.state;
        const validateObj = {};
        const formKeys = Object.keys(verifyEvdDetailsForm);
        formKeys.forEach((key) => {
            validateObj[key] = {
                value: verifyEvdDetailsForm[key].value,
                visible: verifyEvdDetailsForm[key].visible
            };
        });
        const formStatus = FormValidator.validateEvdOTPForm(validateObj);
        const {hasErrors, ...errorMessages} = formStatus;
        if (hasErrors) {
            const updatedForm = {...verifyEvdDetailsForm};
            formKeys.forEach((key) => {
                const inputKey = `${key}Message`;
                const errorMessage = errorMessages[inputKey];
                if (errorMessage && errorMessage !== '') {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = errorMessage;
                }
            });
            this.setState({verifyEvdDetailsForm: updatedForm, formErrorMessage: ''});
        } else {
            this.verifyEvdDetailsFromServer(validateObj);
        }
    };

    verifyEvdDetailsFromServer = async (validateObj) => {
        const {modalParameters: {modalProps}} = this.props;
        let {isEmailToVerify,
            isMobileNoToVerify,
            actionAfterVerification,
            actionPayLoad,
            lob} = modalProps;
        this.setState({isLoading: true});
        const payload = {};
        if (isEmailToVerify && isMobileNoToVerify) {
            payload['emailId'] = validateObj.emailInfo.value;
            payload['emailOtp'] = validateObj.emailOTP.value;
            payload['mobileNumber'] = validateObj.mobileInfo.value;
            payload['mobileOtp'] = validateObj.mobileOTP.value;
        } else if (isEmailToVerify) {
            payload['emailId'] = validateObj.emailInfo.value;
            payload['emailOtp'] = validateObj.emailOTP.value;
        } else {
            payload['mobileNumber'] = validateObj.mobileInfo.value;
            payload['mobileOtp'] = validateObj.mobileOTP.value;
        }
            payload['lob'] = lob;
        try {
            const response = await commonService.validateEvdOtp(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false});
                actionAfterVerification(actionPayLoad);
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    reGenerateOtp = async (key) => {
        this.setState({isLoading: true, error: ''});
        const {modalParameters: {modalProps}} = this.props;
        let {mobileNumber, email, name, lob} = modalProps;
        let payload = {
            service: "VERIFY",
            name: name,
            lob: lob,
        };
        if (key === 'emailOTP') {
            payload['email'] = email;
        } else payload['mobileNumber'] = mobileNumber;
            try {
                const response = await commonService.verifyEvdDetails(payload);
                const responseData = response.data;
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    this.setState({isLoading: false})
                } else {
                    this.setState({error: responseData.message, isLoading: false})
                }
            } catch {
                this.setState({error: GlobalConfig.serverError, isLoading: false})
            }
    };

    render() {
        const {verifyEvdDetailsForm, error, isLoading} = this.state;
//        const {modalParameters: {modalProps}} = this.props;
        const {authenticateOTP} = this;
        let formElementsArray = [];
        const formKeys = Object.keys(verifyEvdDetailsForm);
        formKeys.forEach((key) => {
            formElementsArray.push({
                id: key,
                config: verifyEvdDetailsForm[key],
            });
        });
        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <Fragment>
                    <div className="p20">
                        <h2 className="heading pb10">
                            Verify EVD Details
                        </h2>
                        <form onSubmit={authenticateOTP}>
                            {
                                formElementsArray && formElementsArray.map((formElement) => (
                                    <React.Fragment>
                                        {formElement.config.visible ?
                                            <React.Fragment>
                                                {formElement.config.type === 'heading' ?
                                                    <div className="otp-screen">
                                                        <hr className="no-space extra-space"/>
                                                        <div className="main-title pb10">
                                                            {formElement.config.title}
                                                        </div>
                                                        <div className="description pb10">
                                                            {formElement.config.description}
                                                            <span className="value pl5">
                                                   {formElement.config.value}
                                                </span>
                                                        </div>
                                                    </div> :
                                                    <Input
                                                        key={formElement.id}
                                                        labelConfig={formElement.config.labelConfig}
                                                        elementType={formElement.config.elementType}
                                                        elementConfig={formElement.config.elementConfig}
                                                        value={formElement.config.value}
                                                        valid={formElement.config.valid}
                                                        errorMessage={formElement.config.errorMessage}
                                                        touched={formElement.config.touched}
                                                        resendOtp={formElement.config.resendOtp}
                                                        reGenerateOtp={() => this.reGenerateOtp(formElement.id)}
                                                        wrapperClasses={formElement.config.wrapperClasses}
                                                        inputGroupClasses={formElement.config.inputGroupClasses}
                                                        changed={event => this.inputOTPHandler(event, formElement.id)}
                                                    />}
                                            </React.Fragment> : null
                                        }
                                    </React.Fragment>

                                ))
                            }

                            {
                                error && <div className="server-msg">{error}</div>
                            }

                            <button className="button fill full big mt10" type="submit">
                                VERIFY NOW
                            </button>
                        </form>
                    </div>
                    <div className="layer-strip"/>
                </Fragment>
            </Fragment>
        )
    }
}

export default VerifyEvdDetailsModal;
