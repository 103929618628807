import React, { Component } from 'react';

import './style.scss';

class RadioButton extends Component {
  onChange=(e)=>{
    let { name, onChange, id } = this.props,
      {value} = e.target;
    onChange && onChange(name, value, id);
  };
  render() {
    const {isValueDifferent = false, value, label, className, disabled = false, activeLabelClass = '', isChecked } = this.props;
    return (
      <div className="radio-button">
        <input type="radio" name={this.props.name}
          disabled={disabled}
          onChange={this.onChange}
          value={value}
          checked={isChecked}
          className={className}
        />
        <label>
          <i className="icon-check" />
        </label>
        <span className={`radio-text ${this.props.checked ? activeLabelClass : '' }`}>{isValueDifferent ? label : value}</span>
      </div>
    );
  }
}

export default RadioButton;