import React, { Component } from "react";
import ReactPaginate from "react-paginate";

import "./task-list-pagination.scss";

class TaskListPagination extends Component {
  
  itemsPerPageChanged = value => {
    this.props.itemsPerPageChanged(value);
  };

  handlePageClick = data => {
    this.props.handlePageChange((data.selected + 1) || 1);
  };

  render() {
    const { showResultsArray, itemsPerPage, selectedPage, totalPageCount } = this.props;
    const currentPage = selectedPage ? selectedPage - 1 : 0;
    return (
      <div className="task-list-pagination-wrapper">
        <ReactPaginate
          previousLabel={"PREV"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={currentPage}
          onPageChange={this.handlePageClick}
          containerClassName={"task-list-pagination"}
          activeClassName={"active"}
        />
        <form
          className="showing-result-form"
          onSubmit={() => this.authenticateUser()}
        >
          <label> Showing Result</label>
          <select
            className={""}
            value={itemsPerPage}
            onChange={e => this.itemsPerPageChanged(e.target.value)}
          >
            {showResultsArray &&
              showResultsArray.length &&
              showResultsArray.map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            ))}
          </select>
        </form>
      </div>
    );
  }
}

export default TaskListPagination;
