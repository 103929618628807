import React from "react";

const LeftSidebar = (props) => {
    const { activeTab, handleSelect, formConfig } = props;
    const isIntentEnabled = formConfig && formConfig.intentForm && formConfig.intentForm.enable ? 'list' : 'list not-allowed';
    const isEvalEnabled = formConfig && formConfig.evaluationForm && formConfig.evaluationForm.enable ? 'list' : 'list not-allowed';
    const isDocSoftCopyEnabled = formConfig && formConfig.documentForm && formConfig.documentForm.enable ? 'list' : 'list not-allowed';
    const isDoCHardCopyEnabled = formConfig && formConfig.originalDocument && formConfig.originalDocument.enable ? 'list' : 'list not-allowed';
    return (
        <>
            <ul className="left-sidebar-tab">
                <li className={activeTab === 0 ? 'list active' : isIntentEnabled}
                    onClick={() => { handleSelect(0) }}
                >
                    <div className="number">01</div>
                    <div className="value">
                        <div className="title">
                            Intent Form
                        </div>
                        <div className="sub-title">
                            To be filled by Applicant
                        </div>
                    </div>
                </li>
                <li className={activeTab === 1 ? 'list active' : isEvalEnabled}
                    onClick={() =>{handleSelect(1)}}
                >
                    <div className="number">02</div>
                    <div className="value">
                        <div className="title">
                            Evaluation Form
                        </div>
                        <div className="sub-title">
                            To be filled by ASI / ASM
                        </div>
                    </div>
                </li>

                <li className={activeTab === 2 ? 'list active' : isDocSoftCopyEnabled}
                    onClick={() =>{handleSelect(2)}}
                >
                    <div className="number">03</div>
                    <div className="value">
                        <div className="title">
                            Documents Softcopy
                        </div>
                        <div className="sub-title">
                            To be uploaded by Applicant after ASI/ASM Visit
                        </div>
                    </div>
                </li>

                <li className={activeTab === 3 ? 'list active' : isDoCHardCopyEnabled}
                    onClick={() =>{handleSelect(3)}}
                >
                    <div className="number">04</div>
                    <div className="value">
                        <div className="title">
                            Documents Hardcopy
                        </div>
                        <div className="sub-title">
                            To be sent by Applicant when asked by MIS
                        </div>
                    </div>
                </li>
            </ul>
        </>
    )
}

export default LeftSidebar;
