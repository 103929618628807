import React, { Component, Fragment } from 'react';
import NoDataFound from '../../Common/NoDataFound';

import './style.scss';

class Table extends Component {
  handleOnClick = (id) => {
    const {handleClick} = this.props;
    handleClick(id);
  };
  render() {
  const { showHead, rows, headings } = this.props;
  return (
    <table className="table">
      {showHead && headings.length > 0 &&
        <thead>
          <tr>
            {headings && headings.map((heading, index) => {
                return <th key={index}>{heading.value}</th>
              })
            }
          </tr>
        </thead>
      }
      <tbody>
      {rows && rows.length ?
        rows.map((row, index) => {
            return (
                <tr key={index} id={row.id}>
                  {headings.map((item, index) => {
                    if(item.key === 'owners' || item.key === 'sendTosOR' || item.key === 'sendCcsOR'){
                      const useKey = item.key;
                      const sectdOwners = row[useKey].map(e => e.name);
                      return <td key={index}>{sectdOwners.join(', ')}</td>
                    }
                    else if(item.key === 'escalationOwner') {
                      return <td key={index}>{row.escalationOwner && row.escalationOwner.name}</td>
                    }
                    else {
                      return <td key={index}>
                        {row[item.key] ? row[item.key] :
                        item.key === 'actions' && <Fragment>
                          <i className="icon-edit" onClick={() => this.handleOnClick(row.id)}>
                            <svg className="edit-item" viewBox="-20 -20 70 70">
                              <path fill="#3052F0" fillRule="evenodd" d="M31.062 10.438L12.786 28.149c-.305.294-1.016.986-11.153 2.826a1.412 1.412 0 0 1-1.226-.367 1.304 1.304 0 0 1-.379-1.185c1.879-9.729 2.597-10.499 2.909-10.802l.006-.009.009-.005L21.219.903a3.221 3.221 0 0 1 2.265-.913h.002c.858 0 1.663.325 2.27.913l5.303 5.138a3.058 3.058 0 0 1 .003 4.397zM9.3 26.656L4.486 21.99c-.399 1.491-.908 3.735-1.372 5.994 2.327-.449 4.647-.943 6.186-1.328zm19.814-18.74l-5.301-5.133a.462.462 0 0 0-.648-.002L5.858 19.554l5.971 5.788L29.117 8.555a.444.444 0 0 0-.003-.639z"/>
                            </svg>
                          </i>
                          {/* <i className="icon-del" onClick={() => this.handleOnClick(row.id)}>
                            <svg viewBox="-21 -18 70 70">
                              <path fill="#E64372" fillRule="evenodd" d="M26.411 12.458v19.429c0 2.268-1.838 4.113-4.097 4.113H6.686c-2.259 0-4.097-1.845-4.097-4.113V12.458A4.118 4.118 0 0 1 0 8.637v-.211c0-2.268 1.838-4.113 4.097-4.113h5.582C9.958 1.889 12.012 0 14.5 0c2.488 0 4.543 1.889 4.821 4.313h5.582C27.162 4.313 29 6.158 29 8.426v.211a4.118 4.118 0 0 1-2.589 3.821zM4.532 31.887c0 1.192.966 2.163 2.154 2.163h15.628a2.162 2.162 0 0 0 2.154-2.163V12.75H4.532v19.137zM14.5 1.95a2.92 2.92 0 0 0-2.857 2.363h5.715A2.921 2.921 0 0 0 14.5 1.95zm12.557 6.476a2.16 2.16 0 0 0-2.154-2.162H4.097a2.16 2.16 0 0 0-2.154 2.162v.211c0 1.192.966 2.163 2.154 2.163h20.806a2.161 2.161 0 0 0 2.154-2.163v-.211zm-7.348 23.129a.973.973 0 0 1-.972-.975V19.599c0-.539.435-.975.972-.975.536 0 .971.436.971.975V30.58a.973.973 0 0 1-.971.975zm-5.209 0a.973.973 0 0 1-.971-.975V19.599c0-.539.435-.975.971-.975s.971.436.971.975V30.58a.973.973 0 0 1-.971.975zm-5.209 0a.972.972 0 0 1-.971-.975V19.599c0-.539.435-.975.971-.975.537 0 .972.436.972.975V30.58a.974.974 0 0 1-.972.975z"/>
                            </svg>
                          </i> */}
                        </Fragment>}

                    </td>
                    }
                  })
                  }
                </tr>
            )
        }) : <tr>
          <td colSpan={headings.length}>
            <NoDataFound />
          </td>
        </tr>
      }
      </tbody>
    </table>
    );
  }
}

export default Table;
