import React from "react";

import {dateFormatter, getDays} from '../../../../../helper';
import "./task-row-element.scss";

const TaskRowElement = props => {
  const { data, redirectOnClick } = props;
  const dateFormat = 'dd/MMM/yyyy';
  return (
    <div
      className="task-row-wrapper"
      onClick={() => redirectOnClick(data.applicationFormId)}
    >
      {data.reverted ? (
        <span className="task-reverted"> {data.revertedByRole} </span>
      ) : null}
      <div className="task-number common-div">
        <span className="hash-symbol">#</span>
        <span className="task-number-value">{props.itemNumber}</span>
      </div>
      <div className="task-id-wrapper common-div">
        <span className="request-id">{data.displayId}</span>
        <span className="created-on">Created On {data.applicationCreatedOn && dateFormatter(data.applicationCreatedOn, dateFormat)}</span>
      </div>
      <div className="task-partner-wrapper common-div">
        <span
          className={`task-partner-name ${
            data.previousPartner ? "previousPartnerLogo" : ""
          }`}
        >
          {data.firmName}
        </span>
        <span className="task-partner-location">
          {data.line}, {data.state}
        </span>
      </div>
      <div className="task-assigned-wrapper common-div">
        <span className="task-assigned-on">
          Assigned To {data.assignedToRole} From{" "}
          <span className="task-assigned-days">{data.assignedOn && getDays(data.assignedOn)} Days</span>
        </span>
        <span className="task-assignee-name">{data.assignedToName}</span>
      </div>
    </div>
  );
};

export default TaskRowElement;
