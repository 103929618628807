import { INPUT_ERROR } from '../../constants';
import InputsValidator from './InputsValidator';

export default {

    validateLoginForm: ({
                            mobileNumber
                        }) => {
        let formStatus;
        const mobileNumberStatus = InputsValidator.validatePhoneNumber(mobileNumber || '');
        formStatus = {
            mobileNumberMessage: mobileNumberStatus.message,
            hasErrors: (
                mobileNumberStatus.error
            ),
        };
        return formStatus;
    },

    validateLoginOTPForm: ({
                            otp
                        }) => {
        let formStatus;
        const otpStatus = InputsValidator.validateLoginOtp(otp || '');
        formStatus = {
            otpMessage: otpStatus.message,
            hasErrors: (
                otpStatus.error
            ),
        };
        return formStatus;
    },

    validateRegistrationForm: ({
                                   firmName, firmMobileNumber, firmEmailAddress, termsAndCondition
                               }) => {
        let formStatus;
        const firmNameStatus = InputsValidator.validateFirmName(firmName || '');
        const firmMobileNumberStatus = InputsValidator.validatePhoneNumber(firmMobileNumber || '');
        const firmEmailAddressStatus = InputsValidator.validateEmail(firmEmailAddress || '');
        const termsAndConditionStatus = InputsValidator.validateTermsAndCondition(termsAndCondition || '');

        formStatus = {
            firmNameMessage: firmNameStatus.message,
            firmMobileNumberMessage: firmMobileNumberStatus.message,
            firmEmailAddressMessage: firmEmailAddressStatus.message,
            termsAndConditionMessage: termsAndConditionStatus.message,
            hasErrors: (
                firmNameStatus.error
                || firmMobileNumberStatus.error
                || firmEmailAddressStatus.error
                || termsAndConditionStatus.error
            ),
        };
        return formStatus;
    },

    validateOTPForm: ({
                            mobileOTP, emailOTP
                        }) => {
        let formStatus;
        const mobileOTPStatus = InputsValidator.validateLoginOtp(mobileOTP || '');
        const emailOTPStatus = InputsValidator.validateLoginOtp(emailOTP || '');
        formStatus = {
            mobileOTPMessage: mobileOTPStatus.message,
            emailOTPMessage: emailOTPStatus.message,
            hasErrors: (
                mobileOTPStatus.error
                || emailOTPStatus.error
            ),
        };
        return formStatus;
    },

    validateEvdOTPForm: (validateObj) => {
        const {mobileOTP,emailOTP} = validateObj;
        let formStatus;
        let mobileOTPStatus,emailOTPStatus;
       if(mobileOTP.visible && emailOTP.visible){
           mobileOTPStatus = InputsValidator.validateLoginOtp(mobileOTP.value || '');
           emailOTPStatus = InputsValidator.validateLoginOtp(emailOTP.value || '');
           formStatus = {
               mobileOTPMessage: mobileOTPStatus && mobileOTPStatus.message,
               emailOTPMessage: emailOTPStatus && emailOTPStatus.message,
               hasErrors: (
                   mobileOTPStatus.error
                   || emailOTPStatus.error
               ),
           };
       }else if(mobileOTP.visible){
           mobileOTPStatus = InputsValidator.validateLoginOtp(mobileOTP.value || '');
           formStatus = {
               mobileOTPMessage: mobileOTPStatus && mobileOTPStatus.message,
               hasErrors: (
                   mobileOTPStatus.error
               ),
           };
       }else {
           emailOTPStatus = InputsValidator.validateLoginOtp(emailOTP.value || '');
           formStatus = {
               emailOTPMessage: emailOTPStatus && emailOTPStatus.message,
               hasErrors: (
                   emailOTPStatus.error
               ),
           };
       }
        return formStatus;
    },

    validateIntentForm : (payload) => {
        const {
            firmName, establishmentYear, address, state,
            //  city, 
             town, pinCode, panNumber, gst, pincodeListArray,
            existingRelationship, currentBusinessSelectInputConfig, businessTurnover, termsAndCondition
        } = payload;
        let formStatus;
        const firmNameStatus = InputsValidator.validateFirmName(firmName || '');
        const establishmentYearStatus = InputsValidator.validateEstablishmentYear(establishmentYear || '');
        const addressStatus = InputsValidator.validateAddress(address || '');
        const stateStatus = InputsValidator.validateState(state || '');
        // const cityStatus = InputsValidator.validateCity(city || '');
        const townStatus = InputsValidator.validateTown(town || '');
        const pinCodeStatus = InputsValidator.validatePinCode(pinCode || '', pincodeListArray);
        const panNumberStatus = InputsValidator.validatePanNumber(panNumber || '');
        const gstStatus = InputsValidator.validateGstIntent(gst || {});
        const existingRelationshipStatus = InputsValidator.validateExistingRelationship(existingRelationship || {});
        const currentBusinessSelectInputConfigStatus = InputsValidator.validateCurrentBusinessAssociation(currentBusinessSelectInputConfig || {});
        const businessTurnoverStatus = InputsValidator.validateDecimalAmount((businessTurnover || ''), true);
        // const currentManpowerStatus = InputsValidator.validateNumber(currentManpower || '');
        const termsAndConditionStatus = InputsValidator.validateTermsAndCondition(termsAndCondition || '');
        formStatus = {
            firmNameMessage: firmNameStatus.message,
            establishmentYearMessage: establishmentYearStatus.message,
            addressMessage: addressStatus.message,
            stateMessage: stateStatus.message,
            // cityMessage: cityStatus.message,
            townMessage: townStatus.message,
            pinCodeMessage: pinCodeStatus.message,
            panNumberMessage: panNumberStatus.message,
            gstMessage: gstStatus.message,
            businessTurnoverMessage: businessTurnoverStatus.message,
            existingRelationshipObj: existingRelationshipStatus.errorArr,
            currentBusinessSelectInputConfigObj: currentBusinessSelectInputConfigStatus.errorArr,
            termsAndConditionMessage: termsAndConditionStatus.message,
            hasErrors: (
                firmNameStatus.error
                || establishmentYearStatus.error
                || addressStatus.error
                || stateStatus.error
                // || cityStatus.error
                || townStatus.error
                || pinCodeStatus.error
                || panNumberStatus.error
                || gstStatus.error
                || businessTurnoverStatus.error
                || existingRelationshipStatus.error
                || currentBusinessSelectInputConfigStatus.error
                || termsAndConditionStatus.error
            ),
        };
        return formStatus;
    },

    validateEvaluationForm : (payload) => {
        const {
            shopArea,
//            deliveryVehicle,
            manpowerCapacity,
            partnerType,
            territoryCovered,
            expectedTurnover,
            currentBusinessAssociation,
            bankDetails
        } = payload;
        let formStatus;
        const shopAreaStatus = InputsValidator.validateShopArea(shopArea || '');
        // const deliveryVehicleStatus = InputsValidator.validateDeliveryVehicle(deliveryVehicle || '');
        const manpowerCapacityStatus = InputsValidator.validateManpowerCapacity(manpowerCapacity || '');
        const partnerTypeStatus = InputsValidator.validatePartnerType(partnerType || '');
        const territoryCoveredStatus = InputsValidator.validateTerritoryCovered(territoryCovered || '');
        const expectedTurnoverStatus = InputsValidator.validateExpectedTurnover(expectedTurnover || '');
        const currentBusinessAssociationStatus = InputsValidator.validateCurrentBusinessAssociationEval(currentBusinessAssociation || {});
        const bankDetailsStatus = InputsValidator.validateBankDetailsEval(bankDetails || {});
        // const termsAndConditionStatus = InputsValidator.validateTermsAndCondition(termsAndCondition || '');
        formStatus = {
            shopAreaMessage: shopAreaStatus.message,
            // deliveryVehicleMessage: deliveryVehicleStatus.message,
            manpowerCapacityMessage: manpowerCapacityStatus.message,
            partnerTypeMessage: partnerTypeStatus.message,
            territoryCoveredMessage: territoryCoveredStatus.message,
            expectedTurnoverMessage: expectedTurnoverStatus.message,
            currentBusinessAssociationObj: currentBusinessAssociationStatus.errorArr,
            bankDetailsObj: bankDetailsStatus.errorArr,
            // termsAndConditionMessage: termsAndConditionStatus.message,

            hasErrors: (
                shopAreaStatus.error
                || manpowerCapacityStatus.error
                || partnerTypeStatus.error
                || territoryCoveredStatus.error
                || expectedTurnoverStatus.error
                || currentBusinessAssociationStatus.error
                || bankDetailsStatus.error

            ),
        };
        return formStatus;
    },

    validateStoreForm : (payload) => {
        const {
            shopArea,
//            deliveryVehicle,
            manpowerCapacity,
            partnerType,
            territoryCovered,
            expectedTurnover,
            currentBusinessAssociation,
            bankDetails,
            storeLocation,
            strength,
            weakness,
            opportunity,
            threat,
            prevExperience ,
            percentageShare,
            propertyBackground,
            isTSBBPresence,
            isStoreOnRoad,
            ageOfBuilding,
            activeCableSubscriber,
            projectedAcquisition,
            projectedRecharge,
            numberOfMonths,
            populationOfLocality,
            numberOfHouseholds,
            activeSubscriber,
            activeOtherSubscriber,
            monthlyCableSubscription,
            rechargeBase,
            allClusterName,
            numberOfHomepass,
            sizeOfStore,
            minimumFrontage,
            nearbyNumberOfStore,
            nearbyPreferredStore,
            distanceFromCurrentLocation,
            monthlyRented,
            securityDeposit,
            yearlyRental,
            proposedDateOfHandover,
            proposedDateOfLaunch,
            partnerName,
            familyDetails,
            isFamilyInDTH,
            briefDescription,
            otherDescription,
            nearbyImportantLandmark,
            prevThreeYearsDetail,
            noticeFromLocalAuthority,
            legalDisputeNOC,
            electricityConnectionCapacity,
            waterAccumulationIssue,
            leakageIssue,
            storeLocationInLCO,
            permissionToRepairWork,
            adjacentStores,
        } = payload;
        let formStatus;
        const shopAreaStatus = InputsValidator.validateShopArea(shopArea || '');
        const manpowerCapacityStatus = InputsValidator.validateManpowerCapacity(manpowerCapacity || '');
        const partnerTypeStatus = InputsValidator.validatePartnerType(partnerType || '');
        const territoryCoveredStatus = InputsValidator.validateTerritoryCovered(territoryCovered || '');
        const expectedTurnoverStatus = InputsValidator.validateExpectedTurnover(expectedTurnover || '');
        const currentBusinessAssociationStatus = InputsValidator.validateCurrentBusinessAssociationEval(currentBusinessAssociation || {});

        const bankDetailsStatus = InputsValidator.validateBankDetailsEval(bankDetails || {});
        const prevExperienceStatus = InputsValidator.validateDropdown(prevExperience || '');
        const percentageShareStatus = InputsValidator.validateDropdown(percentageShare || ''); 
        const propertyBackgroundStatus = InputsValidator.validateDropdown(propertyBackground || ''); 
        const isTSBBPresenceStatus = InputsValidator.validateDropdown(isTSBBPresence || ''); 
        const isStoreOnRoadStatus = InputsValidator.validateDropdown(isStoreOnRoad || ''); 
        const ageOfBuildingStatus = InputsValidator.validateDropdown(ageOfBuilding || ''); 
        const activeCableSubscriberStatus = InputsValidator.validateDropdown(activeCableSubscriber || '');

        const storeLocationStatus = InputsValidator.validateTextarea(storeLocation || '');
        const strengthStatus = InputsValidator.validateTextarea(strength || '');
        const weaknessStatus = InputsValidator.validateTextarea(weakness || '');
        const opportunityStatus = InputsValidator.validateTextarea(opportunity || '');
        const threatStatus = InputsValidator.validateTextarea(threat || '');

        const projectedAcquisitionStatus = InputsValidator.validateTextarea(projectedAcquisition || '');
        const projectedRechargeStatus = InputsValidator.validateTextarea(projectedRecharge || '');
        const numberOfMonthsStatus = InputsValidator.validateTextarea(numberOfMonths || '');
        const populationOfLocalityStatus = InputsValidator.validateTextarea(populationOfLocality || '');
        const numberOfHouseholdsStatus = InputsValidator.validateTextarea(numberOfHouseholds || '');
        const activeSubscriberStatus = InputsValidator.validateTextarea(activeSubscriber || '');
        const activeOtherSubscriberStatus = InputsValidator.validateTextarea(activeOtherSubscriber || '');
        const monthlyCableSubscriptionStatus = InputsValidator.validateTextarea(monthlyCableSubscription || '');
        const rechargeBaseStatus = InputsValidator.validateTextarea(rechargeBase || '');
        const allClusterNameStatus = InputsValidator.validateTextarea(allClusterName || '');
        const numberOfHomepassStatus = InputsValidator.validateTextarea(numberOfHomepass || '');
        const sizeOfStoreStatus = InputsValidator.validateTextarea(sizeOfStore || '');
        const minimumFrontageStatus = InputsValidator.validateTextarea(minimumFrontage || '');
        const nearbyNumberOfStoreStatus = InputsValidator.validateTextarea(nearbyNumberOfStore || '');
        const nearbyPreferredStoreStatus = InputsValidator.validateTextarea(nearbyPreferredStore || '');
        const distanceFromCurrentLocationStatus = InputsValidator.validateTextarea(distanceFromCurrentLocation || '');
        const monthlyRentedStatus = InputsValidator.validateTextarea(monthlyRented || '');
        const securityDepositStatus = InputsValidator.validateTextarea(securityDeposit || '');
        const yearlyRentalStatus = InputsValidator.validateTextarea(yearlyRental || '');

        const proposedDateOfHandoverStatus = InputsValidator.validateDateValue(proposedDateOfHandover || '');
        const proposedDateOfLaunchStatus = InputsValidator.validateDateValue(proposedDateOfLaunch || '');

        const partnerNameStatus = InputsValidator.validateInputValue(partnerName || '');
        const familyDetailsStatus = InputsValidator.validateInputValue(familyDetails || '');
        const isFamilyInDTHStatus = InputsValidator.validateInputWithRadioValue(isFamilyInDTH || '');
        const briefDescriptionStatus = InputsValidator.validateInputValue(briefDescription || '');
        const otherDescriptionStatus = InputsValidator.validateInputValue(otherDescription || '');
        const nearbyImportantLandmarkStatus = InputsValidator.validateInputValue(nearbyImportantLandmark || '');
        const prevThreeYearsDetailStatus = InputsValidator.validateInputValue(prevThreeYearsDetail || '');
        const noticeFromLocalAuthorityStatus = InputsValidator.validateSelect(noticeFromLocalAuthority || '');
        const legalDisputeNOCStatus = InputsValidator.validateSelect(legalDisputeNOC || '');
        const electricityConnectionCapacityStatus = InputsValidator.validateSelect(electricityConnectionCapacity || '');
        const waterAccumulationIssueStatus = InputsValidator.validateSelect(waterAccumulationIssue || '');
        const leakageIssueStatus = InputsValidator.validateSelect(leakageIssue || '');
        const storeLocationInLCOStatus = InputsValidator.validateSelect(storeLocationInLCO || '');
        const permissionToRepairWorkStatus = InputsValidator.validateSelect(permissionToRepairWork || '');
        const adjacentStoresStatus = InputsValidator.validateSelect(adjacentStores || '');
        if(proposedDateOfLaunch && proposedDateOfHandover && proposedDateOfLaunch <= proposedDateOfHandover) {
            proposedDateOfLaunchStatus.message = INPUT_ERROR.LAUNCH_DATE_VALIDATION;
            proposedDateOfLaunchStatus.error = true;
        }
        formStatus = {
            shopAreaMessage: shopAreaStatus.message,
            manpowerCapacityMessage: manpowerCapacityStatus.message,
            partnerTypeMessage: partnerTypeStatus.message,
            territoryCoveredMessage: territoryCoveredStatus.message,
            expectedTurnoverMessage: expectedTurnoverStatus.message,
            currentBusinessAssociationObj: currentBusinessAssociationStatus.errorArr,
            bankDetailsObj: bankDetailsStatus.errorArr,

            prevExperience: prevExperienceStatus.message,
            percentageShare: percentageShareStatus.message,
            propertyBackground: propertyBackgroundStatus.message,
            isTSBBPresence: isTSBBPresenceStatus.message,
            isStoreOnRoad: isStoreOnRoadStatus.message,
            ageOfBuilding: ageOfBuildingStatus.message,
            activeCableSubscriber: activeCableSubscriberStatus.message,

            storeLocationMessage: storeLocationStatus.message,
            strengthMessage: strengthStatus.message,
            weaknessMessage: weaknessStatus.message,
            opportunityMessage: opportunityStatus.message,
            threatMessage: threatStatus.message,
            projectedAcquisitionMessage: projectedAcquisitionStatus.message,
            projectedRechargeMessage: projectedRechargeStatus.message,
            numberOfMonthsMessage: numberOfMonthsStatus.message,
            populationOfLocalityMessage: populationOfLocalityStatus.message,
            numberOfHouseholdsMessage: numberOfHouseholdsStatus.message,
            activeSubscriberMessage: activeSubscriberStatus.message,
            activeOtherSubscriberMessage: activeOtherSubscriberStatus.message,
            monthlyCableSubscriptionMessage: monthlyCableSubscriptionStatus.message,
            rechargeBaseMessage: rechargeBaseStatus.message,
            allClusterNameMessage: allClusterNameStatus.message,
            numberOfHomepassMessage: numberOfHomepassStatus.message,
            sizeOfStoreMessage: sizeOfStoreStatus.message,
            minimumFrontageMessage: minimumFrontageStatus.message,
            nearbyNumberOfStoreMessage: nearbyNumberOfStoreStatus.message,
            nearbyPreferredStoreMessage: nearbyPreferredStoreStatus.message,
            distanceFromCurrentLocationMessage: distanceFromCurrentLocationStatus.message,
            monthlyRentedMessage: monthlyRentedStatus.message,
            securityDepositMessage: securityDepositStatus.message,
            yearlyRentalMessage: yearlyRentalStatus.message,
            proposedDateOfHandoverMessage: proposedDateOfHandoverStatus.message,
            proposedDateOfLaunchMessage: proposedDateOfLaunchStatus.message,

            partnerNameMessage: partnerNameStatus.message,
            familyDetailsMessage: familyDetailsStatus.message,
            isFamilyInDTHMessage: isFamilyInDTHStatus.message,
            briefDescriptionMessage: briefDescriptionStatus.message,
            otherDescriptionMessage: otherDescriptionStatus.message,
            nearbyImportantLandmarkMessage: nearbyImportantLandmarkStatus.message,
            prevThreeYearsDetailMessage: prevThreeYearsDetailStatus.message,
            noticeFromLocalAuthorityMessage: noticeFromLocalAuthorityStatus.message,
            legalDisputeNOCMessage: legalDisputeNOCStatus.message,
            electricityConnectionCapacityMessage: electricityConnectionCapacityStatus.message,
            waterAccumulationIssueMessage: waterAccumulationIssueStatus.message,
            leakageIssueMessage: leakageIssueStatus.message,
            storeLocationInLCOMessage: storeLocationInLCOStatus.message,
            permissionToRepairWorkMessage: permissionToRepairWorkStatus.message,
            adjacentStoresMessage: adjacentStoresStatus.message,

            hasErrors: (
                shopAreaStatus.error
                || manpowerCapacityStatus.error
                || partnerTypeStatus.error
                || territoryCoveredStatus.error
                || expectedTurnoverStatus.error
                || currentBusinessAssociationStatus.error
                || bankDetailsStatus.error
                || storeLocationStatus.error
                || strengthStatus.error
                || weaknessStatus.error
                || opportunityStatus.error
                || threatStatus.error
                || prevExperienceStatus.error
                || percentageShareStatus.error
                || propertyBackgroundStatus.error
                || isTSBBPresenceStatus.error
                || isStoreOnRoadStatus.error
                || ageOfBuildingStatus.error
                || activeCableSubscriberStatus.error
                || projectedAcquisitionStatus.error
                || projectedRechargeStatus.error
                || numberOfMonthsStatus.error
                || populationOfLocalityStatus.error
                || numberOfHouseholdsStatus.error
                || activeSubscriberStatus.error
                || activeOtherSubscriberStatus.error
                || monthlyCableSubscriptionStatus.error
                || rechargeBaseStatus.error
                || allClusterNameStatus.error
                || numberOfHomepassStatus.error
                || sizeOfStoreStatus.error
                || minimumFrontageStatus.error
                || nearbyNumberOfStoreStatus.error
                || nearbyPreferredStoreStatus.error
                || distanceFromCurrentLocationStatus.error
                || monthlyRentedStatus.error
                || securityDepositStatus.error
                || yearlyRentalStatus.error

                || partnerNameStatus.error
                || familyDetailsStatus.error
                || isFamilyInDTHStatus.error
                || briefDescriptionStatus.error
                || otherDescriptionStatus.error
                || nearbyImportantLandmarkStatus.error
                || prevThreeYearsDetailStatus.error
                || noticeFromLocalAuthorityStatus.error
                || legalDisputeNOCStatus.error
                || electricityConnectionCapacityStatus.error
                || waterAccumulationIssueStatus.error
                || leakageIssueStatus.error
                || storeLocationInLCOStatus.error
                || permissionToRepairWorkStatus.error
                || adjacentStoresStatus.error
                || proposedDateOfHandoverStatus.error
                || proposedDateOfLaunchStatus.error

            )
        };
        return formStatus;
    },

    validateActiveSubscriber : (payload) => {
        const { pinCodes } = payload;
        let validationStatus;
        const pinCodesStatus = InputsValidator.validatePincodesValue(pinCodes || '');
        
        pinCodesStatus.forEach(key => {
            if(key.error) {
                validationStatus = key.error
            }
        })
        
        return {pinCodesStatus: pinCodesStatus, validationStatus: validationStatus};
    },

    validateHardCopyForm : (payload, noTermValidate) => {
        const {
            trackId, hardCopyUpload, termsAndCondition,
        } = payload;
        let formStatus;
        const trackIdStatus = InputsValidator.validateTrackId(trackId || '');
        const hardCopyUploadStatus = InputsValidator.validateHardCopyUpload(hardCopyUpload || []);
        const termsAndConditionStatus = InputsValidator.validateTermsAndCondition(termsAndCondition || '', noTermValidate);
        formStatus = {
            trackIdMessage: trackIdStatus.message,
            hardCopyUploadMessage: hardCopyUploadStatus.message,
            termsAndConditionMessage: termsAndConditionStatus.message,
            hasErrors: (
                trackIdStatus.error
                || hardCopyUploadStatus.error
                || termsAndConditionStatus.error
            ),
        };
        return formStatus;
    },

  validateSoftCopyForm : (payload, firmType, warehouseAvailable) => {
    const {
      totalArea, managerName, managerMobileNo, address, state, 
    //   city, 
      town, pinCode, evdMobileNo, evdEmailAddress,
      partnerDetails, bankDetails, uploadDocumentsTemplate,termsAndCondition,pincodeListArray
    } = payload;
    let formStatus;
    const totalAreaStatus = InputsValidator.validateTotalArea(totalArea || '', warehouseAvailable);
    const managerNameStatus = InputsValidator.validateName(managerName || '', warehouseAvailable);
    const managerMobileNoStatus = InputsValidator.validatePhoneNumber(managerMobileNo || '', warehouseAvailable);
    const addressStatus = InputsValidator.validateAddress(address || '', warehouseAvailable);
    const stateStatus = InputsValidator.validateState(state || '', warehouseAvailable);
    // const cityStatus = InputsValidator.validateCity(city || '', warehouseAvailable);
    const townStatus = InputsValidator.validateTown(town || '', warehouseAvailable);
    const pinCodeStatus = InputsValidator.validatePinCode(pinCode || '', pincodeListArray, warehouseAvailable);
    const evdMobileNoStatus = InputsValidator.validatePhoneNumber(evdMobileNo || '');
    const evdEmailAddressStatus = InputsValidator.validateEmail(evdEmailAddress || '');
    const partnerDetailsStatus = InputsValidator.validatePartnerDetails(partnerDetails || '', firmType);
    const bankDetailStatus = InputsValidator.validateBankDetails(bankDetails || '');
    const uploadDocumentsTemplateStatus = InputsValidator.validateUploadDocumentsTemplate(uploadDocumentsTemplate.templateArr || '');
    const termsAndConditionStatus = InputsValidator.validateTermsAndCondition(termsAndCondition || '');
    formStatus = {
      totalAreaMessage: totalAreaStatus.message,
      managerNameMessage: managerNameStatus.message,
      managerMobileNoMessage: managerMobileNoStatus.message,
      addressMessage: addressStatus.message,
      stateMessage: stateStatus.message,
      // cityMessage: cityStatus.message,
      townMessage: townStatus.message,
      pinCodeMessage: pinCodeStatus.message,
      evdMobileNoMessage: evdMobileNoStatus.message,
      evdEmailAddressMessage: evdEmailAddressStatus.message,
      partnerDetailsObj: partnerDetailsStatus.errorArr,
      bankDetailsObj: bankDetailStatus.errorArr,
      uploadDocumentsTemplateObj: uploadDocumentsTemplateStatus.errorArr,
      termsAndConditionMessage: termsAndConditionStatus.message,
      hasErrors: (
          totalAreaStatus.error
          || managerNameStatus.error
          || managerMobileNoStatus.error
          || addressStatus.error
          || stateStatus.error
          // || cityStatus.error
          || townStatus.error
          || pinCodeStatus.error
          || evdMobileNoStatus.error
          || evdEmailAddressStatus.error
          || partnerDetailsStatus.error
          || bankDetailStatus.error
          || uploadDocumentsTemplateStatus.error
          || termsAndConditionStatus.error
      ),
    };
    return formStatus;
  },

    validateAdminWorkflowForm : (payload) => {
        const { name, description, packageInstanceMapping, workflow , isFirst } = payload;
        let formStatus;
        const nameStatus = InputsValidator.validateName(name || '');
        const descriptionStatus = InputsValidator.validateTextarea(description || '');
        const packageInstanceMappingStatus = InputsValidator.validateSelect(packageInstanceMapping || '');
        const workflowStatus = InputsValidator.validateWorkflow((workflow || []), isFirst);
        formStatus = {
          nameMessage: nameStatus.message,
          descriptionMessage: descriptionStatus.message,
          packageInstanceMappingMessage: packageInstanceMappingStatus.message,
          workflowMessage: workflowStatus.updatedWorkFlow,
          hasErrors: (
            nameStatus.error
            || descriptionStatus.error
            || packageInstanceMappingStatus.error
            || workflowStatus.error
          ),
        };
        return formStatus;
    },

    validateDashboardSelectForm : (payload) => {
        const { assignee, comment } = payload;
        let formStatus;
        const assigneeStatus = InputsValidator.validateRequired(assignee || '');
        const commentStatus = InputsValidator.validateRequired(comment || '');
        formStatus = {
            assigneeMessage: assigneeStatus.message,
            commentMessage: commentStatus.message,
            hasErrors: (
                assigneeStatus.error
                || commentStatus.error
            ),
        };
        return formStatus;
    },

    validateDashboardCommentForm : (payload) => {
        const { comment } = payload;
        let formStatus;
        const commentStatus = InputsValidator.validateRequired(comment || '');
        formStatus = {
            commentMessage: commentStatus.message,
            hasErrors: (
                commentStatus.error
            ),
        };
        return formStatus;
    },

    validateApplicationCodeForm : (payload) => {
        const { partnerCode, 
            // virtualCode, tataSkyBankAccountNumber, tataSkyBankName, tataSkyBankIfscCode, /*comment*/ 
        } = payload;
        let formStatus;
        const partnerCodeStatus = InputsValidator.validatePartnerCode(partnerCode || '');
        // const virtualCodeStatus = InputsValidator.validateVirtualCode(virtualCode || '');
        // const tataSkyBankAccountNumberStatus = InputsValidator.validateRequired(tataSkyBankAccountNumber || '');
        // const tataSkyBankNameStatus = InputsValidator.validateRequired(tataSkyBankName || '');
        // const tataSkyBankIfscCodeStatus = InputsValidator.validateRequired(tataSkyBankIfscCode || '');
        formStatus = {
            partnerCodeMessage: partnerCodeStatus.message,
            // virtualCodeMessage: virtualCodeStatus.message,
            // tataSkyBankAccountNumberMessage: tataSkyBankAccountNumberStatus.message,
            // tataSkyBankNameMessage: tataSkyBankNameStatus.message,
            // tataSkyBankIfscCodeMessage: tataSkyBankIfscCodeStatus.message,
            hasErrors: (
                partnerCodeStatus.error
                // || virtualCodeStatus.error
                // || tataSkyBankAccountNumberStatus.error
                // || tataSkyBankNameStatus.error
                // || tataSkyBankIfscCodeStatus.error
            ),
        };
        return formStatus;
    },

    validateBusinessWorkFlowForm:  (payload) =>{
        const { formData : {name, LineOfBusiness, selectedWorkflow} } = payload;
        let formStatus;
        const nameStatus = InputsValidator.validateName( name || '' );
        const lobStatus = InputsValidator.validateRequired( LineOfBusiness || '' );
        const selectionWfStatus = InputsValidator.validateEmptyArray( selectedWorkflow || '' );

        formStatus = {
            nameError: nameStatus.message,
            LineOfBusinessError: lobStatus.message,
            selectedWorkflowError: selectionWfStatus.message,
            hasErrors: (
                nameStatus.error
                || lobStatus.error
                || selectionWfStatus.error
            ),
        }
        return formStatus;
    },

    validateAdminActions: (payload) =>{
        const { name,message, label, description, type, priority, actionCategory, validate, status } = payload;
        let formStatus;
        const nameStatus = InputsValidator.validateName( name || '' );
        const messageStatus = InputsValidator.validateTextarea( message || '' );
        const labelStatus = InputsValidator.validateName( label || '' );
        const descriptionStatus = InputsValidator.validateTextarea( description || '' );
        const typeStatus = InputsValidator.validateRequired( type || '' );
        const priorityStatus = InputsValidator.validateRequired( priority || '' );
        const actionCategoryStatus = InputsValidator.validateRequired( actionCategory || '' );
        const validateStatus = InputsValidator.validateRequired( validate || '' );
        const statusStatus = InputsValidator.validateRequired( status || '' );
        formStatus = {
            nameError: nameStatus.message,
            messageError: messageStatus.message,
            labelError: labelStatus.message,
            descriptionError: descriptionStatus.message,
            typeError: typeStatus.message,
            priorityError: priorityStatus.message,
            actionCategoryError: actionCategoryStatus.message,
            validateError: validateStatus.message,
            statusError: statusStatus.message,
            hasErrors: (
                nameStatus.error
                ||messageStatus.error
                || labelStatus.error
                || descriptionStatus.error
                || typeStatus.error
                || priorityStatus.error
                || actionCategoryStatus.error
                || validateStatus.error
                || statusStatus.error
            ),
        }
        return formStatus;
    },

    validateAdminFormVisibility: (payload) =>{
            const {formName, formType} = payload;
            let formStatus;
            const formNameStatus = InputsValidator.validateName( formName || '' );
            const formTypeStatus = InputsValidator.validateRequired( formType || '' );
            formStatus = {
                formNameError: formNameStatus.message,
                formTypeError: formTypeStatus.message,
                hasErrors: (
                    formNameStatus.error
                    || formTypeStatus.error
                ),
            }
        return formStatus
    },

    validateAdminNotificationTemplet: (payload) =>{
        const { name, message, role} = payload;
        let formStatus;
        const nameStatus = InputsValidator.validateName( name || '' );
        const messageStatus = InputsValidator.validateTextarea( message || '' );
        const roleStatus = InputsValidator.validateObject( role || '' );
        formStatus = {
            nameError: nameStatus.message,
            messageError: messageStatus.message,
            roleError: roleStatus.message,
            hasErrors: (
                nameStatus.error
                || messageStatus.error
                || roleStatus.error
            ),
        }
     return formStatus
    },

    validateAdminState: (payload) =>{
        const { name, description, sla, 
            // owners, 
            escalationOwner, assignedOwner, formVisibility, 
            // formType, sendNotificationTo 
        } = payload;
        let formStatus;
        const nameStatus = InputsValidator.validateName( name || '' );
        const slaStatus = InputsValidator.validateRequired( sla || '' );
        const descriptionStatus = InputsValidator.validateTextarea( description || '' );
        // const ownersStatus = InputsValidator.validateEmptyArray( owners || '' );
        const escalationOwnerStatus = InputsValidator.validateObject( escalationOwner || '' );
        const assignedOwnerStatus = InputsValidator.validateObject( assignedOwner || '' );
        const formVisibilityStatus = InputsValidator.validateEmptyArray( formVisibility || '' );
        // const sendNotificationToStatus = InputsValidator.validateEmptyArray( sendNotificationTo || '' );
        formStatus = {
            nameError: nameStatus.message,
            slaError: slaStatus.message,
            descriptionError: descriptionStatus.message,
            // ownersError: ownersStatus.message,
            escalationOwnerError: escalationOwnerStatus.message,
            assignedOwnerError: assignedOwnerStatus.message,
            formVisibilityError: formVisibilityStatus.message,
            // sendNotificationToError: sendNotificationToStatus.message,
            hasErrors: (
                nameStatus.error
                || slaStatus.error
                || descriptionStatus.error
                // || ownersStatus.error
                || escalationOwnerStatus.error
                || assignedOwnerStatus.error
                || formVisibilityStatus.error
                // || sendNotificationToStatus.error
            ),
        }
        return formStatus;
    },
    validatePartnerCreationForm : (payload) => {
        const formArray = Object.entries(payload)
        let formStatus;
        const formStatusArray = [];
        formArray.forEach(input => {
            const [ key, config ] = input;
            let inputStatus = {};
            if (config.validation === 'required')
                inputStatus = InputsValidator.validateRequired(config.value || '')
            if (config.validation === 'validateFirmName')
                inputStatus = InputsValidator.validateFirmName(config.value || '')
            if (config.validation === 'validateAddress')
                inputStatus = InputsValidator.validateAddress(config.value || '')
            if (config.validation === 'validatePinCode')
                inputStatus = InputsValidator.validatePinCode(config.value || '')
            if (config.validation === 'validatePhoneNumber')
                inputStatus = InputsValidator.validatePhoneNumber(config.value || '')
            if (config.validation === 'validateEmail')
                inputStatus = InputsValidator.validateEmail(config.value || '')
            if (config.validation === 'validatePanNumber')
                inputStatus = InputsValidator.validatePanNumber(config.value || '')
            if (config.validation === 'validateGstIntent')
                inputStatus = InputsValidator.validateGstIntent(config.value || '')
            
            inputStatus.page = config.page;
            inputStatus.key = key;
            formStatusArray.push(inputStatus)
        })
        formStatus = {
            formInputStatus:formStatusArray,
            hasErrors: (
                formStatusArray.some(x => x.error)
            ),
        }
        return formStatus
    }
}
