import React from 'react';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {closeModal} from "../../../storeManager/modal/actions";
import {connect} from "react-redux";
import FileViewer from "react-file-viewer";
// import Error from "../Error"

const FileViewerPopup = (props) => {
  const {modalParameters: {url, urlType}, closePopup} = props;
  return (
      <>
        <FileViewer
            fileType={urlType}
            filePath={url}
            // filePath={'https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.docx'}
            // errorComponent={Error}
            // onError={this.onError}
        />
        <i className="close-icon" onClick={() => {closePopup()}}>
          <svg width="13" height="12" viewBox="0 0 25 24">
            <title>Close</title>
            <path fillRule="evenodd" d="M15.106 11.994L24.55 2.55A1.501 1.501 0 1022.426.426L12.982 9.87 3.538.426A1.501 1.501 0 101.414 2.55l9.444 9.444-9.444 9.444a1.501 1.501 0 102.124 2.124l9.444-9.444 9.444 9.444a1.499 1.499 0 002.124 0 1.501 1.501 0 000-2.124l-9.444-9.444z"/>
          </svg>
        </i>
      </>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(FileViewerPopup));
