import React from 'react';
// import {PATHS} from "../../../constants";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {closeModal} from "../../../storeManager/modal/actions";
import {connect} from "react-redux";

const ConfirmationPopup = (props) => {
    /* redirectToHome = () => {
        const {history, closeModal} = this.props;
        closeModal();
        history.push(PATHS.HOME)
    }; */

    const {modalParameters: {message, /*reDirectLinkHide, redirectLoginPage*/onCloseCustomFunction, isCustomCloseFunction}, closePopup} = props;
        return (
            <div className="text-center ptb30">
                <div className="custom-modal-header pb35">
                    <div className="gradient-text">
                        {message}
                    </div>
                </div>
                {isCustomCloseFunction &&
                <i className="close-icon" onClick={() => {closePopup(); onCloseCustomFunction();}}>
                            <svg width="13" height="12" viewBox="0 0 25 24">
                              <title>Close</title>
                              <path fillRule="evenodd" d="M15.106 11.994L24.55 2.55A1.501 1.501 0 1022.426.426L12.982 9.87 3.538.426A1.501 1.501 0 101.414 2.55l9.444 9.444-9.444 9.444a1.501 1.501 0 102.124 2.124l9.444-9.444 9.444 9.444a1.499 1.499 0 002.124 0 1.501 1.501 0 000-2.124l-9.444-9.444z"/>
                            </svg>
                          </i>}
                {/*{
                    reDirectLinkHide ?  null : (
                        <button className="button fill" onClick={() => this.redirectToHome()}>
                            {
                                redirectLoginPage ? 'Go to Login' : 'Go to Dashboard'
                            }
                        </button>
                    )
                }*/}
            </div>
        );
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(ConfirmationPopup));
