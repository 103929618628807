import GlobalConfig from '../../globalConfig/globalConfig';

export const sendOtpApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/auth/sendOtp`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const registerUserApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/registration/signup`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const dashBoardListApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/getDashboardTasks`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const dashBoardFilterListApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/getDashboardCounts`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const validateLoginAPI = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/auth/validateLoginOTP`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getIntentForm = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/form/intentForm`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getPartnerDetails = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/partner`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getStateList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/state/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getCityList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/city/findByState/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getPincodeList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/pincode/getPincode`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getTownsListApi = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/town/getTown`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getExistingRelationshipList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/channel/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getBusinessAssociationList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/digitalService/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const partnerFormSubmitApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/partner/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getUploadDocumentTemplateApi = {
    method:'GET',
    url:`${GlobalConfig.API_ROOT}/documentTemplate/`,
    headers: GlobalConfig.getHeaders(['JSON'])
};

export const uploadFile = {
    method: 'POST',
    url:`${GlobalConfig.API_ROOT}/document/uploadFile`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getBankList = {
    method:'GET',
    url:`${GlobalConfig.API_ROOT}/bank/`,
    headers: GlobalConfig.getHeaders(['JSON'])
};

export const downloadDocuments = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/document/downloadZipFile`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchAdminActionsList = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/action/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getCategoryList = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/action/getCategoryList`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getProgressStatus = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/getProgressStatus`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchFormVisibilityData = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/formVisibility/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchBusinessControllerData = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/lineofbusiness/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const BussinessControllerData = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/lineofbusiness/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getFormType = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/form/types`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getForm = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/form/types`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createFormVisibility = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/formVisibility/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createLineOfBusiness = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/lineofbusiness/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createNotificationTemplate = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/notificationTemplate/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createBusinessWorkflow = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/lineofbusinessworkflow/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchAdminStateList = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/workflowState/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchEmailTemplateList = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/emailTemplate/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchPackageInstanceMapping = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/packageMapping/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createWorkflow = {
    method: 'POST',
    url:`${GlobalConfig.API_ROOT}/workflow/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchAllWorkflow = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/workflow/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchAllNotification = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/notificationTemplate/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const fetchAllBusinessWorkflow = {
    method: 'GET',
    url:`${GlobalConfig.API_ROOT}/lineofbusinessworkflow/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createAction = {
    method: 'POST',
      url: `${GlobalConfig.API_ROOT}/action/create`,
      headers: GlobalConfig.getHeaders(['JSON']),
};

export const getActionDetails = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/action`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createState = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/workflowState/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getStateDetails = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/lineofbusiness/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const createEmailTemplate = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/emailTemplate/create`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getEmailtemplateDetails = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/emailTemplate/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getLineOfBussinessDetail = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/workflowState`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getNotificationTemplate = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/workflowState`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const actionTypeList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/action/getTypeList`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const actionPriorityList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/action/getPriorityList`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const ownersList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/role/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const notificationList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/notificationTemplate/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const verifyEvdDetails = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/firm/verifyEvdDetails`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const validateEvdOtp = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/firm/validateEvdOtp`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const assigneeList = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/partner/getAssigneeList`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const partnerChangeStateApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/partner/changeState`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const notificationListApi = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/getUserNotification`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const notificationStatusChangeApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/userViewedNotification`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getStoreTemplates = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/storeTemplate/`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const activeSubscriber = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/activeBase/calculate`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const logoutApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/auth/logout`,
    headers: GlobalConfig.getHeaders(['JSON']),
};
export const downloadFilesApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/document/downloadS3Files`,
    headers: GlobalConfig.getHeaders(['JSON']),
};
export const createPartnerFormApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/partner/saveCodeCreationForm/Submit`,
    headers: GlobalConfig.getHeaders(['JSON']),
};
export const DraftPartnerFormApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/partner/saveCodeCreationForm/Save`,
    headers: GlobalConfig.getHeaders(['JSON']),
}
export const  getPartnerFormInfoApi = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/partner/getCodeCreationForm`,
    headers: GlobalConfig.getHeaders(['JSON']),
}
export const createPartnerDropdownListApi = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/getAllDropDownValues`,
    headers: GlobalConfig.getHeaders(['JSON']),
}