export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const MODALS = {
  FILE_VIEWER_POPUP: 'FILE_VIEWER_POPUP',
  CONFIRMATION_POPUP: 'CONFIRMATION_POPUP',
  APPROVE_DOCUMENT_MODAL: 'APPROVE_DOCUMENT_MODAL',
  VERIFY_EVD_DETAILS_MODAL:'VERIFY_EVD_DETAILS_MODAL',
  SELECT_MODAL: 'SELECT_MODAL',
  COMMENTABLE_MODAL: 'COMMENTABLE_MODAL',
  ASSIGN_TO_ME_MODAL:'COMMENTABLE_MODAL',
  REOPEN_MODAL:'COMMENTABLE_MODAL',
  APPLICATION_CODE_MODAL:'APPLICATION_CODE_MODAL',
  NOTIFICATION_MODAL: 'NOTIFICATION_MODAL',
  REASSIGN_MODAL: 'REASSIGN_MODAL',
  SHOW_MSG_ON_DASHBOARD_BUTTON: 'SHOW_MSG_ON_DASHBOARD_BUTTON',
  REOPEN_ONHOLD_MODAL: 'REOPEN_ONHOLD_MODAL',
  PARTNER_FORM_CREATION_MODAL: 'PARTNER_FORM_CREATION_MODAL'
};
