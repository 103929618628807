import React from "react";

const StatusDetails = (props) => {
    const {status, id, assignedTo} = props;
  return (
      <div className="application-summary-details">
          <div className="prl20">
              <div className="lh-1 pb20">
                  <div className="title">
                      APPLICATION ID
                  </div>
                  <div className="value">
                      {id}
                  </div>
              </div>

              <div className="pb10 lh-1">
                  <div className="title">
                      Assigned to
                  </div>
                  <div className="value">
                      {assignedTo || '-'}
                  </div>
              </div>
          </div>

          <div className="status prl20">
              <div className="title">
                  Application Status
              </div>
              <div className="value">
                  {status && status.replace('_',' ')}
              </div>
          </div>
      </div>
  )
};

export default StatusDetails;