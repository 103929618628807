export const PATHS = {
  LOGIN: '/login',
  REGISTER: '/register',
  HOME: '/',
  DASHBOARD: '/dashboard',
  DETAIL: '/listing-detail',
  SERVER_ERROR: '/server-error',
  SOMETHING_WENT_WRONG: '/something-went-wrong',
  VERIFY_USER: '/verify-user',
  APPLICATION_FORM: '/application-form',
  ADMIN: '/admin',
  ABOUT_US: '/about-us',
  LANDING_DASHBOARD: '/landing-dashboard',
};

export const TABLE_HEADER = {
  digitalService: "Type",
  company: "Brand",
  marketAreaCovered: "Market Area Covered",
  sinceYear: "Since",
  sisterConcern:"Sister Concern",
  remarks:"Remark"
};

export const MESSAGES = {
  SOMETHING_WENT_WRONG: "Oh no, something went wrong !",
  SERVER_ERROR: "Server error !",
  NON_EXIST_EMAIL: "Email ID not configured for this account. Please contact customer care for support",
  DID_MOUNT_ERROR: "API is not working",
  PHONE_TOOLTIP: "This will be your login id and all communication will happen on to this number",
  EMAIL_TOOLTIP: "This will be your business email id and all communication will happen on to this email id",
  UPLOAD_FILE: "Uploading File",
  NO_FILE_AVAILABLE: "No file available",
  GST_TEXT: 'Please upload self signed document if GSTIN certificate is not available.',
  STOP_ACTION:'This action can not be performed since another application is IN PROGRESS.'
};

export const INPUT_ERROR = {
  REQUIRED: "This field is mandatory",
  CHAIN_ID: "Chain Id is not valid",
  PASSWORD: "Password can't be less than 8 characters",
  CONFIRM_PASSWORD: "Please enter the Confirm password same as password",
  MOBILE_NUMBER: "Invalid Mobile Number",
  EMAIL: "Invalid Email ID",
  GST_INVALID: "Not a valid (GST) number",
  STATE: "This field is mandatory",
  CITY: "Please enter City",
  TOWN: "Please select town",
  DUPLICATE_VALUE: "Please enter a different value",
  PARTNER_NAME: "Applicant Name is Required",
  PARTNER_PAN: "PAN of Applicant is Required",
  BANK_NAME_MESSAGE: "Bank name is required",
  ACCOUNT_MESSAGE: "A/c number is required",
  CC_LIMIT_MESSAGE: "CC limit is required",
  INVALID_PINCODE: "Please enter valid pincode",
  INVALID_APPLICANT_CODE: "Invalid Applicant Code",
  NAN: "Please enter a Numeric value",
  CAN_NOT_BE_LESS_THAN_CURRENT_DATE: "Date can not be older than current date",
  LAUNCH_DATE_VALIDATION: "Date should be greater than store handover"
};

export const INPUT_MAX_LENGTH = {
  LENGTH_TWO: 2,
  LENGTH_THREE: 3,
  LENGTH_FIVE: 5,
  LENGTH_SIX: 6,
  PARTNER_CODE_MAX_LENGTH: 5,
  LENGTH_NINE: 9,
  LENGTH_TEN: 10,
  LENGTH_ELEVEN: 11,
  LENGTH_TWELVE: 12,
  LENGTH_FIFTEEN: 15,
  LENGTH_SIXTEEN: 16,
  LENGTH_TWENTY: 20,
  LENGTH_TWENTY_FOUR: 24,
  LENGTH_FIFTY: 50,
  LENGTH_SEVENTY_TWO: 72,
  LENGTH_EIGHTY: 80,
  LENGTH_HUNDRED: 100,
  LENGTH_TWO_FIFTY: 250,
  LENGTH_ONE_FIFTY: 150,
};

export const RESPONSIVE = {
  MOBILE_VIEW: 767
};

export const STATUS_KEYS = {
 APPROVED: "APRROVED",
 REJECT: "REJECT",
 UN_ACTION: "UNACTION"
};

export const ROLES_CATEGORY = {
 PARTNER: "PARTNER",
 USER: "USER",
 ADMIN: "ADMIN",
 AUDITOR: "AUDITOR",
 WORKFLOW_MANAGER: "WORKFLOW_MANAGER"
};

export const POLICY_URL = {
  PRIVACY_POLICY: "/bank/docs/Privacy-Policy.docx",
  SUBMISSION_OF_INTENT: "/bank/docs/Submission-of-Intent.docx",
  TERMS_OF_USE: "/bank/docs/Terms-of-Use.docx",
};

export const DASHBOARD_CONFIG = {
  SALES: "SALES",
  STORE: "STORE",
  APPROVED: "APPROVED",
  IN_PROGRESS: "IN_PROGRESS",
  REJECTED: "REJECTED",
  ON_HOLD: "ON_HOLD",
  COURIER_TRACKING: "COURIER_TRACKING",
  MODAL_MESSAGE: "Please contact to your ASM OR ASI",
  INIT: "INIT",
  INIT_STATUS: "Not Started",
  ON_HOLD_STATUS: "Application ON HOLD",
  COURIER_TRACKING_STATUS: "ACTIVE"
}
