import React, {Component, Fragment} from "react";
import Input from "../../Common/Input";
// import {uploadDocResponse} from "./uploadDocMock";
import {AxiosService} from "../../../services/apiService";
import {
 uploadFile
} from "../../../storeManager/common/repository";
import { commonService } from "../../../storeManager/common/services";
// import {checkIfUploadRadioSelected} from "../helper";
import {INPUT_MAX_LENGTH, MESSAGES, STATUS_KEYS} from "../../../constants";
import Error from '../../Common/Error';

import {MODALS} from "../../../storeManager/modal/constants";

class DocumentSoftCopy extends Component {

    constructor(props){
        super(props);
        this.state = {
            isUploading: false,
            isDocReject: false
        }
    }

    componentDidMount() {
        let {partnerDocuments} =this.props;
        if(partnerDocuments && partnerDocuments.uploadedDocuments && partnerDocuments.uploadedDocuments.length){
          let rejectCount = 0;
          partnerDocuments.uploadedDocuments.forEach(obj => {
            if(obj.documentStatus === STATUS_KEYS.APPROVED || obj.documentStatus === STATUS_KEYS.REJECT){
              rejectCount += 1;
            }
          });
          rejectCount > 0 && this.setState({isDocReject : true})
        }
        this.getUploadDocumentTemplate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            documentsSoftCopyForm,
            formIsUpdated,
            setFormIsUpdated,
            setDocumentSoftCopyFormData
        } = this.props;
        if (formIsUpdated) {
            let updatedForm = {...documentsSoftCopyForm};
            let templateArr = updatedForm.uploadDocumentsTemplate.templateArr;
            templateArr.forEach(template => {
                let radioElmArr = template.radioElementArr;
                if (radioElmArr) {
                    radioElmArr.forEach((elm, index) => {
                        let elmId = -1;
                        elm.radioConfig.forEach((config) => {
                            if (radioElmArr.length === 1) {
                                if(template.rightSectionConfig){
                                    if (config.labelConfig.label.toLowerCase().includes('yes') && config.elementConfig.checked) {
                                        template.rightSectionConfig.checked = false;
                                        template.rightSectionConfig.disabled = true;
                                    } else if (config.labelConfig.label.toLowerCase().includes('no') && config.elementConfig.checked) {
                                        template.rightSectionConfig.disabled = false;
                                    }
                                }
                            } else {
                                if (config.labelConfig.label.toLowerCase().includes('no') && config.elementConfig.checked) {
                                    elmId = elm.objId;
                                    if (index === 0) {
                                        radioElmArr[1].visible = false;
                                    } else {
                                        radioElmArr[0].visible = false;
                                    }
                                }
                                if (config.labelConfig.label.toLowerCase().includes('yes') && config.elementConfig.checked) {
                                    elmId = elm.objId;
                                    if (index === 0) {
                                        radioElmArr[1].visible = true;
                                    } else {
                                        radioElmArr[0].visible = true;
                                    }
                                }
                                if (elm.visible && config.labelConfig.label.includes('2') && config.elementConfig.checked) {
                                    template.rightSectionConfig.checked = false;
                                    template.rightSectionConfig.disabled = true;
                                } else {
                                    if (config.labelConfig.label.includes('2') && config.elementConfig.checked) {
                                        template.rightSectionConfig.disabled = false;
                                    }
                                }
                            }
                        });
                        if (elm.visible &&
                            elm.radioConfig[0].elementConfig.checked === false &&
                            elm.radioConfig[1].elementConfig.checked === false) {
                            elm.radioConfig.forEach(config => {
                                if (config.labelConfig.label.includes('2') || config.labelConfig.label.toLowerCase().includes('no')) {
                                    config.elementConfig.checked = true;
                                    template.rightSectionConfig.checked = false;
                                    template.rightSectionConfig.disabled = true;
                                }
                            });
                        }
                    });
                }
            });
            updatedForm.uploadDocumentsTemplate.templateArr = templateArr;
            setDocumentSoftCopyFormData(updatedForm);
            setFormIsUpdated(false);
        }
    }

    getUploadDocumentTemplate = async () => {
        let {
            documentsSoftCopyForm,
            setDocumentSoftCopyFormData,
            uploadedDocumentsRadioValueKeys,
            setFormIsUpdated
        } = this.props;
        let updatedForm = {...documentsSoftCopyForm};
        const {partnerDocuments} = this.props;
        try {
            const response = await commonService.getUploadDocumentTemplateByLOB(this.props.serverPartnerObj.lineOfBusiness.name);
            if (response && response.data.length > 0) {
                let uploadDocumentsArr = response.data;
                let uploadDocuments = [];
                for (let i = 0; i < uploadDocumentsArr.length; i++) {
                    let arrObj = {
                        id: uploadDocumentsArr[i].id,
                        heading: uploadDocumentsArr[i].title,
                        type: uploadDocumentsArr[i].templateType,
                        documentText: uploadDocumentsArr[i].documentText,
                        templateTypeKeys: uploadDocumentsArr[i].templateTypeKeys,
                        rightSection: uploadDocumentsArr[i].rightSection,
                        docList: [],
                        allowedExtensionLabel: uploadDocumentsArr[i].allowedExtensionLabel
                    };
                    let id = uploadDocumentsArr[i].id;
                    if (uploadDocumentsArr[i].templateType === 'BOOLEAN') {
                        let templateArr = uploadDocumentsArr[i].templateTypeKeys;
                        let elementArr = [];
                        for (let i = 0; i < templateArr.length; i++) {
                            let radioConfig = [];
                            for (let j = 0; j < templateArr[i].radioKeys.length; j++) {
                                let checked = false;
                                if (uploadedDocumentsRadioValueKeys.length) {
                                    uploadedDocumentsRadioValueKeys.forEach(radioKey => {
                                        if (radioKey.id === templateArr[i].radioKeys[j].id) {
                                            checked = true;
                                        }
                                    })
                                } else {
                                    if (templateArr[i].radioKeys[j].keyName.toLowerCase().includes('no') || templateArr[i].radioKeys[j].keyName.includes('2')) {
                                        checked = true;
                                    }
                                }
                                radioConfig.push({
                                    labelConfig: {
                                        label: templateArr[i].radioKeys[j].keyName,
                                        classes: ["radio-text"]
                                    },
                                    // elementType: 'text',
                                    elementConfig: {
                                        type: "radio",
                                        value: templateArr[i].radioKeys[j].id,
                                        name: `${templateArr[i].radioKeys[j].id}`,
                                        tabIndex: 0,
                                        checked: checked,
                                        disabled: false
                                    }
                                })
                            }
                            elementArr.push({
                                wrapperClasses: ["form-element radio-btn-group soft-copy-action"],
                                labelConfig: {
                                    label: "",
                                    classes: ["label-title", "pos-rel"]
                                },
                                id: templateArr[i].id,
                                objId: id,
                                valid: true,
                                visible: true,
                                radioConfig: [...radioConfig],
                            })
                        }
                        arrObj['radioElementArr'] = elementArr;
                    }
                    arrObj['buttonConfig'] = {
                        type: 'upload',
                        text: 'Upload',
                        buttonClass: '',
                        isDisabled: uploadDocumentsArr[i].templateType === 'BOOLEAN' && uploadDocumentsArr[i].label !== 'GST'
                    };

                    if (uploadDocumentsArr[i].rightSection) {
                        arrObj['rightSectionConfig'] = {
                            type: uploadDocumentsArr[i].rightSection.rightSectionButton,
                            id: uploadDocumentsArr[i].rightSection.id,
                            checked: false,
                            text: uploadDocumentsArr[i].rightSection.text,
                            templateUrl: uploadDocumentsArr[i].rightSection.templateUrls,
                            disabled: false,
                            hide: uploadDocumentsArr[i].label === 'GST' || false,
                            showText: uploadDocumentsArr[i].label === 'GST' ||false
                        };
                    }
                    uploadDocuments.push(arrObj);
                }
                updatedForm['uploadDocumentsTemplate']['templateArr'] = uploadDocuments;
                if (partnerDocuments && partnerDocuments.uploadedDocuments) {
                    let templateArr = updatedForm.uploadDocumentsTemplate.templateArr;
                    updatedForm.uploadDocumentsTemplate.id = partnerDocuments.id;
                    partnerDocuments.uploadedDocuments.forEach(doc => {
                        templateArr.forEach(templateArrObj => {
                            if (templateArrObj.id === doc.documentTemplate.id) {
                                templateArrObj.documentStatus = doc.documentStatus;
                                if(doc.documentStatus === "REJECT") {
                                    templateArrObj.docList = [];
                                    templateArrObj.comment = doc.comment;
                                } else {
                                    templateArrObj.docList = doc.documents.length > 0 ? [...doc.documents] : [];
                                }
                                if (doc.rightSectionCheckBox) {
                                    // templateArrObj.rightSectionConfig.checked = doc.rightSectionCheckBox.value;
                                    // templateArrObj.rightSectionConfig.id = doc.rightSectionCheckBox.id;
                                    templateArrObj.rightSectionConfig.checked = doc.rightSectionCheckBox;
                                }
                                if (doc.templateTypeValue.length) {
                                    doc.templateTypeValue.forEach(tempVal => {
                                        if (tempVal.radioKey.keyName === 'YES') {
                                            templateArrObj.buttonConfig.isDisabled = false;
                                        }
                                        if (tempVal.radioKey.keyName.includes('2')) {
                                            templateArrObj.rightSectionConfig.checked = false;
                                            templateArrObj.rightSectionConfig.disabled = true;
                                        }
                                        if (tempVal.radioKey.keyName.includes('1')) {
                                            templateArrObj.rightSectionConfig.disabled = false;
                                        }
                                    })
                                }
                            }
                        });

                    })
                }
                setDocumentSoftCopyFormData(updatedForm);
                setFormIsUpdated(true);
            } else {
                this.setState({noData: true});
            }
        } catch {
            this.setState({noData: true});
        }
    };

    selectFileHandler = (e) => {
        e.preventDefault();
        const {uploadDocId, documentsSoftCopyForm, setDocumentSoftCopyFormData, loading} = this.props;
        const updatedForm = {...documentsSoftCopyForm};
        let uploadDocuments = updatedForm.uploadDocumentsTemplate.templateArr;
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append(`file`, file);
        const updatedUploadFile = {...uploadFile};
        updatedUploadFile.url = updatedUploadFile.url + '?id='+uploadDocId;
        updatedUploadFile.params = formData;
        loading(true, MESSAGES.UPLOAD_FILE);
        const api = new AxiosService(updatedUploadFile);
        Promise.all([api.doAjax()]).then(values => {
            loading(false, '');
            let response = values[0];
            if (response && response.data && response.data.data) {
                for (let i = 0; i < uploadDocuments.length; i++) {
                    if (uploadDocId === uploadDocuments[i].id) {
                        uploadDocuments[i]['docList'].push(response.data.data);
                        uploadDocuments[i]['uploadError']= false;

                    }
                }
                updatedForm['uploadDocumentsTemplate']['templateArr'] = uploadDocuments;
                setDocumentSoftCopyFormData(updatedForm);
            } else {
                // todo handle file upload error
                for (let i = 0; i < uploadDocuments.length; i++) {
                    if (uploadDocId === uploadDocuments[i].id) {
                        uploadDocuments[i]['uploadError']= true;
                    }
                }
                updatedForm['uploadDocumentsTemplate']['templateArr'] = uploadDocuments;
                setDocumentSoftCopyFormData(updatedForm);
            }
        }).catch(err => {
            console.log('error in uploading ', err);
            loading(false, '');
        })

    };

    handleUploadClick = (e, id) => {
        e.preventDefault();
        const {setUploadDocId} = this.props;
        setUploadDocId(id);
        document.getElementById(`upload-file${id}`).value ='';
        document.getElementById(`upload-file${id}`).click();
    };

    deleteUploadedDocument = (e, id, index, objId) => {
        e.preventDefault();
        const {documentsSoftCopyForm, setDocumentSoftCopyFormData} = this.props;
        const updatedForm = {...documentsSoftCopyForm};
        let uploadDocuments = updatedForm.uploadDocumentsTemplate.templateArr;
        for (let i = 0; i < uploadDocuments.length; i++) {
            if (objId === uploadDocuments[i].id) {
                uploadDocuments[i].docList.splice(index, 1);
            }
        }
        updatedForm['uploadDocumentsTemplate']['templateArr'] = uploadDocuments;
        setDocumentSoftCopyFormData(updatedForm);
    };

    returnObjToBeAdded = (objKey, /*formType*/) => {
        let {documentsSoftCopyForm} = this.props;
        let updatedObj = documentsSoftCopyForm[objKey][0];
        let bankNameOptions = [];
        if (updatedObj['bankName']) {
            bankNameOptions = updatedObj['bankName'].elementConfig.options;
        }
        let returnObj = {};
        if (objKey === 'bankDetails') {
            returnObj = {
                bankName: {
                    wrapperClasses: ["form-element select-control w-25"],
                    labelConfig: {
                        label: "Bank Name",
                        classes: ["label-title required-symbol"]
                    },
                    elementType: "select",
                    elementConfig: {
                        type: "text",
                        placeholder: "Bank Name",
                        tabIndex: 0,
                        options: [...bankNameOptions],
                        disabled: false
                    },
                    value: "",
                    errorMessage: "",
                    valid: true,
                    touched: false,
                    isOther: false
                },
                otherBank: {
                    wrapperClasses: ["form-element w-25"],
                    labelConfig: {
                        label: "Name",
                        classes: ["label-title required-symbol"]
                    },
                    elementType: "input",
                    elementConfig: {
                        type: "text",
                        placeholder: "Name",
                        maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
                        tabIndex: 0,
                        disabled: false
                    },
                    value: "",
                    errorMessage: "",
                    valid: true,
                    touched: false
                },
                bankAccountNumber: {
                    wrapperClasses: ["form-element w-25"],
                    labelConfig: {
                        label: "Account No.",
                        classes: ["label-title required-symbol"]
                    },
                    elementType: "input",
                    elementConfig: {
                        type: "text",
                        placeholder: "Account No.",
                        maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY_FOUR,
                        tabIndex: 0,
                        disabled: false
                    },
                    value: "",
                    errorMessage: "",
                    valid: true,
                    touched: false
                },
                ccLimit: {
                    wrapperClasses: ["form-element w-25"],
                    labelConfig: {
                        label: "CC Limit",
                        classes: ["label-title"]
                    },
                    elementType: "input",
                    elementConfig: {
                        type: "text",
                        placeholder: "CC Limit",
                        maxLength: INPUT_MAX_LENGTH.LENGTH_NINE,
                        tabIndex: 0,
                        disabled: false
                    },
                    value: "",
                    errorMessage: "",
                    valid: true,
                    touched: false
                },
                buttonConfig: {
                    isButton: true,
                    type: 'Remove',
                    text: '-',
                    buttonClass: 'add-btn mb10 remove'
                }
            }
        } else {
            returnObj = {
                partnerName: {
                    wrapperClasses: ["form-element multiline-error w-37"],
                    labelConfig: {
                        label: "Applicant Name",
                        classes: ["label-title required-symbol"]
                    },
                    elementType: "input",
                    elementConfig: {
                        type: "text",
                        placeholder: "Applicant Name",
                        maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
                        tabIndex: 0,
                        disabled: false
                    },
                    value: "",
                    errorMessage: "",
                    valid: true,
                    touched: false
                },
                partnerPanNumber: {
                    wrapperClasses: ["form-element multiline-error w-37"],
                    labelConfig: {
                        label: "PAN Of Applicant",
                        classes: ["label-title required-symbol"]
                    },
                    elementType: "input",
                    elementConfig: {
                        type: "text",
                        placeholder: "PAN Of Applicant",
                        maxLength: INPUT_MAX_LENGTH.LENGTH_TEN,
                        tabIndex: 0,
                        disabled: false
                    },
                    value: "",
                    errorMessage: "",
                    valid: true,
                    touched: false
                },
                buttonConfig: {
                    isButton: true,
                    type: 'Remove',
                    text: '-',
                    buttonClass: 'add-btn remove'
                }
            }
        }
        return returnObj;
    };

    generateDocument = (e, config) => {
        e.preventDefault();
        if (config.templateUrl.length) {
            window.open(config.templateUrl[0].templateUrl, "_blank");
        } else {
            window.open(config.text, "_blank");

        }
    };

    renderedForm = (documentsSoftCopyFormArr) => {
      const {isDocReject} = this.state;
        const {
            addRemoveObj,
            inputChangedHandler,
            radioChangeHandler,
            arrayFiledChangeHandler,
            formConfig,
            formSubmit,
            termsConditionChangeHandler,
            formErrorMessage
        } = this.props;

        const formType = 'documentsSoftCopyForm';
        let formArr = [];
        for (let i = 0; i < documentsSoftCopyFormArr.length; i++) {
            let formElement = documentsSoftCopyFormArr[i];
            if(isDocReject && formElement.config.elementConfig){
              formElement.config.elementConfig.disabled = true;
            }
            if(formElement.config.isHide){
                //do nothing
            }
            else if (formElement.config.isHeader) {
                if(formElement.config.isHide) {
                    // do nothing
                } else {
                    formArr.push(
                        <div className={`outer-heading pb20 ${formElement.id}`} key={`${formElement.id}${i}`}>
                            <div className="title-2 pb10">{formElement.config.isHeader}</div>
                            <hr className="no-space"/>
                        </div>
                    )
                }
            }   else if(formElement.config.isSubHeader) {
                if(formElement.config.isHide) {
                    // do nothing
                } else {
                    formArr.push(
                            <div className="description pb15">{formElement.config.isSubHeader}</div>
                    )
                }
            }
            else if (formElement.config.isTermsAndCondition) {
                if( formConfig && formConfig.buttonArray && !!formConfig.buttonArray.length){
                formArr.push(
                  <div
                    key={`${formElement.id}${i}`}
                    className="notes align-left mtb20"
                  >
                    <div
                      className={`checkbox-element ${
                        !formElement.config.valid ? "error" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={formElement.config.value}
                        onChange={(e) => {
                          termsConditionChangeHandler(
                            e.target.checked,
                            formType
                          );
                        }}
                      />
                      <span className="checkmark" />
                    </div>
                    <div className="d-inline">
                      <span className="justify-text pl30 pr5">
                        I confirm that the soft copy scanned documents uploaded
                        by me are true and correct copies of the original and I
                        agree to keep the original copies of all documents
                        submitted and provide the same as proof as and when
                        required by Tata Play Limited.
                        <span className="required">*</span>
                      </span>
                    </div>
                    {!formElement.config.valid ? (
                      <span className="invalid-text">
                        {formElement.config.errorMessage}
                      </span>
                    ) : null}
                  </div>
                );
                }
            } else if (Array.isArray(formElement.config)) {
                const formElementArr = formElement.config;
                formElementArr.map((configObj, index) => {
                    let isBankObj = Object.keys(configObj).includes("bankName");
                    let otherObj = {};
                    if(isBankObj && configObj.bankName && configObj.bankName.isOther){
                        otherObj = {...configObj};
                    }else if(isBankObj && configObj.bankName && !configObj.bankName.isOther){
                        let {otherBank, ...currBankObjWithoutOther} = configObj;
                        otherObj ={...currBankObjWithoutOther};
                    }else{
                        otherObj = {...configObj};
                    }

                    let formKeys = Object.keys(otherObj);
                    formArr.push(
                        <div key={`${formElement.id}${index}`} className='bank-partner-form'>
                            {
                                formKeys.map((obj, idx) => {
                                  if(isDocReject && configObj[obj].elementConfig){
                                    configObj[obj].elementConfig.disabled = true
                                  }
                                    return (
                                        <React.Fragment key={`${formElement.id}${index}${idx}`}>
                                            {configObj[obj].elementConfig ? <Input
                                                    labelConfig={configObj[obj].labelConfig}
                                                    elementType={configObj[obj].elementType}
                                                    elementConfig={configObj[obj].elementConfig}
                                                    value={configObj[obj].value}
                                                    valid={configObj[obj].valid}
                                                    errorMessage={configObj[obj].errorMessage}
                                                    touched={configObj[obj].touched}
                                                    wrapperClasses={configObj[obj].wrapperClasses}
                                                    inputGroupClasses={configObj[obj].inputGroupClasses}
                                                    radioConfig={configObj[obj].radioConfig}
                                                    // radioChangeHandler={(event, index) =>
                                                    //     radioChangeHandler(event, formElement.id, index, formType)
                                                    // }
                                                    changed={(event) =>
                                                        arrayFiledChangeHandler(event, formElement.id, formType, index, obj)
                                                    }
                                                /> :
                                                <React.Fragment>
                                                    {configObj[obj].isButton && <button
                                                        type="button"
                                                        className={`button fill mt20 ml10 ${configObj[obj].buttonClass}`}
                                                        disabled={isDocReject}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            addRemoveObj(
                                                                formType,
                                                                formElement.id,
                                                                configObj[obj].type,
                                                                index, "", "", "", this.returnObjToBeAdded(formElement.id, formType)
                                                            );
                                                        }}
                                                    >
                                                        {configObj[obj].type === "More" ? (
                                                            <>
                                                                <i>
                                                                    <svg width="12" height="13" viewBox="0 0 23 24">
                                                                        <path
                                                                            fill="#FFF"
                                                                            fillRule="evenodd"
                                                                            d="M14.414.011H8.681V9.31H.015v5.377h8.666v9.3h5.733v-9.3h8.601V9.31h-8.601V.011z"
                                                                        />
                                                                    </svg>
                                                                </i>
                                                                <div className="text">More</div>
                                                            </>
                                                        ) : (
                                                            <i className="delete-icon">
                                                                <svg width="14" height="18" viewBox="0 0 22 27">
                                                                    <path
                                                                        fill="#FFF"
                                                                        fillRule="evenodd"
                                                                        d="M20.036 9.343v14.572c0 1.701-1.394 3.085-3.108 3.085H5.072c-1.714 0-3.108-1.384-3.108-3.085V9.343A3.086 3.086 0 010 6.478V6.32c0-1.701 1.394-3.085 3.108-3.085h4.235A3.678 3.678 0 0111 0a3.677 3.677 0 013.657 3.235h4.235C20.606 3.235 22 4.619 22 6.32v.158c0 1.3-.815 2.412-1.964 2.865zM3.438 23.915a1.63 1.63 0 001.634 1.622h11.856a1.63 1.63 0 001.634-1.622V9.563H3.438v14.352zM11 1.463a2.209 2.209 0 00-2.168 1.772h4.336A2.208 2.208 0 0011 1.463zm9.526 4.857a1.63 1.63 0 00-1.634-1.622H3.108A1.63 1.63 0 001.474 6.32v.158A1.63 1.63 0 003.108 8.1h15.784a1.63 1.63 0 001.634-1.622V6.32zm-5.575 17.346a.734.734 0 01-.737-.731v-8.236c0-.404.33-.731.737-.731s.737.327.737.731v8.236c0 .404-.33.731-.737.731zm-3.951 0a.734.734 0 01-.737-.731v-8.236c0-.404.33-.731.737-.731s.737.327.737.731v8.236c0 .404-.33.731-.737.731zm-3.951 0a.734.734 0 01-.738-.731v-8.236c0-.404.331-.731.738-.731.406 0 .736.327.736.731v8.236c0 .404-.33.731-.736.731z"
                                                                    />
                                                                </svg>
                                                            </i>
                                                        )}
                                                    </button>}
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>);
                });
            } else if (
                Array.isArray(formElement.config.radioConfig) &&
                formElement.config.withoutInput
            ) {
                formArr.push(
                    <Input
                        key={`${formElement.id}${i}`}
                        labelConfig={formElement.config.labelConfig}
                        wrapperClasses={formElement.config.wrapperClasses}
                        valid={formElement.config.valid}
                        inputGroupClasses={formElement.config.inputGroupClasses}
                        radioConfig={formElement.config.radioConfig}
                        radioChangeHandler={(event, index) =>
                            radioChangeHandler(event, formElement.id, index, formType)
                        }
                    />
                );
            } else if (Array.isArray(formElement.config.radioConfig)) {
                formArr.push(
                    <div className="form-control-group" key={`${formElement.id}${i}`}>
                        <Input
                            labelConfig={formElement.config.labelConfig}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            valid={formElement.config.valid}
                            errorMessage={formElement.config.errorMessage}
                            touched={formElement.config.touched}
                            wrapperClasses={formElement.config.wrapperClasses}
                            inputGroupClasses={formElement.config.inputGroupClasses}
                            radioConfig={formElement.config.radioConfig}
                            radioChangeHandler={(event, index) =>
                                radioChangeHandler(event, formElement.id, index, formType)
                            }
                            changed={(event, index) =>
                                inputChangedHandler(event, formElement.id, formType)
                            }
                        />
                    </div>
                );
            } else if (formElement.config.isTemplate) {
               this.renderTemplate(formElement.config, formArr);
            } else {
                formArr.push(
                    <Input
                        key={`${formElement.id}${i}`}
                        labelConfig={formElement.config.labelConfig}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        valid={formElement.config.valid}
                        errorMessage={formElement.config.errorMessage}
                        touched={formElement.config.touched}
                        wrapperClasses={formElement.config.wrapperClasses}
                        dataListConfig = {formElement.config.dataListConfig}
                        inputGroupClasses={formElement.config.inputGroupClasses}
                        changed={event =>
                            inputChangedHandler(event, formElement.id, formType, i)
                        }
                    />
                );
            }
        }

      {
        formErrorMessage && formArr.push(<Error key={'docSoftCopyFormErr'} error={formErrorMessage} />)
      }

        if (formConfig && formConfig.buttonArray) {
            formConfig.buttonArray.forEach((item,index) => {
              formArr.push(
                <Fragment key={index}>
                  {
                    (item.priority === 'PRIMARY') ?
                      <button
                        className="button fill big w-210 mt10 mb30 mr20"
                        // type="submit"
                        onClick={(e) => formSubmit(e, 'documentsSoftCopyForm',item)}
                      >
                        {item.label}
                      </button>
                      :
                      (item.priority === 'SECONDARY') ?
                      <button
                        className="button action big w-210 mt10 mb30"
                        type="button"
                        onClick={(e) => formSubmit(e, 'documentsSoftCopyForm',item)}
                        // disabled
                      >
                        {item.label}
                      </button>
                      :
                      null
                  }
                </Fragment>)
            })
          }
        return formArr;
    };

    renderTemplate = (formElement, formArr) => {
        let uploadDocumentsArr = formElement.templateArr;
        let {/*isUploadDisabled,*/ uploadRadioHandler, documentSoftCopyCheckHandler, openModal} = this.props;
        let objIndex = 0;
        formArr.push(
            <div className={'upload-document-section'} key={formArr.length}>
                {uploadDocumentsArr && uploadDocumentsArr.map((configObj, index) => {
                    objIndex = index;
                    const docApproved = configObj.documentStatus === STATUS_KEYS.APPROVED ? "table doc-approved" : "table";
                    return (
                        <div className={configObj.docIsRequired || configObj.comment ? 'table doc-error' : docApproved}
                             key={`${index}uploadDocSection`}>
                            <div className="cell">
                                <span className="cell-heading">{configObj.heading}<span className="required">*</span></span>
                                {
                                    configObj.radioElementArr && configObj.radioElementArr.map((radioElmObj, idx) => {
                                        if (radioElmObj.visible) {
                                            return (
                                                <Input
                                                    key={`${radioElmObj.id}uploadDocRadio`}
                                                    labelConfig={radioElmObj.labelConfig}
                                                    elementType={radioElmObj.elementType}
                                                    // value={configObj.elementConfig.value}
                                                    valid={radioElmObj.valid}
                                                    // errorMessage={configObj.elementConfig.errorMessage}
                                                    // touched={configObj.elementConfig.touched}
                                                    isDisabled={configObj.documentStatus === STATUS_KEYS.APPROVED}
                                                    wrapperClasses={radioElmObj.wrapperClasses}
                                                    radioConfig={radioElmObj.radioConfig}
                                                    radioChangeHandler={(event, index) => uploadRadioHandler(event, configObj.id, "uploadDocumentsTemplate", radioElmObj.id, idx)}
                                                />
                                            )
                                        }
                                    })
                                }
                                {
                                    configObj.documentText && (
                                        <ul>
                                            {
                                                configObj.documentText.map((list, index) => {
                                                    return (
                                                        <li key={`${index}uploadDocDocumentText`}>{list.text}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )
                                }

                                {
                                    configObj.docList && (
                                        <ul className="uploaded-doc-list">
                                            {
                                                configObj.docList.map((list, index) => {
                                                    return (
                                                        <li key={`${index}docList`}>{list.name}
                                                        {
                                                            configObj.documentStatus !== STATUS_KEYS.APPROVED && !configObj.buttonConfig.isDisabled && (
                                                                <i className="delete-icon ml10"
                                                                   onClick={(e) => this.deleteUploadedDocument(e, list.id, index, configObj.id)}>
                                                                    <svg width="8" height="8" viewBox="0 0 33 33">
                                                                        <path fill="#e93292" fillRule="evenodd"
                                                                              d="M19.409 16.492L32.382 3.506a2.067 2.067 0 000-2.92 2.061 2.061 0 00-2.918 0L16.491 13.572 3.518.586a2.06 2.06 0 00-2.917 0 2.065 2.065 0 000 2.92l12.973 12.986L.601 29.477a2.067 2.067 0 001.458 3.526c.528 0 1.056-.202 1.459-.605l12.973-12.986 12.973 12.986a2.06 2.06 0 002.918 0 2.068 2.068 0 000-2.921L19.409 16.492z"/>
                                                                    </svg>
                                                                </i>
                                                            )
                                                        }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )
                                }
                                {
                                    configObj.comment && (
                                        <div className="error-text">Document Rejected : {configObj.comment}</div>
                                    )
                                }
                            </div>
                            {/*{configObj.elementConfig &&*/}
                            {/*}*/}
                            <div className="upload-doc-right-section">
                                {configObj.rightSection && configObj.rightSectionConfig.hide && configObj.rightSectionConfig.showText && 
                                (<div className="notes align-left mtb20 ml5">
                                    {MESSAGES.GST_TEXT}
                                    </div>)}
                                {
                                    configObj.rightSection && !configObj.rightSectionConfig.hide &&
                                    <React.Fragment>
                                        {configObj.rightSectionConfig.type === 'CHECKBOX' ?
                                            <div className="notes align-left mtb20 ml5">
                                                <div className="checkbox-element">
                                                    <input type="checkbox"
                                                           disabled={configObj.rightSectionConfig.disabled || configObj.documentStatus === STATUS_KEYS.APPROVED}
                                                           checked={configObj.rightSectionConfig.checked}
                                                           value={configObj.rightSectionConfig.id}
                                                           onChange={(e) => documentSoftCopyCheckHandler(
                                                               e, configObj.id, "uploadDocumentsTemplate",
                                                               configObj.rightSectionConfig.id
                                                           )}
                                                    />
                                                    <span className="checkmark"/>
                                                </div>
                                                {
                                                    configObj.rightSectionConfig.templateUrl &&
                                                    configObj.rightSectionConfig.templateUrl.length ? (
                                                        <span
                                                            className="pl25 link-blue"
                                                            onClick={()=>{
                                                              openModal(MODALS.FILE_VIEWER_POPUP, {
                                                                customModalContentClass: "file-viewer-modal",
                                                                url: configObj.rightSectionConfig.templateUrl[0].templateUrl,
                                                                urlType: configObj.rightSectionConfig.templateUrl[0].templateExtension,
                                                              });
                                                            }}
                                                        >
                                                            {configObj.rightSectionConfig.text}
                                                        </span>
                                                    ) : <span className="pl25">{configObj.rightSectionConfig.text}</span>
                                                }

                                            </div>: <Fragment />
                                        }
                                      {/*<a
                                          href={configObj.rightSectionConfig.templateUrl[0].templateUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="pl25"
                                      >
                                        {configObj.rightSectionConfig.text}
                                      </a>*/}
                                      {/*<button type="button" className={'button action m10'}
                                              disabled={configObj.documentStatus === STATUS_KEYS.APPROVED}
                                              onClick={(e)=>this.generateDocument(e,configObj.rightSectionConfig)}>
                                        GENERATE
                                      </button>*/}
                                    </React.Fragment>
                                }
                                <div className="upload-button" key={`${configObj.id}uploadInput`}>
                                    <input id={`upload-file${configObj.id}`} className="input-hidden"
                                           title="Click to Upload File"
                                           type="file"
                                           name="upload"
                                           accept={configObj.allowedExtensionLabel}
                                           disabled={
                                           configObj.buttonConfig.isDisabled ||
                                           configObj.documentStatus === STATUS_KEYS.APPROVED
                                           }
                                           onChange={(e) => this.selectFileHandler(e)}
                                    />
                                </div>
                                <button className={'button fill m10'}
                                        type="button"
                                        disabled={configObj.buttonConfig.isDisabled || configObj.documentStatus === STATUS_KEYS.APPROVED}
                                        onClick={(e) => this.handleUploadClick(e, configObj.id)}
                                        key={`${configObj.id}uploadButton`}>
                                    {configObj.buttonConfig.text}
                                </button>
                                {configObj.uploadError && <div className="error-text">Error in uploading</div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    };

    render() {
        const {documentsSoftCopyForm, formConfig, /*formSubmit, formErrorMessage*/ } = this.props;
        const documentsSoftCopyFormArr = [];
        const documentsSoftCopyFormKeys = Object.keys(documentsSoftCopyForm);
        documentsSoftCopyFormKeys.forEach(key => {
            documentsSoftCopyFormArr.push({
                id: key,
                config: documentsSoftCopyForm[key]
            });
        });
        const renderedForm = this.renderedForm(documentsSoftCopyFormArr);

        return (
            <div>
                <div className="pb25">
                    <div className="title-1 pr10">
                        Documents
                    </div>

                    <div className="description pb25">
                        To be uploaded by applicant after ASI / ASM visit
                    </div>
                </div>
                <form>
                    <fieldset disabled={!formConfig.editable} className={`${!formConfig.editable ? "application-form-disabled":''}`}>
                    {renderedForm}
                    {/* <Error error={formErrorMessage} /> */}

                    {/* <div>
                        <button
                            className="button fill big w-210 mt10 mb30 mr20"
                            type="submit"
                        >
                            SUBMIT YOUR REQUEST
                        </button>

                        <button
                            className="button action big w-210 mt10 mb30"
                            type="button"
                            disabled
                        >
                            SAVE AS DRAFT
                        </button>
                    </div> */}
                    </fieldset>
                </form>
            </div>
        )
    }
}

export default DocumentSoftCopy;
