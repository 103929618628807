import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './modal.scss';
import {MODALS} from "../../../storeManager/modal/constants";
import FileViewerPopup from "./../FileViewerPopup";
import ConfirmationPopup from "./../ConfirmationPopup";
import SelectViewModal from './../../ListingDetail/RightSection/SelectViewModal'
import CommentableViewModal from './../../ListingDetail/RightSection/CommentableViewModal'
import PartnerCreationModal from './../../ListingDetail/RightSection/PartnerCreationModal'
import ReassignModal from './../../ListingDetail/RightSection/ReassignModal'
import ApplicationCodeViewModal from './../../ListingDetail/RightSection/ApplicationCodeViewModal'
import DocumentViewModal from './../../ListingDetail/RightSection/DocumentViewModal'
import {closeModal} from '../../../storeManager/modal/actions'
import VerifyEvdDetailsModal from "../../ApplicationForm/verifyEvdDetails/VerifyEvdDetailsModal";
import NotificationViewModal from '../../Common/Header/Profile/NotificationViewModal'
// import {notificationListApi} from "../../../storeManager/common/repository";

const mapStateToProps = state => ({
  modal: state.modalReducer,
});

const mapDispatchToProps = dispatch => ({
  closePopup: bindActionCreators(closeModal, dispatch),
});

class Modal extends Component {
  showContent = () => {
    const { closePopup, modal: { modalName, modalParameters } } = this.props;
    if (modalName) {
      switch (modalName) {
        case MODALS.FILE_VIEWER_POPUP:
          return <FileViewerPopup closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.CONFIRMATION_POPUP:
          return <ConfirmationPopup closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.APPROVE_DOCUMENT_MODAL:
          return <DocumentViewModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.VERIFY_EVD_DETAILS_MODAL:
          return <VerifyEvdDetailsModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.SELECT_MODAL:
          return <SelectViewModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.COMMENTABLE_MODAL:
          return <CommentableViewModal closePopup={closePopup} modalParameters={modalParameters}/>;
          case MODALS.REOPEN_ONHOLD_MODAL:
          return <CommentableViewModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.ASSIGN_TO_ME_MODAL:
          return <CommentableViewModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.APPLICATION_CODE_MODAL:
          return <ApplicationCodeViewModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.NOTIFICATION_MODAL:
          return <NotificationViewModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.REASSIGN_MODAL:
          return <ReassignModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.PARTNER_FORM_CREATION_MODAL:
          return <PartnerCreationModal closePopup={closePopup} modalParameters={modalParameters} />;
        default:
          return null
      }
    }
  };

  render() {
    const { modal: { showModal, modalParameters }, closePopup } = this.props;
    const content = this.showContent();
    return (
      <Fragment>
        {
          showModal ? (
              <div
                  className={
                    `custom-modal show ${modalParameters.customModalClass ? modalParameters.customModalClass : ''}`
                  }
              >
                <div className="modal-dialog">
                  <div
                      className={
                        `custom-modal-content ${modalParameters.customModalContentClass ? modalParameters.customModalContentClass : ''}`
                      }
                  >
                    {content}
                    {
                      modalParameters.hideCloseButton ? null : (
                          <i className="close-icon" onClick={() => closePopup()}>
                            <svg width="13" height="12" viewBox="0 0 25 24">
                              <title>Close</title>
                              <path fillRule="evenodd" d="M15.106 11.994L24.55 2.55A1.501 1.501 0 1022.426.426L12.982 9.87 3.538.426A1.501 1.501 0 101.414 2.55l9.444 9.444-9.444 9.444a1.501 1.501 0 102.124 2.124l9.444-9.444 9.444 9.444a1.499 1.499 0 002.124 0 1.501 1.501 0 000-2.124l-9.444-9.444z"/>
                            </svg>
                          </i>
                      )
                    }
                  </div>
                </div>
              </div>
          ) : null
        }
      </Fragment>
    )
  }
}

Modal.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
