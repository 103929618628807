import { format, getTime, differenceInCalendarDays } from 'date-fns';

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

export const isPartner = () => {
  const isPartner = localStorage.getItem("isPartner");
  const partnerObj = isPartner && JSON.parse(isPartner);
  return !!(partnerObj && partnerObj.isPartner);
};

export const getCheckedListNCountObj = (array, value) => {
  const updatedDataList = [...array];
  let checkedCount = 0;
  updatedDataList.forEach(currentObj => {
    if (+currentObj.subscriberId === +value) {
      currentObj.isChecked = !currentObj.isChecked;
    }
    if (currentObj.isChecked) {
      checkedCount += 1;
    }
  });
  return {
    updatedDataList, checkedCount
  }
};



export const getDays = (utcTime) => {
  return differenceInCalendarDays(new Date(), new Date(utcTime) ); 
}

export const dateFormatter = (utcTime, reqFormat='dd MMM yyyy') => {
  return format(new Date(utcTime), reqFormat); //09 Dec 2019
};

export const dateTimeFormatter = (utcTime) => {
  return format(new Date(utcTime), 'dd MMM yyyy, hh:mm a'); //09 Dec 2019, 10:09 AM
};

export const getTimeStamp = (date) => {
  return getTime(new Date(date));
};

export const getDateFormatYYYYMMDD = (date) => {
  /*const dateObj = new Date(date);
  const d = dateObj.getDate();
  const m = dateObj.getMonth() + 1;
  const y = dateObj.getFullYear();
  return y +'-'+ m +'-' + d*/
    return format(new Date(date), 'yyyy-MM-dd'); //2020-01-01
};

export const getCategoryWiseData = (array, categoryKey) => {
  const categoryObj = {};
  array.forEach((elem) => {
    const currentCategory = elem[categoryKey];
    if (!categoryObj.hasOwnProperty(currentCategory)) {
      categoryObj[currentCategory] = [];
    }
    categoryObj[currentCategory].push(elem);
  });
  return categoryObj;
};

export const restrictDecimalInput = (e) => {
  if (e.charCode === 46) e.preventDefault();
};

export const sortBy = (array, key, order, type) => {
  let sortedArray = [...array];
  if(type === "number") {
    sortedArray.sort((a, b) => (order === "dsc") ? b[key]-a[key] : a[key]- b[key]);
  } else {
    sortedArray.sort((a, b) => {
      if (order === "dsc") {
        return a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0;
      } else {
        return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
      }
    });
  }
  return sortedArray;
};

export const sortByObjValue = (array, key, order) => {
  let sortedArray = [...array];
  sortedArray.sort((a, b) => {
    if (order === "dsc") {
      return (a[key].props.children > b[key].props.children) ? -1 : (a[key].props.children < b[key].props.children) ? 1 : 0;
    } else {
      return (a[key].props.children < b[key].props.children) ? -1 : (a[key].props.children > b[key].props.children) ? 1 : 0;
    }
  });
  return sortedArray;
};

export const getSearchResult = (array, key, value) => {
  let count = value.length <= 3 ? 1 : 0;
  array.forEach(obj=>{
    if(value.length > 3) {
      obj.isVisible = obj[key].includes(value);
      obj.isVisible && (count++);
    } else {
      obj.isVisible = true
    }
  });
  return {data: array, noDataFound: !count };
};


export const scrollToTop = (top,left) =>{
  window.scroll({
    top: top||0, 
    left: left||0, 
    behavior: 'smooth'
  });
}

export const numberFormatter = value => {
  return parseInt(value) ? parseInt(value).toLocaleString("en-IN") : value;
};