import React, { Component } from 'react';

import './style.scss';

class CheckBox extends Component {
  onChange=(e)=>{
    let { name, onChange, id } = this.props,
      {value, checked} = e.target;
    onChange && onChange(name, value, checked, id);
  };
  render() {
    const {id, isChecked, disabled, title, name, className, labelText, value, ...other} = this.props;
    return (
      <div className="checkbox">
        <input {...other} type="checkbox" disabled={disabled}
                name={name} title={title || labelText} checked={isChecked} id={id} onChange={this.onChange} className={className} value={value} />
        <label>
          <i className="icon-check" />
        </label>
        <span className="checkbox-text">{labelText}</span>
      </div>
    );
  }
}

export default CheckBox;