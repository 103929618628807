import React, { Component, Fragment } from 'react';
import '../InputBox/style.scss';
import './style.scss';

class SelectBox extends Component {
  handleChange = (event) => {
    let { name, onChange } = this.props;
    let { value } = event.target;
    onChange && onChange(name,value);
  }

  render() {
    let {labelName, options = [], defaultValue = 'Select', value, errorsMessage } = this.props;
    return (
      <Fragment>
        {labelName && <label>{labelName}</label>}
        <div className={`form-group form-select-control ${errorsMessage && 'error'}`}>
          <select className="form-control" onChange={this.handleChange} value={value}>
              {defaultValue && <option value="">{defaultValue}</option>}
              {options && options.map((option, index) =>
                <option value={option.id} key={index}>{option.value}</option>
                )
              }
          </select>
          {errorsMessage && <label className={`${errorsMessage && 'error-message'}`}>{errorsMessage}</label>}
        </div>
      </Fragment>
    );
  }
}

export default SelectBox;