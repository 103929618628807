import React, { Component } from 'react';
import { TEXT, FORM_VISIBILITY_HEADING } from '../constant';
import InputBox from '../InputBox';
import RadioButton from '../RadioButton';
import {MODALS} from "../../../storeManager/modal/constants";
import Table from '../Table';
import SelectBox from '../SelectBox';
import { fetchFormVisibilityData, getForm } from '../../../storeManager/common/repository';
import { AxiosService } from '../../../services/apiService';
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {scrollToTop} from '../../.././helper';
import Loader from '../../../components/Loader';
import FormValidator from '../../../services/FormValidator';

class FormVisibility extends Component {

    constructor(props){
        super(props);
        this.state = {
            formName: '',
            isEdit: false,
            formTypeList :[],
            formType: "",
            editable: true,
            enable: true,
            isLoading: true,
            enableObj : {
                Yes: true,
                No: false
            },
            errorObj : {
                formNameError : "",
                formTypeError : ""
            }
        }
    }

    componentDidMount(){
    this.loadHandler()
    }

    loadHandler = async () => {
        const getFormTypeApi = new AxiosService(getForm);
        const formVisibilityApi = new AxiosService(fetchFormVisibilityData);
        let formData = [];
        Promise.all([
            getFormTypeApi.doAjax(),
            formVisibilityApi.doAjax()
        ])
        .then(res => {
          const [
            {data : {data : formTypeList, status: status1} },
            {data : {data : formVisibilityData, status: status2} }
          ] = res;
        if(status1 && status2) {
            formVisibilityData.forEach(item=>{
                if(item.enable){
                    item.enable = 'Yes'
                }else{
                    item.enable = 'No'
                }
                if(item.editable){
                    item.editable = 'Yes'
                }else{
                    item.editable = 'No'
                }
                formData.push(item);
            })
            this.setState({
                formTypeList: formTypeList.map(type => { return {'id':type, 'value':type}}),
                formVisibilityData,
                isLoading: false
            })
        }
        else{
            this.setState({isLoading: false})
        }
        })
        .catch( err => {
            this.setState({isLoading: false})
            console.log('Error in api call', err);
        })
    };

    handleChange = (name,value) => {
        this.setState({
            [name]: value,
        })
    };

    handleRadioButton = (name, value, id) => {
        this.setState({
            [name]: id,
        })
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const {...validatePayload} = this.state;
        if(this.validateForm(validatePayload)){
        this.setState({isLoading: true});
            try{
                const { enable, editable, formName, formType, id } = this.state;
                const payload = {
                    id: id || null,
                    enable,
                    editable,
                    formName,
                    formType,
                }
                const response = await commonService.createFormVisibility(payload);
                const responseData = response.data;
                const { openModal } = this.props;
                if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE){
                    this.setState({
                    isLoading: false
                    });
                    openModal(MODALS.CONFIRMATION_POPUP, {
                        message: responseData.message
                    });
                    this.resetForm()
                } else {
                    this.setState({
                        error: responseData.message,
                        isLoading: false
                    })
                }
            }
            catch (e) {
                console.log('error occured on admin state =', e);
                this.setState({
                error: GlobalConfig.serverError,
                isLoading: false,
                })
            }
        }
    }

    resetForm = () => {
        this.setState({
            enable: true,
            editable: true,
            formName:"",
            formType:"",
            isEdit: false,
            errorObj : {
                formNameError : "",
                formTypeError : ""
            }
        });
        this.loadHandler()
    }

    handleClick = async (id) => {
        const response = await commonService.getFormDetail(id);
        if(response){
            const { enable, editable, formName, formType  } = response.data.data;
            this.setState({
                id,
                enable,
                editable,
                formName,
                formType,
                isEdit: true,
            });
            scrollToTop();
        }
    }

    validateForm = (payload) =>{
        let { errorObj } = this.state;
        const formStatus = FormValidator.validateAdminFormVisibility(payload);
        if(formStatus.hasErrors){
          errorObj = formStatus;
          this.setState({
            errorObj
          });
        }
        else{
          return true;
        }
      }

  render() {
      const { isLoading, formName, isEdit, formTypeList, formType, enableObj,
            formVisibilityData, editable, enable, errorObj } = this.state;
      return(
        <div className="form-visibility">
            {isLoading && <Loader />}
            <h2 className="heading mb-15">{TEXT.FORM_VISIBILITY}</h2>
            <div className="sub-heading">{TEXT.FORM_VISIBILITY_SUBTITLE}</div>
            <div className="content-form no-gutters">
                <form className="col-lg-8 no-gutters">
                    <h3 className="content-title">{`${isEdit ? 'Update' : 'Create'} Form Visibility`}</h3>
                    <div className="row">
                        <div className="form-name col-lg-6">
                            <InputBox
                                labelName='Form Name'
                                name = 'formName'
                                className = 'form-control'
                                type = "text"
                                value = {formName}
                                onChange = {this.handleChange}
                                errorsMessage = {errorObj.formNameError || ""}
                            />
                        </div>
                        <div className="form-type-section col-lg-6">
                            <SelectBox
                                labelName = "Form Type"
                                options = { formTypeList }
                                className = 'form-control'
                                onChange = { this.handleChange }
                                name = 'formType'
                                value = { formType }
                                errorsMessage = {errorObj.formTypeError || ""}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="enable-section col-lg-6">
                        <label>Enable</label>
                            <RadioButton
                                name="enable"
                                id={enableObj.Yes}
                                onChange={this.handleRadioButton}
                                isChecked = { enable }
                                value = 'Yes'
                            />
                            <RadioButton
                                name="enable"
                                id={enableObj.No}
                                onChange={this.handleRadioButton}
                                isChecked = { !enable }
                                value = 'No'
                            />
                        </div>
                        <div className="editable-section col-lg-6">
                        <label>Editable</label>
                            <RadioButton
                                name="editable"
                                id={enableObj.Yes}
                                onChange={this.handleRadioButton}
                                isChecked = { editable }
                                value = 'Yes'
                            />
                            <RadioButton
                                name="editable"
                                id={enableObj.No}
                                onChange={this.handleRadioButton}
                                isChecked = { !editable }
                                value = 'No'
                            />
                        </div>
                    </div>
                    <div>
                        <button className="button fill big w-210 mt10 mb30 mr20" onClick={this.handleSubmit}>{`${isEdit ? 'UPDATE' : 'CREATE'}`}</button>
                    </div>
                </form>
            </div>
            <div className="table-container mt45">
                <h3>Form Visibility {formVisibilityData && `(${formVisibilityData.length})`}</h3>
                <div className="table-data-list">
                <Table showHead headings={FORM_VISIBILITY_HEADING} rows={formVisibilityData} handleClick={this.handleClick} />
                </div>
            </div>
            {/*<Modal/>*/}
        </div>
      )
  }
}

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
  });

export default (connect(null, mapDispatchToProps)(FormVisibility))
