import { INPUT_ERROR, INPUT_MAX_LENGTH } from './../../../constants'
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,15}$/;

const validateConfirmPassword = (confirmPassword, password, keyName) => {
  let name = '';
  let message = '';
  let error = false;
  if (!password) {
    return {
      message: INPUT_ERROR.REQUIRED,
      error: true,
    }
  } else if (password.length < 8) {
    message = INPUT_ERROR.PASSWORD;
    error = true;
  } /*else if (password.length >= 8 && password.length <= confirmPassword.length) {
    message = '';
    error = false;
  }*/ else if (password !== confirmPassword) {
    name = keyName;
    message = INPUT_ERROR.CONFIRM_PASSWORD;
    error = true
  } else if (password === confirmPassword) {
    name = keyName;
    message = '';
    error = false
  }
  return { message, error, name };
};

export default {

  validateName: (inputName, isRequired = true) => {
    const maxLength = INPUT_MAX_LENGTH.LENGTH_FIFTY;
    let message = '';
    let error = false;
    let name = inputName;
    name = name.trim();
    if (isRequired) {
      if (!name) {
        message = INPUT_ERROR.REQUIRED;
        error = true;
      } else if (name.length > maxLength) {
        message = `Enter max ${maxLength} characters`;
        error = true;
      } else {
        message = '';
      }
    }

    return { message, error };
  },

  validateFirmName: (inputName) => {

    const isNotAllowedName = (name) => {
      const nameArr = name.split(' ');
      let error = false;
      if (nameArr) {
        for (let i = 0; i < nameArr.length; i++) {
          const curString = nameArr[i].toLowerCase();
          if (curString === 'tata' || curString === 'sky' || curString === 'play') {
            error = true;
            break;
          }
        }
      }
      return error;
    };

    const maxLength = INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY;
    let message = '';
    let error = false;
    let name = inputName;
    name = name.trim();
    if (!name) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (isNotAllowedName(name)) {
      message = 'This name is not allowed';
      error = true;
    } else if (name.length > maxLength) {
      message = `Enter max ${maxLength} characters`;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateTextarea: (inputTextarea) => {
    let message = '';
    let error = false;
    const maxLength = INPUT_MAX_LENGTH.LENGTH_TWO_FIFTY;
    let textarea = inputTextarea;
    textarea = textarea.trim();
    if (!textarea) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (textarea.length > maxLength) {
      message = `Enter max ${maxLength} characters`;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateEmail: (emailId) => {
    const maxLength = 50;
    let message = '';
    let error = false;
    const regexEmail = /^\w+([-.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    if (!emailId) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!regexEmail.test(String(emailId).toLowerCase())) {
      message = INPUT_ERROR.EMAIL;
      error = true;
    } else if (emailId.length > maxLength) {
      message = `Maximum Length should be ${maxLength}`;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validatePhoneNumber: (phoneNumber, isRequired = true) => {
    let message = '';
    let error = false;
    if (isRequired) {
      if (!phoneNumber) {
        message = INPUT_ERROR.REQUIRED;
        error = true;
      } else if (!/^(?:[6-9]|0[1-9]|10)\d{9}$/.test(phoneNumber)) {
        message = INPUT_ERROR.MOBILE_NUMBER;
        error = true;
      }
    }

    return { message, error };
  },

  validatePassword: (password, confirmPassword, keyName) => {
    let message = '';
    let error = false;
    if (confirmPassword) {
      return validateConfirmPassword(confirmPassword, password, keyName)
    } else {
      if (!password) {
        return {
          message: INPUT_ERROR.REQUIRED,
          error: true,
        }
      } else if (password.length < 8) {
        return {
          message: INPUT_ERROR.PASSWORD,
          error: true,
        }
      } else return { message, error };
    }
  },

  validateConfirmPassword: (confirmPassword, password) => {
    let message = '';
    let error = false;
    if (!confirmPassword) {
      return {
        message: INPUT_ERROR.REQUIRED,
        error: true
      }
    } else if (password !== confirmPassword) {
      return {
        message: INPUT_ERROR.CONFIRM_PASSWORD,
        error: true
      }
    } else return { message, error };
  },

  validateLoginOtp: (otp) => {
    const maxLength = 6;
    let message = '';
    let error = false;
    if (!otp) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!/^[0-9]+$/.test(otp)) {
      message = 'Not a valid otp';
      error = true;
    } else if (otp.length < maxLength) {
      message = `Minimum Length should be ${maxLength}`;
      error = true;
    } else if (otp.length > maxLength) {
      message = `Maximum Length should be ${maxLength}`;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateEstablishmentYear: (establishmentYear) => {
    let message = '';
    let error = false;
    if (!establishmentYear) {
      message = 'Please select establishment year';
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateDropdown: (dropdownValue) => {
    const errorObj = { error: false, message: "" };
    let selectValue = dropdownValue.value.value;

    if (!selectValue) {
      errorObj.error = true;
      errorObj.message = INPUT_ERROR.REQUIRED;
    }
    return errorObj;
  },

  validateState: (state, isRequired = true) => {
    let message = '';
    let error = false;
    if (isRequired) {
      if (!state) {
        message = INPUT_ERROR.STATE;
        error = true;
      } else {
        message = '';
      }
    }

    return { message, error };
  },

  validateCity: (city, isRequired = true) => {
    let message = '';
    let error = false;
    if (isRequired) {
      if (!city) {
        message = INPUT_ERROR.CITY;
        error = true;
      } else {
        message = '';
      }
    }

    return { message, error };
  },

  validateTown: (town, isRequired = true) => {
    let message = '';
    let error = false;
    if (isRequired) {
      if (!town) {
        message = INPUT_ERROR.TOWN;
        error = true;
      } else {
        message = '';
      }
    }

    return { message, error };
  },

  validateAddress: (address, isRequired = true) => {
    const maxLength = 150;
    let message = '';
    let error = false;
    if (isRequired) {
      if (!address) {
        message = INPUT_ERROR.REQUIRED;
        error = true;
      } else if (address.length > maxLength) {
        message = `Maximum Length should be ${maxLength}`;
        error = true;
      } else {
        message = '';
      }
    }


    return { error, message };
  },

  validateTermsAndCondition: (termsAndCondition, noTermValidate) => {
    let message = '';
    let error = false;
    if (noTermValidate) {
      return { error, message };
    }
    if (!termsAndCondition) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    }
    return { error, message };
  },

  validatePinCode: (pinCode, pincodeArray, isRequired = true) => {
    const maxLength = 6;
    let message = '';
    let error = false;
    if (isRequired) {
      if (!pinCode) {
        message = INPUT_ERROR.REQUIRED;
        error = true;
      } else if (pinCode.charAt(0) === '0') {
        message = 'Not a valid pin code';
        error = true;
      } else if (pinCode.length < maxLength) {
        message = `Minimum Length should be ${maxLength}`;
        error = true;
      } else if (pinCode.length > maxLength) {
        message = `Maximum Length should be ${maxLength}`;
        error = true;
        // } else if(!pincodeArray.includes(pinCode)){
        //   message = INPUT_ERROR.INVALID_PINCODE;
        //   error = true;
      } else {
        message = '';
      }
    }

    return { error, message };
  },
  validatePanNumber: (panNumber) => {
    const maxLength = 10;
    let message = '';
    let error = false;
    if (!panNumber) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(panNumber)) {
      message = 'Invalid PAN';
      error = true;
    } else if (panNumber.length < maxLength) {
      message = `Minimum Length should be ${maxLength}`;
      error = true;
    } else {
      message = '';
    }
    return { error, message };
  },
  validateGstIntent: (gst) => {
    if (gst.isGst) {
      const maxLength = 15;
      let message = '';
      let error = false;
      let gstNo = gst.value;
      gstNo = gstNo.toString().trim();
      const regexGst = /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/i;
      if (gstNo) {
        if (!regexGst.test(String(gstNo).toLowerCase())) {
          message = 'Invalid GST Number';
          error = true;
        } else if (gstNo.length > maxLength) {
          message = `Enter max ${maxLength} characters`;
          error = true;
        }
      } else {
        message = INPUT_ERROR.REQUIRED;
        error = true;
      }
      // console.log('error, message', error, message)
      return { error, message };
    } else {
      return { error: false, message: "" };
    }
  },

  validateExistingRelationship: (existingRelationship) => {
    let valueArray = [];
    let finalError = false;
    const errorArr = [];
    const relationShipArr = existingRelationship.value;
    for (let i = 0; i < relationShipArr.length; i++) {
      const erroObj = { error: false, message: "" };
      let selectValue = relationShipArr[i].selectConfig.elementConfig.value;

      if (existingRelationship.isRequired && selectValue && valueArray.includes(selectValue)) {
        finalError = true;
        erroObj.error = true;
        erroObj.message = INPUT_ERROR.DUPLICATE_VALUE;
      }
      else if (existingRelationship.isRequired && (!selectValue || !relationShipArr[i].value)) {
        finalError = true;
        erroObj.error = true;
        erroObj.message = INPUT_ERROR.REQUIRED;
      }
      else if (existingRelationship.isRequired && relationShipArr[i].value && relationShipArr[i].value.length !== INPUT_MAX_LENGTH.PARTNER_CODE_MAX_LENGTH) {
        finalError = true;
        erroObj.error = true;
        erroObj.message = INPUT_ERROR.INVALID_APPLICANT_CODE;
      }
      else {
        erroObj.error = false;
        erroObj.message = "";
      }

      if (selectValue) {
        valueArray.push(selectValue);
      }

      errorArr.push(erroObj);
    }
    return { error: finalError, errorArr };
  },

  validateCurrentBusinessAssociation: (currentBusiness) => {
    let valueArray = [];
    let finalError = false;
    const errorArr = [];
    const currentBusinessfigArr = currentBusiness.value;
    for (let i = 0; i < currentBusinessfigArr.length; i++) {
      const erroObj = { error: false, message: "" };

      let selectValue = currentBusinessfigArr[i].selectConfig.elementConfig.value;

      if (selectValue && valueArray.includes(selectValue)) {
        finalError = true;
        erroObj.error = true;
        erroObj.message = INPUT_ERROR.DUPLICATE_VALUE;
      }
      else if ((selectValue && !currentBusinessfigArr[i].value) || ((!selectValue && currentBusinessfigArr[i].value))) {
        finalError = true;
        erroObj.error = true;
        erroObj.message = INPUT_ERROR.REQUIRED;
      }

      // else if (!selectValue || !currentBusinessfigArr[i].value) {
      //   finalError = true;
      //   erroObj.error = true;
      //   erroObj.message = INPUT_ERROR.REQUIRED;
      // }

      if (selectValue) {
        valueArray.push(selectValue);
      }

      errorArr.push(erroObj);
    }
    return { error: finalError, errorArr };
  },


  /* ----------  Application Evaluation Form Validation -------------*/
  validateShopArea: (shopArea) => {
    let message = '';
    let error = false;
    if (!shopArea) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateDeliveryVehicle: (deliveryVehicle) => {
    let message = '';
    let error = false;
    if (!deliveryVehicle) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateManpowerCapacity: (manpowerCapacity) => {
    let message = '';
    let error = false;
    if (!manpowerCapacity) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validatePartnerType: (partnerType) => {
    let message = '';
    let error = false;
    if (!partnerType) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateTerritoryCovered: (territoryCovered) => {
    let message = '';
    let error = false;
    if (!territoryCovered) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateExpectedTurnover: (expectedTurnover) => {
    let message = '';
    let error = false;
    if (!expectedTurnover) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateCurrentBusinessAssociationEval: (currentBusiness) => {
    let valueArray = [];
    let finalError = false;
    const errorArr = [];
    const currentBusinessfigArr = currentBusiness.value;
    for (let i = 0; i < currentBusinessfigArr.length; i++) {
      const erroObj = { error: false, message: "" };


      let selectValue = currentBusinessfigArr[i].curAssoc.selectConfig.elementConfig.value;
      if (selectValue && valueArray.includes(selectValue.toString())) {
        finalError = true;
        erroObj.error = true;
        erroObj.message = INPUT_ERROR.DUPLICATE_VALUE;
      }
      else if ((selectValue && !currentBusinessfigArr[i].curAssoc.value) || ((!selectValue && currentBusinessfigArr[i].curAssoc.value))) {
        finalError = true;
        erroObj.error = true;
        erroObj.message = INPUT_ERROR.REQUIRED;
      }
      // else if (!selectValue || !currentBusinessfigArr[i].curAssoc.value) {
      //     finalError = true;
      //     erroObj.error = true;
      //     erroObj.message = INPUT_ERROR.REQUIRED;
      // }

      if (selectValue) {
        valueArray.push(selectValue.toString());
      }

      errorArr.push(erroObj);
    }
    return { error: finalError, errorArr };
  },

  validateBankDetailsEval: (bankDetails) => {
    // let valueArray = [];
    let finalError = false;
    const errorArr = [];
    const bankDetailsArr = bankDetails.value;
    for (let i = 0; i < bankDetailsArr.length; i++) {
      const erroObj = {
        // bank:{error:'', message:''},
        // otherBank:{error:'', message:''}
      };
      let selectValue = bankDetailsArr[i].bank.value;
      let isOthers = bankDetailsArr[i].bank.isOther;

      // if(selectValue && valueArray.includes(selectValue)){
      //   finalError = true;
      //   erroObj.error = true;
      //   erroObj.message = "Duplicate value";
      // } else
      if (!selectValue) {
        finalError = true;
        // erroObj.error = true;
        // erroObj.message = INPUT_ERROR.REQUIRED;
        erroObj.bank = {
          error: true,
          message: INPUT_ERROR.REQUIRED,
        }
      }
      if (isOthers && !bankDetailsArr[i].otherBank.value) {
        finalError = true;
        erroObj.otherBank = {
          error: true,
          message: INPUT_ERROR.REQUIRED,
        }
      }

      // if(selectValue){
      //   valueArray.push(selectValue);
      // }

      errorArr.push(erroObj);
    }
    return { error: finalError, errorArr };
  },


  /* ----------  Application hard Copy Validation -------------*/

  validateTrackId: (trackId) => {
    const maxLength = 30;
    let message = '';
    let error = false;
    if (!trackId) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (trackId.length > maxLength) {
      message = `Enter max ${maxLength} characters`;
      error = true;
    } else {
      message = '';
    }
    return { error, message };
  },

  validateHardCopyUpload: (hardCopyUpload) => {
    let message = '';
    let error = false;
    if (hardCopyUpload && hardCopyUpload.length) {
      if (hardCopyUpload[0].id && hardCopyUpload[0].name && hardCopyUpload[0].url) {
        message = '';
      }
    }
    else {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    }
    return { error, message };
  },

  /* ----------  Applicatiion hard Copy Validation End -------------*/
  validateRegistrationOtp: (otp) => {
    const maxLength = 6;
    const minLength = 6;
    let error = '';
    let hasError = false;
    if (!otp) {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } else if (!/^[0-9]+$/.test(otp)) {
      error = 'Not a valid Otp';
      hasError = true;
    } else if (otp.length < minLength) {
      error = `Minimum Length should be ${minLength}`;
      hasError = true;
    } else if (otp.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateAdhaar: (inputValue) => {
    const maxLength = 12;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } /*else if (!/^\\d{12}$/.test(inputValue)) {
      error = 'Not a valid adhaar number';
      hasError = true;
    }*/ else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validatePanCard: (inputValue) => {
    const maxLength = 10;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(inputValue)) {
      error = 'Not a valid Pancard Format';
      hasError = true;
    } else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateVoterId: (inputValue) => {
    const maxLength = 11;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } else if (!/[a-zA-Z0-9]{10,11}/.test(inputValue)) {
      error = 'Not a valid voterId';
      hasError = true;
    } else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateDrivingLicense: (inputValue) => {
    const maxLength = 16;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } else if (!/^([a-zA-Z]){2}.{14}?$/.test(inputValue)) {
      error = 'Not a valid Driving License';
      hasError = true;
    } else if (inputValue.length < maxLength) {
      error = `Minimum Length should be ${maxLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validatePassport: (inputValue) => {
    const maxLength = 9;
    const minLength = 8;
    let error = '';
    let hasError = false;
    if (!inputValue) {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } else if (!/[a-zA-Z0-9]{8,9}/.test(inputValue)) {
      error = 'Not a valid passport';
      hasError = true;
    } else if (inputValue.length < minLength) {
      error = `Minimum Length should be ${minLength}`;
      hasError = true;
    } else if (inputValue.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateAmount: (amount) => {
    let message = '';
    let error = false;
    if (!amount) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!/^[0-9]+$/.test(amount) || amount.charAt(0) === '0') {
      message = 'Not a valid Amount ';
      error = true;
    } else {
      error = '';
    }
    return { error, message };
  },
  validateNumber: (number) => {
    let message = '';
    let error = false;
    if (!number) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      error = '';
    }
    return { error, message };
  },
  validateDecimalAmount: (amount, isOptional = false) => {
    let message = '';
    let error = false;
    if (!amount && !isOptional) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (amount && (!/^[0-9]*\.?[0-9]+$/.test(amount.replace(/,/g, '')) || amount.toString().charAt(0) === '0')) {
      message = 'Not a valid Amount ';
      error = true;
    } else {
      error = '';
    }
    return { error, message };
  },
  validateServiceType: (selectType) => {
    let error = '';
    let hasError = false;
    let connectionFor = selectType;
    connectionFor = connectionFor.toString().trim();
    if (!connectionFor || selectType === "true") {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateLandmark: (inputLandmark) => {
    const maxLength = 72;
    let error = '';
    let hasError = false;
    let landmark = inputLandmark;
    landmark = landmark.trim();
    if (!landmark) {
      error = INPUT_ERROR.REQUIRED;
      hasError = true;
    } else if (landmark.length > maxLength) {
      error = `Maximum Length should be ${maxLength}`;
      hasError = true;
    } else {
      error = '';
    }
    return { error, hasError };
  },
  validateByArray: (inputValue, validationArray) => {
    let error = '';
    let hasError = false;
    let value = inputValue;
    value = value.toString().trim();
    if (Array.isArray(validationArray) && validationArray.length > 0) {
      for (let i = 0; i < validationArray.length; i += 1) {
        const currentValidation = validationArray[i];
        if (currentValidation.validation === 'required') {
          if (!value) {
            error = INPUT_ERROR.REQUIRED;
            hasError = true;
            break;
          }
        }
      }
    }
    return { error, hasError };
  },
  validateGST: (inputGSTNo) => {
    const maxLength = 15;
    let error = '';
    let hasError = false;
    let gstNo = inputGSTNo;
    gstNo = gstNo.toString().trim();
    const regexGst = /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/i;
    if (gstNo) {
      if (!regexGst.test(String(gstNo).toLowerCase())) {
        error = INPUT_ERROR.GST_INVALID;
        hasError = true;
      } else if (gstNo.length > maxLength) {
        error = `Enter max ${maxLength} characters`;
        hasError = true;
      }
    } else {
      error = '';
    }
    return { error, hasError };
  },

  validateTotalArea: (area, isRequired = true) => {
    let message = '';
    let error = false;
    if (isRequired && !area) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    }
    return { message, error };
  },
  validateSelect: (value) => {
    let message = '';
    let error = false;
    if (!value) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    }
    return { message, error };
  },
  validatePartnerDetails: (partnerDetails, firmType) => {
    let finalError = false;
    const errorArr = [];
    if (firmType === 'PARTNER') {
      const currentArr = partnerDetails;
      let valueArray = [];
      for (let i = 0; i < currentArr.length; i++) {
        const erroObj = {};
        if (!currentArr[i].partnerName.value) {
          finalError = true;
          erroObj.partnerName = {
            error: true,
            message: INPUT_ERROR.PARTNER_NAME,
          }
        }
        if (currentArr[i].partnerPanNumber) {
          const inputValue = currentArr[i].partnerPanNumber.value;
          const maxLength = INPUT_MAX_LENGTH.LENGTH_TEN;
          if (!inputValue) {
            finalError = true;
            erroObj.partnerPanNumber = {
              error: true,
              message: INPUT_ERROR.PARTNER_PAN,
            }
          } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(inputValue)) {
            finalError = true;
            erroObj.partnerPanNumber = {
              error: true,
              message: "Not a valid Pancard Format",
            }
          }
          else if (inputValue && valueArray.includes(inputValue.toLowerCase())) {
            finalError = true;
            erroObj.partnerPanNumber = {
              error: true,
              message: "Duplicate PAN Number"
            }
          }
          else if (inputValue.length < maxLength) {
            finalError = true;
            erroObj.partnerPanNumber = {
              error: true,
              message: `Minimum Length should be ${maxLength}`
            }
          } else if (inputValue.length > maxLength) {
            finalError = true;
            erroObj.partnerPanNumber = {
              error: true,
              message: `Maximum Length should be ${maxLength}`
            }
          } else {
            finalError = false;
            erroObj.partnerPanNumber = {
              error: false,
              message: "",
            }
          }
        }
        if (currentArr[i].partnerPanNumber.value) {
          valueArray.push(currentArr[i].partnerPanNumber.value.toLowerCase());
        }
        errorArr.push(erroObj);
      }
    }
    return { error: finalError, errorArr };
  },

  validateBankDetails: (bankDetails) => {
    // let valueArray = [];
    let finalError = false;
    const errorArr = [];
    const currentArr = bankDetails;
    let valueArray = [];
    for (let i = 0; i < currentArr.length; i++) {
      const erroObj = {};
      let isOthers = currentArr[i].bankName.isOther;

      // if(currentArr[i].bankName.value && valueArray.includes(currentArr[i].bankName.value)){
      //   finalError = true;
      //   erroObj.bankName = {
      //     error: true,
      //     message: "Duplicate Value",
      //   }
      // }


      if (!currentArr[i].bankName.value) {
        finalError = true;
        erroObj.bankName = {
          error: true,
          message: INPUT_ERROR.BANK_NAME_MESSAGE,
        }
      }
      if (isOthers && !currentArr[i].otherBank.value) {
        finalError = true;
        erroObj.otherBank = {
          error: true,
          message: INPUT_ERROR.BANK_NAME_MESSAGE,
        }
      }
      if (currentArr[i].bankAccountNumber.value && valueArray.includes(currentArr[i].bankAccountNumber.value)) {
        finalError = true;
        erroObj.bankAccountNumber = {
          error: true,
          message: "Duplicate Account Number"
        }
      }
      else if (!currentArr[i].bankAccountNumber.value) {
        finalError = true;
        erroObj.bankAccountNumber = {
          error: true,
          message: INPUT_ERROR.ACCOUNT_MESSAGE,
        }
      }
      // if (!currentArr[i].ccLimit.value) {
      //   finalError = true;
      //   erroObj.ccLimit = {
      //     error: true,
      //     message: INPUT_ERROR.CC_LIMIT_MESSAGE,
      //   }
      // }

      if (currentArr[i].bankAccountNumber.value) {
        valueArray.push(currentArr[i].bankAccountNumber.value);
      }
      errorArr.push(erroObj);
    }
    return { error: finalError, errorArr };
  },

  validateUploadDocumentsTemplate: (uploadDocumentsTemplate) => {
    let finalError = false;
    const errorArr = [];
    const currentArr = uploadDocumentsTemplate;
    for (let i = 0; i < currentArr.length; i++) {
      if (currentArr[i].heading === "GST NO.") {
        if (!currentArr[i].docList.length) {
          currentArr[i].docIsRequired = true
          finalError = true;
        } else {
          currentArr[i].docIsRequired = false
        }
      }
      else {
        if (!currentArr[i].docList.length) {
          if (currentArr[i].heading === "Address Proof" && currentArr[i].buttonConfig.isDisabled) {
            currentArr[i].docIsRequired = false
          } else if (currentArr[i].buttonConfig.isDisabled && currentArr[i].rightSectionConfig && currentArr[i].rightSectionConfig.checked) {
            // console.log("currentArr=========", currentArr[i]);
            currentArr[i].docIsRequired = false
          } else {
            finalError = true;
            currentArr[i].docIsRequired = true
          }
          /*erroObj.bankName = {
              error: true,
              // message: "Bank Name is Required",
          }*/
        } else {
          if (currentArr[i].type !== "TEXT" && currentArr[i].rightSectionConfig && !currentArr[i].rightSectionConfig.checked && !currentArr[i].rightSectionConfig.disabled) {
            // console.log("currentArr=========", currentArr[i]);
            finalError = true;
            currentArr[i].docIsRequired = true
          } else currentArr[i].docIsRequired = false
        }
      }

      // errorArr.push(erroObj);
    }
    return { error: finalError, errorArr };
  },

  validateWorkflow: (workflow, isFirst) => {
    const updatedWorkFlow = [...workflow];
    const stateArr = [];
    let error = false;

    updatedWorkFlow.forEach((item, i) => {
      let curSourceState = item.sourceState.value;
      let actionStateArr = [];

      item.actionTarget.forEach((element, index) => {
        let actionState = element.action['value'];
        let targetState = element.targetState['value'];

        if (!actionState) {
          element.action['errorMessage'] = INPUT_ERROR.REQUIRED;
          error = true;
        } else {
          if (actionStateArr.includes(actionState)) {
            element.action['value'] = '';
            element.action['errorMessage'] = 'Target State Already selected';
            error = true;
          } else {
            actionStateArr.push(actionState);
            element.action['errorMessage'] = '';
          }
        }

        if (!targetState) {
          element.targetState['errorMessage'] = INPUT_ERROR.REQUIRED;
          error = true;
        } else {
          element.targetState['errorMessage'] = '';
        }
      })

      if (!curSourceState) {
        item.sourceState.errorMessage = INPUT_ERROR.REQUIRED;
        error = true;
      } else {
        item.sourceState.errorMessage = '';
      }

      if (stateArr.includes(curSourceState)) {
        item.sourceState.value = '';
        item.sourceState.errorMessage = 'Source State Already selected';
        error = true;
      } else {
        if (curSourceState !== '') {
          stateArr.push(curSourceState);
        }
      }
    })

    if (!isFirst) {
      error = true;
    }

    return { updatedWorkFlow, error };
  },
  validateRequired: (value) => {
    let message = '';
    let error = false;
    if (!value) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    }
    return { message, error };
  },

  validateEmptyArray: (value) => {
    let message = '';
    let error = false;
    if (!value.length) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    }
    return { message, error };
  },

  validateObject: (value) => {
    let message = "";
    let error = false;
    if (!Object.keys(value).length) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    }
    return { message, error };
  },

  validatePartnerCode: (partnerCode) => {
    const maxLength = 5;
    let message = '';
    let error = false;
    if (!partnerCode) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (partnerCode.length < maxLength) {
      message = `Applicant code should be ${maxLength} digits`;
      error = true;
    } else {
      message = '';
    }
    return { error, message };
  },

  validateVirtualCode: (virtualCode) => {
    let message = '';
    let error = false;
    if (!virtualCode) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!/^\w*$/.test(virtualCode)) {
      message = `Virtual code is invalid`;
      error = true;
    } else {
      message = '';
    }
    return { error, message };
  },

  validateNumericValue: (inputValue) => {
    let message = '';
    const pattern = /^[0-9]$/;
    let error = false;
    if (!inputValue) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (!pattern.test(inputValue)) {
      message = INPUT_ERROR.NAN;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },

  validateDateValue: (dateValue) => {
    let message = '';
    let error = false;
    let currentDate = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    let inputDate = new Date(new Date(dateValue).setHours(0, 0, 0, 0)).getTime();
    if (!dateValue) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else if (inputDate < currentDate) {
      message = INPUT_ERROR.CAN_NOT_BE_LESS_THAN_CURRENT_DATE;
      error = true;
    }
    else {
      message = '';
    }
    return { message, error };
  },

  validateInputValue: (inputValue) => {
    let message = '';
    let error = false;
    if (!inputValue) {
      message = INPUT_ERROR.REQUIRED;
      error = true;
    } else {
      message = '';
    }
    return { message, error };
  },
  // validateInputWithRadioValue: (inputWithRadio) => {
  //   // console.log(inputWithRadio)
  //   let message = '';
  //   let error = false;
  //   let { radioConfig } = inputWithRadio.value;
  //   let { value } = inputWithRadio.value;
  //   if (radioConfig) {
  //     let yesRadio = radioConfig.find(v => {
  //       return v.elementConfig.value == 'Yes'
  //     })
  //     if (yesRadio && yesRadio.elementConfig.checked && !value) {
  //       message = INPUT_ERROR.REQUIRED;
  //       error = true;
  //     } else {
  //       message = ' ';
  //       error = false;
  //     }
  //   }
  //   return { message, error };
  // },

  validateInputWithRadioValue: (inputWithRadio) => {
    let message = '';
    let error = false;
    let { radioConfig } = inputWithRadio.value;
    let { value } = inputWithRadio.value;
    if(radioConfig){
      let yesRadio = radioConfig.find(v=>{
        return v.elementConfig.value === 'Yes'
      })
      if(yesRadio && yesRadio.elementConfig.checked && !value){
          message = INPUT_ERROR.REQUIRED;
          error = true;
      }else{
        message = '';
        error = false;
      }
    }
    return { message, error };
  },

  validatePincodesValue: (pinCode) => {
    const maxLength = 6;
    let response = [];
    // let message = '';
    // let error = false;
    pinCode.value.forEach((item) => {
      // console.log(item, index, arr);
      if (item.pinCodes.value === "") {
        response.push({
          message: INPUT_ERROR.REQUIRED,
          error: true
        });
      }
      else if (!/^[0-9]+$/.test(item.pinCodes.value) || item.pinCodes.value.charAt(0) === '0') {
        response.push({
          message: 'Not a valid pincode',
          error: true
        });

      } else if (item.pinCodes.value.length < maxLength) {
        response.push({
          message: `Minimum Length should be ${maxLength}`,
          error: true
        });

      } else if (item.pinCodes.value.length > maxLength) {
        response.push({
          message: `Maximum Length should be ${maxLength}`,
          error: true
        });
      } else {
        response.push({
          message: ''
        });

      }
    });

    return response;
  },
};

