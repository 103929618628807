import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { TEXT, ACTION_TABLE_HEADING } from '../constant';
import InputBox from '../InputBox';
import TextArea from '../TextArea';
import SelectBox from '../SelectBox';
import Table from '../Table';
import Loader from '../../../components/Loader';
import {MODALS} from "../../../storeManager/modal/constants";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import GlobalConfig from "../../../globalConfig/globalConfig";
import FormValidator from '../../../services/FormValidator';
import CheckBox from '../CheckBox';
import {
  actionTypeList,
  actionPriorityList,
  fetchAdminActionsList,
  getCategoryList,
  getProgressStatus,
  fetchAllNotification,
  fetchEmailTemplateList
} from '../../../storeManager/common/repository';
import { AxiosService } from '../../../services/apiService';
import {commonService} from "../../../storeManager/common/services";
import {scrollToTop} from '../../.././helper';

class AdminActions extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      label: '',
      description: '',
      type: '',
      priority: '',
      actionCategory: '',
      status: '',
      message: '',
      emailTemplate: null,
      notificationTemplates: [],
      isLoading: true,
      error: '',
      actionsList: [],
      typeList: [],
      priorityList: [],
      actionCategoryList :[],
      actionStatusList : [],
      notificationList: [],
      emailTemplateList: [],
      isEdit: false,
      validate: true,
      errorObj :{
        actionCategoryError: "",
        descriptionError: "",
        hasErrors: "",
        labelError: "",
        nameError: "",
        priorityError: "",
        statusError: "",
        typeError: "",
        validateError: "",
        messageError: ""
      },
      validateList: [ {id: true, value: 'Yes'}, {id: false, value: 'No'}]
    }
  }

  componentDidMount(){
    this.loadHandler()
  }

  loadHandler = async () => {
    const actionApi = new AxiosService(fetchAdminActionsList);
    const typeApi = new AxiosService(actionTypeList);
    const priorityApi = new AxiosService(actionPriorityList);
    const actionListApi = new AxiosService(getCategoryList);
    const getPriorityApi = new AxiosService(getProgressStatus);
    const notificationAPi = new AxiosService(fetchAllNotification);
    const emailtemplateApi = new AxiosService(fetchEmailTemplateList);
    Promise.all([
      actionApi.doAjax(),
      typeApi.doAjax(),
      priorityApi.doAjax(),
      actionListApi.doAjax(),
      getPriorityApi.doAjax(),
      notificationAPi.doAjax(),
      emailtemplateApi.doAjax()
    ])
    .then(res => {
      const [
        {data : {data : actionsList, status: status1} },
        {data : {data : typeList, status: status2} },
        {data : {data : priorityList, status: status3} },
        {data : {data : actionCategoryList, status: status4} },
        {data : {data : actionStatusList, status: status5} },
        {data : {data : notificationList}},
        {data : {data : emailTemplateList}}
      ] = res;
      if(status1 && status2 && status3 && status4 && status5) {
        this.setState({
          actionsList,
          typeList: typeList.map(item => {return {'id':item, 'value':item}}),
          priorityList: priorityList.map(item => {return {'id':item, 'value':item}}),
          actionCategoryList: actionCategoryList.map(item => {return {'id':item, 'value':item}}),
          actionStatusList: actionStatusList.map(item => {return {'id':item, 'value':item}}),
          notificationList,
          emailTemplateList: emailTemplateList.map(item => {return {'id':item.id, 'value':item.friendlyTemplateName}}),
          isLoading: false
        })
      }
      else{
        this.setState({isLoading: false})
      }
    })
    .catch( err => {
      this.setState({isLoading: false})
      console.log('Error in api call', err);
    })
  };

  handleChange = (name,value) => {
    this.setState({
      [name]: value,
    })
  };

  resetForm = () => {
    this.setState({
      name: '',
      description: '',
      type: '',
      priority: '',
      error: '',
      label: '',
      actionCategory: '',
      validate: true,
      status: '',
      message: '',
      emailTemplate : "",
      notificationTemplates: [],
      isEdit: false,
      errorObj :{
        actionCategoryError: "",
        descriptionError: "",
        hasErrors: "",
        labelError: "",
        nameError: "",
        priorityError: "",
        statusError: "",
        typeError: "",
        validateError: "",
      }
    }, () => this.loadHandler())
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const {...validatePayload} = this.state;

    if(this.validateForm(validatePayload)){
      this.setState({
        isLoading: true,
      });
      try {
        const { name, description, type, priority, id, actionCategory, label, status, validate, message, emailTemplate, notificationTemplates } = this.state;
        const payload = {
          id: id || null,
          description,
          name,
          validate: this.strToBool(validate),
          priority,
          type,
          label,
          actionCategory,
          status,
          emailTemplate : emailTemplate ? { id: emailTemplate } : null,
          notificationTemplates,
          message
        };
        const response = await commonService.createAction(payload);
        const responseData = response.data;
        const { openModal } = this.props;
        if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE){
          openModal(MODALS.CONFIRMATION_POPUP, {
            message: responseData.message
          });
          this.resetForm();
        } else {
          this.setState({
            error: (responseData && responseData.message) || GlobalConfig.serverError,
            isLoading: false
          })
        }
      } catch (e) {
        console.log('error occured on admin state =', e);
        this.setState({
          error: GlobalConfig.serverError,
          isLoading: false,
        })
      }
    }
  }

  handleClick = async (id) => {
    const response = await commonService.getActionDetails(id);
    if(response){
      const {name,message, description, priority, type, label, actionCategory, status, validate, emailTemplate, notificationTemplates } = response.data.data;
      this.setState({
        id,
        name,
        description,
        priority,
        validate: validate.toString(),
        type,
        label,
        actionCategory,
        status,
        message,
        emailTemplate: (emailTemplate && emailTemplate.id) || null,
        notificationTemplates,
        isEdit: true,
      }, () => scrollToTop());
    }
  };

  handleCheckbox = (name,value, checked, id, type) => {
    const selectedValue = this.state[type];
    let updatedValue = [...selectedValue];
    if(checked){
      updatedValue.push({id});
    } else {
      updatedValue = updatedValue.filter(e => e.id !== id)
    };
    this.setState({
      [type]: updatedValue
    })
  };

  strToBool = (value) => {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }

  validateForm = (payload) =>{
    let { errorObj } = this.state;
    const formStatus = FormValidator.validateAdminActions(payload);
    if(formStatus.hasErrors){
      errorObj = formStatus;
      this.setState({
        errorObj
      });
    }
    else{
      return true;
    }
  }

  render() {
    const {
      name, description, type, priority, isLoading, label,message,emailTemplate,notificationTemplates,
      error, typeList, priorityList, actionsList, actionCategoryList, actionStatusList,
      actionCategory, status, isEdit, validate, validateList, errorObj, notificationList, emailTemplateList
    } = this.state;
    const notificationElement = notificationList && notificationList.map((notification, index) => {
      const findInList = notificationTemplates.find(singleNotification => singleNotification.id === notification.id);
      const isChecked = findInList ? true : false;
      let checkBxTitle = null;
      if(notification && notification.role && notification.role.roleType) {
        checkBxTitle = `Notified to : ${notification.role.roleType}`;
      }
      return <CheckBox
        id={notification.id}
        key={index}
        title={checkBxTitle}
        labelText={notification.name}
        name={notification.name}
        value={notification.name}
        onChange={(name,value, checked, id) => this.handleCheckbox(name,value, checked, id, 'notificationTemplates')}
        isChecked={isChecked}
      />
    });

    return (

      <div className="admin-actions">
        {isLoading && <Loader />}
          <h2 className="heading mb-15">{TEXT.ACTIONS_TITLE}</h2>
          <div className="sub-heading">{TEXT.ACTIONS_SUBTITLE}</div>
          <div className="content-form no-gutters">
            <form className="col-lg-8 no-gutters">
                <h3 className="content-title">{`${isEdit ? 'Update' : 'Create'} Action`}</h3>
                <div className="action-name-section col-lg-6">
                  <InputBox
                    labelName='Action Name'
                    name='name'
                    className='form-control'
                    type="text"
                    value={name}
                    onChange={this.handleChange}
                    errorsMessage = {errorObj.nameError || ''}
                  />
                  <InputBox
                    labelName='Label Name'
                    name='label'
                    className='form-control'
                    type="text"
                    value={label}
                    onChange={this.handleChange}
                    errorsMessage = {errorObj.labelError || ''}
                  />
                </div>
                <div className="action-desc-section">
                  <TextArea
                    labelName='Description'
                    name='description'
                    className='form-control'
                    pholder="by this you can change assignment of any request."
                    onChange={this.handleChange}
                    value={description}
                    errorsMessage = {errorObj.descriptionError || ''}
                  />
                </div>
                <div className="row">
                  <div className="type-section col-lg-6">
                    <SelectBox
                      labelName="Type"
                      options={typeList}
                      onChange={this.handleChange}
                      name='type'
                      value={type}
                      errorsMessage = {errorObj.typeError || ''}
                    />
                  </div>
                  <div className="priority-section col-lg-6">
                    <SelectBox
                      labelName="Priority"
                      options={priorityList}
                      name="priority"
                      value={priority}
                      onChange={this.handleChange}
                      errorsMessage = {errorObj.priorityError || ''}
                    />
                  </div>
                  <div className="category-section col-lg-6">
                    <SelectBox
                      labelName="category"
                      options={actionCategoryList}
                      name="actionCategory"
                      value={actionCategory}
                      onChange={this.handleChange}
                      errorsMessage = {errorObj.actionCategoryError || ''}
                    />
                  </div>
                  <div className="status-section col-lg-6">
                    <SelectBox
                      labelName="Status"
                      options={actionStatusList}
                      name="status"
                      value={status}
                      onChange={this.handleChange}
                      errorsMessage = {errorObj.statusError || ''}
                    />
                  </div>
                  <div className="validate-section col-lg-6">
                    <SelectBox
                      labelName="Validate"
                      options={validateList}
                      name="validate"
                      value={validate}
                      onChange={this.handleChange}
                      errorsMessage = {errorObj.validateError || ''}
                    />
                  </div>
                  <div className="action-message-section col-lg-6">
                  <InputBox
                    labelName='Action Message'
                    name='message'
                    className='form-control'
                    type="text"
                    value={message}
                    onChange={this.handleChange}
                    errorsMessage = {errorObj.messageError || ''}
                  />
                  </div>
                </div>
                <div className="row">
                  <div className="notification-owner col-lg-6 form-group">
                      <label>Notification Template List</label>
                      <div className="owner-bg">
                        { notificationElement }
                      </div>
                      <div className="error-message">{errorObj.sendNotificationToError}</div>
                  </div>
                  <div className='workflow-list col-lg-6 form-group'>
                    <SelectBox
                        labelName='Email template'
                        name='emailTemplate'
                        onChange={this.handleChange}
                        options={emailTemplateList}
                        value={emailTemplate}
                    />
                </div>
                </div>
                <div>
                  {error && <label className="error-message">{error}</label>}
                  <button
                      className="button fill big w-210 mt10 mb30 mr20"
                      onClick={this.handleSubmit}
                  >
                    {`${isEdit ? 'UPDATE' : 'CREATE'}`}
                  </button>
                </div>
            </form>
          </div>
          <div className="table-container mt45">
            <h3>Actions {actionsList && `(${actionsList.length})`}</h3>
            <div className="table-data-list action-data-table">
              <Table showHead headings={ACTION_TABLE_HEADING} rows={actionsList} handleClick={this.handleClick} />
            </div>
          </div>
          {/*<Modal />*/}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModalHandler, dispatch)
});

export default (connect(null, mapDispatchToProps)(AdminActions))
