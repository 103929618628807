import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from "../../../constants";
import logo from '../../../assets/images/tata-sky-logo.png';
import playLogo from "../../../assets/images/tata-play-logo-black.png";
import './header.scss';

// dependent components
import Profile from "./Profile";

const Header = () => {
  return (
    <header className="header">
      <div className="inner">
        <Link to={PATHS.HOME}>
          <img src={playLogo} alt="Tata Play" className="logo" />
        </Link>
        <div className="d-inline-flex ml-auto">
          <Profile />
        </div>
      </div>
    </header>
  );
};

export default Header;