import React, {Component, Fragment} from "react";

class MobileLeftPanel extends Component {
    dashboardDropdown = React.createRef();
    state = {
        roleFilter: false,
        statusFilter: false,
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.dashboardDropdown.current && !this.dashboardDropdown.current.contains(event.target)) {
            this.setState({
                roleFilter: false,
                statusFilter: false,
            });
        }
    };

    toggleFilterList = (key) => {
        this.setState(prevState => ({
            [key]: !prevState[key]
        }))
    };

    onClickHandler = (item, roleKey) => {
        const {
            filterRoleOnChange,
            filterStatusOnChange,
        } = this.props;
        this.toggleFilterList(roleKey);
        if (roleKey === "statusFilter") {
            filterStatusOnChange(item)
        } else filterRoleOnChange(item);
    };

    render() {
        const {
            tasksByRole,
            selectedRoleObj,
            filterListByStatus,
            selectedStatusObj
        } = this.props;

        const {roleFilter, statusFilter} = this.state;

        return (
            <div ref={this.dashboardDropdown}>
                <div className="selected-role-mobile" onClick={() => this.toggleFilterList("roleFilter")}>
        <span className="task-by-role-mobile">
          {selectedRoleObj.label || "Filter by Role"}
        </span>
                    {selectedRoleObj.label && <span className="task-count-mobile">
          ({selectedRoleObj.count || 0})
        </span>}
                </div>
                {
                    roleFilter && (
                        <div className="task-list-wrapper-on-hover">
                            <ul className="task-by-role-list-wrapper">
                                {tasksByRole &&
                                tasksByRole.length &&
                                tasksByRole.map((item, index) => (
                                    <li
                                        key={index}
                                        className={`task-by-role-item ${
                                            item.id === selectedRoleObj.id ? "activeRole" : ""
                                        }`}
                                        onClick={() => this.onClickHandler(item, "roleFilter")}
                                    >
                                        <span className="task-by-role">{item.label}</span>
                                        <span className="task-count">({item.count})</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )
                }

                {
                    filterListByStatus &&
                    filterListByStatus.length ? (
                        <Fragment>
                            <div className="selected-role-mobile" onClick={() => this.toggleFilterList("statusFilter")}>
                                <span className="task-by-role-mobile">
                                  {selectedStatusObj.status || "Filter by Status"}
                                </span>
                                <span className="task-count-mobile">
                                    ({selectedStatusObj.count || 0})
                                </span>
                            </div>

                            {
                                statusFilter && (
                                    <div className="task-list-wrapper-on-hover">
                                        <ul className="task-by-role-list-wrapper status-list-wrapper">
                                            {
                                                filterListByStatus.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className={`task-by-role-item ${
                                                            item.status === selectedStatusObj.status ? "activeRole" : ""
                                                        } 
                                                  `}
                                                        onClick={() => this.onClickHandler(item, "statusFilter")}
                                                    >
                                                  <span className="task-by-role">
                                                    {item.status.replace(/_+/g, " ").toLowerCase()}
                                                  </span>
                                                        <span className="task-count">({item.count})</span>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )
                            }
                        </Fragment>
                    ) : null
                }
            </div>
        );
    }
}

export default MobileLeftPanel;
