import React, {Component, Fragment} from "react";
// import Logo from './../../assets/images/logo_login.png';
import Logo from './../../assets/images/tata-play-logo-black.png';
import Login from "./Login";
import Registration from "./Registeration";
import VerifyOTP from './Login/VerifyOTP';
import {isAuthenticated} from "../../helper";
import {PATHS} from "../../constants";
import {NavLink} from "react-router-dom";

class Auth extends Component {
    state = {
        activeTab: 0,
        mobileNumber: '',
        showLogin: true
    };

    componentDidMount() {
        if (!isAuthenticated()) {
            const {history: {location: {activeTab}}} = this.props;
            activeTab && this.setState({
                activeTab,
            })
        } else {
            const {history} = this.props;
            history.push(PATHS.HOME);
        }
    }

    handleSelect = (index) => {
        this.setState({
            activeTab: index,
        })
    };

    openOTP = (mobileNumber) => {
        const {showLogin} = this.state;
        this.setState({
            showLogin: !showLogin,
            mobileNumber
        })
    };

    render() {
        const {openOTP} = this;
        const {activeTab, showLogin, mobileNumber} = this.state;
        return (
          <div className="auth-container">
            <img src={Logo} alt="Tata Play" className="login-logo mb20" />
            <div className="outer-layer">
              <div className="inner">
                {showLogin ? (
                  <Fragment>
                    <ul className="list-tab">
                      <li
                        className={activeTab === 0 ? "active" : ""}
                        onClick={() => this.handleSelect(0)}
                      >
                        Login
                      </li>
                      <li
                        className={activeTab === 1 ? "active" : ""}
                        onClick={() => this.handleSelect(1)}
                      >
                        Register
                      </li>
                    </ul>
                    {activeTab === 0 ? (
                      <Login
                        handleSelect={this.handleSelect}
                        openOTP={openOTP}
                      />
                    ) : (
                      <Registration handleSelect={this.handleSelect} />
                    )}
                  </Fragment>
                ) : (
                  <VerifyOTP mobileNumber={mobileNumber} openOTP={openOTP} />
                )}
              </div>
              <div className="layer-strip" />
            </div>
            <ul className="navbar">
              <li className="option">
                <i className="nav-icon">
                  <svg width="14" height="14" viewBox="0 0 28 28">
                    <path
                      fill="#354060"
                      fillRule="evenodd"
                      d="M13.999 28C6.28 28 0 21.72 0 14 0 6.281 6.28 0 13.999 0 21.719 0 28 6.281 28 14c0 7.72-6.281 14-14.001 14zm0-26.164C7.293 1.836 1.836 7.293 1.836 14s5.457 12.164 12.163 12.164c6.709 0 12.165-5.457 12.165-12.164S20.708 1.836 13.999 1.836zm1.687 19.571h-3.373a.918.918 0 010-1.836h.769v-7.834h-.769a.917.917 0 110-1.836H14c.508 0 .918.411.918.919v8.751h.768a.917.917 0 110 1.836zM13.741 8.818a1.224 1.224 0 11-.003-2.447 1.224 1.224 0 01.003 2.447z"
                    />
                  </svg>
                </i>
                <NavLink to={PATHS.ABOUT_US} target="_blank">
                  About Us
                </NavLink>
              </li>
            </ul>
          </div>
        );
    }
}

export default Auth;
