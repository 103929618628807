import React, { Component, Fragment } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {changeNotificationReadStatus} from "../../../../../storeManager/common/actions";
import "./notification.scss";

const mapStateToProps = state => ({
  notificationList: state.commonReducer.notificationList,
});

const mapDispatchToProps = dispatch => ({
  changeNotificationReadStatus: bindActionCreators(changeNotificationReadStatus, dispatch),
  
});
class NotificationViewModal extends Component {

  unreadNotifications = notificationsList => {
    let totalUnread = 0;
    if (notificationsList && notificationsList.length) {
      totalUnread = notificationsList.filter(item => item.isNew).length;
    }
    return totalUnread;
  }; 

  render() {

    const { notificationList, changeNotificationReadStatus, modalParameters:{changeNotificationStatus}} = this.props;
    const unreadNotifications = this.unreadNotifications(notificationList);
    return (
      <Fragment>
        <div className="custom-modal-header pb5">
          <div className="title pl15">NOTIFICATION</div>
        </div>

        <div className="mr30">
          <div className="unread-notification-wrapper pl15 mb20">
            You have{" "}
            <span className="unread-notification">{unreadNotifications || 0}</span>{" "}
            new notification
          </div>

          <div className="notification-list">
            {notificationList &&
            !!notificationList.length &&
            notificationList.map((item, index) => (
                <div
                    className={`notification-card ${
                        item.isNew ? "unread" : ""
                    }`}
                    key={index}
                    onClick={() => {
                      changeNotificationReadStatus(index);
                      changeNotificationStatus(item.id);
                    }}
                >
                  <div>
                    {item.date} | {item.role}
                  </div>
                  <div>
                    {item.isRejected && (
                        <Fragment>
                          <span className="rejected">Rejected -</span>
                          <span className="request-id pl5">#{item.requestId}</span>
                        </Fragment>
                    )}
                  </div>
                  {/* {item.isNew ? <span>Not Read</span> : <span>Read</span>} */}
                  {!!item.comment && <div>
                    {item.comment}
                    {
                      !(item.isRejected) &&
                      <span className="request-id pl5">#{item.requestId}</span>
                    }
                  </div>}
                </div>
            ))}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationViewModal);
