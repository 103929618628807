import React, {Component, Fragment} from "react";
import Input from "../../Common/Input";
import Error from "../../Common/Error";
import {ROLES_CATEGORY} from "../../../constants";

class DocumentHardCopy extends Component {
  render() {
    const {
      docHardCopyForm, inputChangedHandler,
      termsConditionChangeHandler, formErrorMessage,
      formSubmit, fileHandler, formConfig
    } = this.props;
    // const {formConfig: {sendTo : {address}}} = this.props;
    const isRoleCategory = localStorage.getItem("roleCategory");
    const roleCategory = isRoleCategory && JSON.parse(isRoleCategory);

    const address = (formConfig && formConfig.sendTo && formConfig.sendTo.address) || {};
    const courierAddress = {...address};
    let updatedCourierAddress =
        courierAddress && (Object.keys(courierAddress).length || '') && (
            `   ${courierAddress.line},
                    ${(courierAddress.city || {}).name}, 
                    ${(courierAddress.state || {}).name} - 
                    ${(courierAddress.pinCode || {}).code} `
        );

    const formType = "docHardCopyForm";
    let hardCopyDataList = [], partnerCourierDetails;
    let formElementsArray = [];
    const formKeys = Object.keys(docHardCopyForm);
    formKeys.forEach((key) => {
      if (key === 'hardCopyDataList') {
        hardCopyDataList = [...docHardCopyForm[key]];
      } else if (key === 'partnerCourier') {
        partnerCourierDetails = {...docHardCopyForm[key]};
      } else {
        formElementsArray.push({
          id: key,
          config: docHardCopyForm[key],
        });
      }

    });

    return (
      <div className="pb25">
        <div className="title-1 pr10">Original Documents</div>

        <div className="description">
          To be couriered by applicant when asked by MIS
        </div>

        <div className="hardCopy-details-wrapper mt30">
          {hardCopyDataList.map((obj, index) => {
            if (obj.heading === "Send To") {
              obj.description = updatedCourierAddress;
            }
            return (
              <div key={index} className="hard-copy-list">
                <div className="title">{obj.heading}</div>
                {obj.list && (
                  <ul className="uploaded-doc-list">
                    {obj.list.map((item, index) => {
                      return (
                        <li key={index}>
                          <span>{index + 1}. </span>
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                )}
                {obj.subHeading && <div className="name">{obj.subHeading}</div>}
                {obj.description && (
                  <div className="description-d">{obj.description}</div>
                )}
              </div>
            );
          })}
          <div className="hard-copy-list">
            <div className="title">Submit Courier Details</div>
            <form>
              <fieldset
                disabled={!formConfig.editable}
                className={`${
                  !formConfig.editable ? "application-form-disabled" : ""
                }`}
              >
                {formElementsArray &&
                  formElementsArray.map((formElement) =>
                    formElement.config.isTermsAndCondition ? (
                      roleCategory === ROLES_CATEGORY.PARTNER && (
                        <div className="notes align-left mtb20">
                          <div
                            className={`checkbox-element ${
                              !formElement.config.valid ? "error" : ""
                            }`}
                          >
                            <input
                              type="checkbox"
                              checked={formElement.config.value}
                              onChange={(e) => {
                                termsConditionChangeHandler(
                                  e.target.checked,
                                  formType
                                );
                              }}
                            />
                            <span className="checkmark" />
                          </div>
                          <div className="d-inline">
                            <span className="justify-text pl30 pr5">
                              I confirm that the soft copy scanned documents
                              uploaded by me are true and correct copies of the
                              original and I agree to keep the original copies
                              of all documents submitted and provide the same as
                              proof as and when required by Tata Play Limited.
                              <span className="required">*</span>
                            </span>
                          </div>
                          {!formElement.config.valid ? (
                            <span className="invalid-text">
                              {formElement.config.errorMessage}
                            </span>
                          ) : null}
                        </div>
                      )
                    ) : (
                      <React.Fragment key={formElement.id}>
                        <Input
                          labelConfig={formElement.config.labelConfig}
                          elementType={formElement.config.elementType}
                          elementConfig={formElement.config.elementConfig}
                          value={formElement.config.value}
                          valid={formElement.config.valid}
                          errorMessage={formElement.config.errorMessage}
                          touched={formElement.config.touched}
                          wrapperClasses={formElement.config.wrapperClasses}
                          inputGroupClasses={
                            formElement.config.inputGroupClasses
                          }
                          changed={(event) =>
                            formElement.config.elementType === "file"
                              ? fileHandler().onChangeImageHandler(
                                  event,
                                  formElement.id,
                                  formType
                                )
                              : inputChangedHandler(
                                  event,
                                  formElement.id,
                                  formType
                                )
                          }
                        />
                        <div className="sub-title">Upload Courier Photo</div>
                        {formElement.config.docList && (
                          <ul className={"upload-doc-list"}>
                            {formElement.config.docList &&
                              formElement.config.docList.map((list, index) => {
                                return (
                                  <li key={index}>
                                    {list.name}
                                    <i
                                      className="delete-icon ml10"
                                      onClick={(e) =>
                                        fileHandler().deleteUploadedDocument(
                                          e,
                                          formType
                                        )
                                      }
                                    >
                                      <svg
                                        width="8"
                                        height="8"
                                        viewBox="0 0 33 33"
                                      >
                                        <path
                                          fill="#e93292"
                                          fillRule="evenodd"
                                          d="M19.409 16.492L32.382 3.506a2.067 2.067 0 000-2.92 2.061 2.061 0 00-2.918 0L16.491 13.572 3.518.586a2.06 2.06 0 00-2.917 0 2.065 2.065 0 000 2.92l12.973 12.986L.601 29.477a2.067 2.067 0 001.458 3.526c.528 0 1.056-.202 1.459-.605l12.973-12.986 12.973 12.986a2.06 2.06 0 002.918 0 2.068 2.068 0 000-2.921L19.409 16.492z"
                                        />
                                      </svg>
                                    </i>
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                      </React.Fragment>
                    )
                  )}
                {formErrorMessage && <Error error={formErrorMessage} />}

                {formConfig && formConfig.buttonArray
                  ? formConfig.buttonArray.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          {item.priority === "PRIMARY" ? (
                            <button
                              className="button fill big upload-submit-btn"
                              type="submit"
                              onClick={(e) => formSubmit(e, formType, item)}
                            >
                              {item.label}
                            </button>
                          ) : item.priority === "SECONDARY" ? (
                            <button
                              className="button action big w-210 mt10 mb30"
                              type="button"
                              onClick={(e) => formSubmit(e, formType, item)}
                            >
                              {item.label}
                            </button>
                          ) : (
                            <Fragment />
                          )}
                        </Fragment>
                      );
                    })
                  : null}
              </fieldset>
            </form>
          </div>

          {partnerCourierDetails && partnerCourierDetails.trackId ? (
            <div className="partner-tracking-details">
              <hr />
              <div className="title">Applicant Courier Details:</div>

              <div className="description-d pb20">
                Tracking Id.{" "}
                <span className="value">{partnerCourierDetails.trackId}</span>
              </div>
              <div className="title">Applicant Uploaded Document:</div>
              <Fragment>
                {partnerCourierDetails.docList && (
                  <ul className={"upload-doc-list"}>
                    {partnerCourierDetails.docList &&
                      partnerCourierDetails.docList.map((list, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={list.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {list.name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </Fragment>
            </div>
          ) : (
            <Fragment />
          )}
        </div>
      </div>
    );
  }
}

export default DocumentHardCopy;
