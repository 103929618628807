import React, {PureComponent} from 'react';
import {Route, withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {closeModal} from "../../../storeManager/modal/actions";
import connect from "react-redux/es/connect/connect";
import { scrollToTop } from '../../../helper';

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: bindActionCreators(closeModal, dispatch),
  }
};
class CommonLayout extends PureComponent {
    _isMounted = false;
    state = {
        windowWidth: undefined
    }

    componentDidMount(){
        this.handleResize();
        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate(prevProps) {
      const { closeModal } = this.props;
        this._isMounted = true;
        if (this.props.location.pathname !== prevProps.location.pathname) {
            closeModal();
            scrollToTop();
        }
    }

    handleResize = () => this.setState({
        windowWidth: window.innerWidth
      });

    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener('resize', this.handleResize)
    }

    render() {
        const {component: Component, ...rest} = this.props;
        const {windowWidth} = this.state;
        return (
            <Route
                {...rest}
                render={matchProps => (
                   <React.Fragment>
                       <Component
                           {...rest}
                           {...matchProps}
                           windowWidth={windowWidth}
                       />
                   </React.Fragment>
                )}
            />
        )
    }
}

export default connect(null, mapDispatchToProps)(withRouter(CommonLayout));