import React from "react";

import TaskRowElement from "./TaskRowElement";

const TaskList = props => {
  // const [activeTab,setActiveTab] = React.useState(0)
  const { taskList, redirectOnClick, startingIndex, lobList, tabChangeHandler, activeTab } = props;
  // console.log(taskList,'taskList')
  return (
    <div className="task-list-wrapper">
      {taskList &&
        taskList.length &&
        taskList.map((item, index) => (
          <TaskRowElement
            key={index}
            data={item}
            itemNumber={startingIndex + index + 1}
            redirectOnClick={redirectOnClick}
          />
        ))}
    </div>
  ); 
};

export default TaskList;
