import React from "react";

import "./no-data-found.scss";

const NoDataFound = props => {
  return (
    <div className={`no-data-found ${props.classes ? props.classes : ""}`}>
      No Data Found
    </div>
  );
};

export default NoDataFound;
