import React, {Component} from 'react';

class Search extends Component {
  state = {
    searchValue: "",
    // focusClass:""
  };

  onChangeHandler = (searchValue) => {
    const { onChangeSearchHandler, allowSpecialChar } = this.props;
    let value = allowSpecialChar ? searchValue.trimStart() : searchValue.replace(/[^a-zA-Z0-9]/g, '');
    onChangeSearchHandler(value);
    this.setState({
      searchValue: value
    });
  };

  removeSearchValue = () => {
    const { onChangeSearchHandler } = this.props;
    onChangeSearchHandler("");
    this.setState({
      searchValue: ""
    });
  };
  
  onBlurHandler = (e) => {
    const { allowSpecialChar, onBlurSearchValue } = this.props;
    if (!allowSpecialChar)
     this.setState({ searchValue: "" })
  }

  render() {
    const { placeHolder, disabled } = this.props;
    const { searchValue, focusClass } = this.state;
    return (
      <div className={`search-container ${focusClass || ''}`}>
        <div className="form-element">
          <input
            type="text"
            className="search-input"
            placeholder={placeHolder}
            name="searchValue"
            value={searchValue}
            maxLength={24}
            autoComplete="off"
            disabled={disabled}
            onChange={(e)=>this.onChangeHandler(e.target.value)}
            // onFocus={(e) => this.onFocusHandler(e)}
            onBlur={(e) => this.onBlurHandler(e)}
          />
        </div>
      </div>
    )
  }
}

export default Search;