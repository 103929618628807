import React from 'react';
import { DASHBOARD_CONFIG } from "../../constants";

const ActionButtons = props => {
    const { clickHandler, isVisible, lobStatus, lobType } = props;
    let btnWrapperClass = "";
    let LOBMessage = "";
    if (lobType) {
        btnWrapperClass = lobType.toLowerCase();
    }
    if(lobStatus) {
        if(lobStatus === DASHBOARD_CONFIG.INIT){
            LOBMessage = DASHBOARD_CONFIG.INIT_STATUS;
        }else if(lobStatus === DASHBOARD_CONFIG.REJECTED) {
            LOBMessage = "";
        }else if(lobStatus === DASHBOARD_CONFIG.ON_HOLD) {
            LOBMessage = DASHBOARD_CONFIG.ON_HOLD_STATUS;
        }else if(lobStatus === DASHBOARD_CONFIG.COURIER_TRACKING) {
            LOBMessage = DASHBOARD_CONFIG.COURIER_TRACKING_STATUS;
        }else {
            LOBMessage = lobStatus
        }
    } else {
        LOBMessage = DASHBOARD_CONFIG.INIT_STATUS;
    }
    return (
        <div className={btnWrapperClass}>
            <button
                type="button"
                className="button full big mb20 fill action-button"
                to="application-form"
                disabled={!isVisible}
                onClick={() => clickHandler(lobType, lobStatus)}
            >
                <span>Go To <span className="status d-inline-block text-center pl40">{LOBMessage}</span></span>
                <span className="label">{lobType} Application Form</span>
            </button>
        </div>
    )
}

export default ActionButtons;