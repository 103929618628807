import React, { Component } from 'react';
import {withRouter} from "react-router-dom";

import {PATHS} from '../../constants';
import AdminActions from "./AdminActions";
import AdminState from './AdminState';
import AdminWorkflow from './AdminWorkflow';
import FormVisibility from './AdminFormVisibility';
import AdminBusiness from './AdminBussinessWorkflow';
import BusinessWorkflow from './AdminBusinessWorkflowController';
import AdminNotificationTemplate from './AdminNotificationTemplate';
import EmailTemplate from './EmailTemplate';


import './style.scss';

class Admin extends Component {
  state = {
      adminOption: [
        {label:'Actions', url:'actions'},
        {label:'State', url:'state'},
        {label:'Workflow', url:'workflow'},
        {label:'Form Visibility', url:'form-visibility'},
        {label:'Line Of Business', url:'line-of-business'},
        {label:'Business Workflow', url:'business-workflow'},
        {label:'Notification Template', url:'notification-template'},
        {label:'Email Template', url:'email-template'},
      ],
        isSelected: 'actions',
  };
  selectedTab = (selectedUrl) => {
    const {history} = this.props;
    this.setState({
      isSelected: selectedUrl,
    })
    history.push(`${PATHS.ADMIN}/${selectedUrl}`);
  };

  componentDidMount () {
    const { match } = this.props;
    const {adminOption} = this.state;
    if(match.params.tabName){
      let index = adminOption.findIndex(element => element.url.toLowerCase()=== match.params.tabName.toLowerCase())
      if(index > -1){
        this.setState({isSelected:adminOption[index].url})
      }
    }
  }

  render() {
    const { isSelected, adminOption } = this.state,
      optionsList = adminOption.map((option, index) => {
      return (
          <li
              key={index}
              onClick={(event) => this.selectedTab(option.url)}
              className={isSelected === option.url ? 'active' : ''}
          >
            {option.label}
            <svg viewBox="0 0 32 12">
                <path
                    fill="#E50079"
                    fillRule="evenodd"
                    d="M31.799 6.98l-5.83 5.82a.688.688 0 0 1-.972 0V.19a.688.688 0 0 1 .972 0l5.83 5.82a.684.684 0 0 1 0 .97zM18 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 18 9zm-8 0a2 2 0 1 1-.001-3.999A2 2 0 0 1 10 9zM2 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 2 9z" />
            </svg>
        </li>)
    });
    return (
      <div className="admin-wrapper">
        <div className="row">
          <div className="col-lg-2 col-md-2 admin-options">
            <ul>
              {optionsList}
            </ul>
          </div>
          <div className="col-lg-10 col-md-10 option-content">
            <div className="form-bg">
              {isSelected === 'actions' && <AdminActions />}
              {isSelected === 'state' && <AdminState />}
              {isSelected === 'workflow' && <AdminWorkflow />}
              {isSelected === 'form-visibility' && <FormVisibility />}
              {isSelected === 'line-of-business' && <AdminBusiness />}
              {isSelected ==='business-workflow' && <BusinessWorkflow/>}
              {isSelected ==='notification-template' && <AdminNotificationTemplate/>}
              {isSelected ==='email-template' && <EmailTemplate/>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Admin);
